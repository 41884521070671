import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Checkbox} from "@mui/material";
const useStyles = makeStyles({
  head: {
    backgroundColor: '#eef5f6',
    color: '#2e343e',
  },
  body: {
    fontSize: 14,
  },
  truncateJobRole:{
      display: "block",
      width: "130px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover":{
          textOverflow:"none",
          whiteSpace:"normal",
          width: "160px"
      }
  },
  root: {
    width: '100%',
  },
  span: {
    fontSize:10,
  },
  // container: {
  //   maxHeight: 440,
  // },
  paper: {
    padding: 1,
    textAlign: 'center',
    color: '#456745',
  },
  openRequest :{
    color:'#469990'
  }
});

export default function DataTable(props) {
  const classes = useStyles();
  return (
      <Paper className={classes.root}>
        <TableContainer className='amz_data_table'>
          <Table stickyHeader aria-label="sticky table" className={'data-table'}>
            <TableHead>
              <TableRow>
                  <TableCell padding="checkbox">
                      <Checkbox
                          indeterminate={props.getRowsCheckedCount(props.rowData) > 0 && props.getRowsCheckedCount(props.rowData) < props.rowData.length}
                          checked={props.getRowsCheckedCount(props.rowData) > 0 && props.getRowsCheckedCount(props.rowData) === props.rowData.length}
                          onChange={props.onSelectAllClick}
                      />
                  </TableCell>
                {props.columnData.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        onClick={() => { column.sortable && props.handleSort(column.id) }}
                        sortDirection={((props.sortColumn === column.id) ? props.sortOrder : false)}
                        className={`${((props.sortColumn === column.id) ? props.sortOrder : (column.sortable ? 'default' : null))} ${column.sortable ? 'sortable' : null} ${column.headerClass ? column.headerClass : ''}`}
                    >
                      {column.title}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rowData.map((row) => {
                return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={`${row.id}_${Math.random()}`} >
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                onClick={(e) => props.rowSelect(e, row)}
                                checked={props.getIndex(props.isLaborOrder?row.LaborOrderId:row.RequisitionId, props.rowData, true)!==-1}
                            />
                        </TableCell>
                      {props.columnData.map((column) => {
                        const value = row[column.id];
                        return (
                            row.status === "OPEN" ?
                                <TableCell {...(props.rowAction && { onClick: (e) => props.rowAction(e, row) })} className={[classes.openRequest,column.id === "JobRole"&&classes.truncateJobRole].join(' ')} key={`${column.id}_${row.id}_${Math.random()}`} align={column.align}>
                                  <b>{column.format && typeof value === 'number' ? column.format(value) : value}</b>
                                </TableCell>:
                                <TableCell {...(props.rowAction && { onClick: (e) => props.rowAction(e, row) })} className={column.id === "JobRole"&&classes.truncateJobRole} key={`${column.id}_${row.id}_${Math.random()}`} align={column.align}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                        );
                      })}
                    </TableRow>
                );
              })}
              {
                props.rowData.length === 0 && <TableRow>
                  <TableCell colSpan={props.columnData.length} style={{ textAlign: 'center' }}>
                    <p>{props.noDataFound?props.noDataFound : 'No Records Found'}</p>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10,25,50,75,100]}
            component="div"
            count={props.count}
            rowsPerPage={props.pageSize}
            page={props.pageNo - 1}
            options={{
              paging: false,
              headerStyle: {
                backgroundColor: "#378FC3",
                color: "#FFF",
                fontSize: "17px",
                textAlign: "center",
                fontWeight: "bold"
              },
              // rowStyle: rowData => ({
              //   backgroundColor: !!rowData.parentOnly ? "#EEE" : "#333"
              // })
            }}
            onChangePage={(e, page) => {
              props.handlePage(page)
            }}
            onChangeRowsPerPage={(e) => props.handlePageSize(e.target.value)}
        />
      </Paper>
  );
}
