export function getValidity(expiry) {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const expiryDate = new Date(expiry);
    const expiryTime = expiryDate.getTime();
    const days_diff = Math.max(0,Math.ceil((expiryTime-currentTime)/(1000 * 3600 * 24)));
    return days_diff;
}

export function daysInBetween(date1, date2) {
    const startTime = new Date(date1).getTime();
    const endTime = new Date(date2).getTime();
    const days_diff = Math.max(0,Math.ceil((endTime-startTime)/(1000 * 3600 * 24)));
    return days_diff;
}

export const disablePastDate = (minDate, add) => {
    minDate.setDate(minDate.getDate() + add);
    let month = minDate.getMonth() + 1;
    let day = minDate.getDate();
    const year = minDate.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    return year + '-' + month + '-' + day;
};

export const enableRangeDate = (minDate) => {
    minDate.setMonth(minDate.getMonth() + 4);
    let month = minDate.getMonth() + 1;
    let day = minDate.getDate();
    const year = minDate.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    return year + '-' + month + '-' + day;
};

export function isBeforeToday(date) {
    const currentTime = new Date().getTime();
    return date.getTime() - (330 * 60 * 1000) < currentTime;
}

export function formatDate(date,modify) {
    let d =  new Date()
    d.setDate(date.getDate() + modify)
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
}