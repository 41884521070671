import {compare} from "./Comparator";

export function paginate(array, pageSize, pageNumber) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export function searchLaborOrder(laborOrders,search){
    if(search) {
        return laborOrders.filter(arr => arr.Site.toLowerCase().includes(search.toLowerCase()))
    }
    return laborOrders
}

export function filterLaborOrder(laborOrders,jobRole,site){
    if(jobRole) {
        laborOrders = laborOrders.filter(arr => arr.jobRole === jobRole)
    }
    if(site) {
        laborOrders = laborOrders.filter(arr => arr.site === site)
    }
    return laborOrders
}

export function sortLaborOrder(rows,sortColumn,order){
    return rows.sort(compare(sortColumn,order))
}