import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function MultiAutoComplete(props) {
  let selected = props.options
    ? props.options.filter((o) => o.is_selected)
    : [];
  return (
    <Autocomplete
        loading={props.isLoading}
        loadingText={"Loading..."}
      {...props}
      multiple
      value={selected}
      limitTags={2}
      size="small"
      options={props.options ? props.options : []}
      id={props.id ? props.id : "NA"}
      renderInput={(params) => (
        <TextField
          className="amz_select"
          {...params}
          label={props.fieldName ? props.fieldName : ""}
        />
      )}
      getOptionLabel={(option) =>
        option && option.name ? option.name.toString() : ""
      }
      tabIndex={props.tab_index}
      disabled={props.isDisabled}
    />
  );
}

export default MultiAutoComplete;
