import React from 'react'

function FormPageTItle(props) {
    return (
        <div>
            <h3 className='form_page_title'>{props.Title}</h3>
        </div>
    )
}

export default FormPageTItle
