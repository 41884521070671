import React from "react";
import {
    Grid,
    makeStyles,
} from "@material-ui/core";
import InputText from "../FormPage/InputText";
import InputLabel from "../FormPage/InputLabel";
import SelectSingle from "../FormPage/SelectSingle";
import constants from "../../Utils/Constants";
import Formfieldstar from "../FormPage/FormFieldStar";
import {
    FormComparevalidationerror,
    FormNonZerovalidationerror,
    Formvalidationerror
} from "../FormPage/FormValidationError";
import OnboardingPhase from "../../pages/Requisitions/OnboardingPhase";
import SmallButton from "../../components/SmallButton/SmallButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormFieldView from "../FormPage/FormFieldView";
import Constants from "../../Utils/Constants";
import {isMenaUser} from "../../Utils/RegionUtil";
import MENAOnboardingPhase from "../../pages/Requisitions/MenaOnboardingPhase";




function Requisition(props) {

    makeStyles((theme) => ({
        root: {
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    }));

    const vendorChangeHandler = (event) => {
       props.onVendorChange(props.Requisition.id, event.target.value);
    };

    const jobTypeChangeHandler = (event) => {
        props.onJobTypeChange(props.Requisition.id, event.target.value);
    };


    const handleCreateRequisition = (event) => {
       props.onCreateRequisition(event);
    }

    const handleDeleteRequisition = (event) => {
        event.preventDefault();
        props.onDeleteRequisition(props.Requisition.id);
    }

    const handleCreateOnboardingPhase = (event) => {
        event.preventDefault();
        props.onCreateOnboardingPhase(props.Requisition.id);
    }

    const handleDeleteOnboardingPhase = (onboardingPhaseId) => {
        props.onDeleteOnboardingPhase(props.Requisition.id, onboardingPhaseId)
    }
    const handleRequisitionDataChange = (event) => {
        props.onRequisitionDataChange(props.Requisition.id, event);
    }

    const handleOnboardingPhaseDataChange = (onboardingPhaseId, e) => {
        props.onboardingPhaseDataChange(props.Requisition.id, onboardingPhaseId, e);
    }

    const validateMinLeadPool = () => {
         return parseInt(props.Requisition.RequisitionData.MinLeadPool) < parseInt(props.Requisition.RequisitionData.HeadCount);
    }




    return (
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Accordion style={{width:"100%"}} className="form_group_item">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                        <InputLabel Title={"Requisition " + props.count} />
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container alignItems="left" className="form_group_list">
                        
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <InputLabel Title="Vendor" />
                                    <Formfieldstar />
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                    <SelectSingle id={"vendor"+ props.count.toString()} name="vendor" fieldName = "vendors" options={props.Requisition.Vendors} handleChange={vendorChangeHandler} tabIndex="0" isDisabled = {props.Requisition.isDisabled} />
                                    {props.IsFormSubmitted && !props.Requisition.Vendors.find((id) => id.is_selected) && (
                                        <Formvalidationerror fieldName = "Vendor" />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <InputLabel Title="Job Type" />
                                    <Formfieldstar />
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                    {props.isUpdate && props.Requisition.RequisitionStatus && props.Requisition.RequisitionStatus !==Constants.REQUISITIONS.STATUS.OPEN ? <FormFieldView Content={props.Requisition.JobTypes.filter((o)=>o.is_selected)[0].name}/>:
                                        <SelectSingle error id="jobType" name="jobType" fieldName = "job types" options={props.Requisition.JobTypes} handleChange={jobTypeChangeHandler} tabIndex="0"/>}
                                    { props.IsFormSubmitted && !props.Requisition.JobTypes.find((id) => id.is_selected) && (
                                        <Formvalidationerror fieldName = "Job type"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid container alignItems="top" className="form_group_list">

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <InputLabel Title="Head Count:" />
                                    <Formfieldstar />
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="HeadCount"
                                        value={props.Requisition.RequisitionData.HeadCount}
                                        onChange={handleRequisitionDataChange}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {props.IsFormSubmitted && !props.Requisition.RequisitionData.HeadCount && (
                                        <Formvalidationerror fieldName = "Head count"/>
                                    )}
                                    {props.IsFormSubmitted &&  parseInt(props.Requisition.RequisitionData.HeadCount) < 1  && (
                                        <FormNonZerovalidationerror fieldName = "Head count"/>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <InputLabel Title="Recommended Lead Pool:" />
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                    <InputText
                                        error
                                        name="MinLeadPool"
                                        value={props.Requisition.RequisitionData.MinLeadPool}
                                        onChange={handleRequisitionDataChange}
                                        FieldPlaceHolder="10"
                                        tabIndex="0"
                                    />
                                    {props.IsFormSubmitted && props.Requisition.RequisitionData.HeadCount && props.Requisition.RequisitionData.MinLeadPool && validateMinLeadPool() &&
                                        <FormComparevalidationerror fieldName = "Recommended lead pool"/>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>

                    {props.Requisition.OnboardingPhases.map(onboardingPhase => !isMenaUser() ? <OnboardingPhase IsFormSubmitted = {props.IsFormSubmitted} OnboardingPhase = {onboardingPhase} onboardingPhaseDataChange = {handleOnboardingPhaseDataChange} onCreateOnboardingPhase = {handleCreateOnboardingPhase} onDeleteOnboardingPhase = {handleDeleteOnboardingPhase} StartDate = {props.StartDate} EndDate = {props.EndDate} count = {props.Requisition.OnboardingPhases.findIndex(item => item.id === onboardingPhase.id) + 1} OnboardingPhases = {props.Requisition.OnboardingPhases} length = {props.Requisition.OnboardingPhases.length} isUpdate = {props.isUpdate}  tabIndex = "0" Requisitions = {props.Requisitions} RequisitionCount = {props.count} /> : <MENAOnboardingPhase IsFormSubmitted = {props.IsFormSubmitted} OnboardingPhase = {onboardingPhase} onboardingPhaseDataChange = {handleOnboardingPhaseDataChange} onCreateOnboardingPhase = {handleCreateOnboardingPhase} onDeleteOnboardingPhase = {handleDeleteOnboardingPhase} StartDate = {props.StartDate} EndDate = {props.EndDate} count = {props.Requisition.OnboardingPhases.findIndex(item => item.id === onboardingPhase.id) + 1} OnboardingPhases = {props.Requisition.OnboardingPhases} length = {props.Requisition.OnboardingPhases.length} isUpdate = {props.isUpdate}  tabIndex = "0" Requisitions = {props.Requisitions} RequisitionCount = {props.count} />)}
                </AccordionDetails>
            </Accordion>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                {!props.isUpdate && props.count === props.length && <SmallButton Title={constants.BUTTON.CREATE_REQUISITION} onClick={handleCreateRequisition} ButtonIcon={<AddRoundedIcon />}/>}
                {!props.isUpdate && props.count !== 1 && <SmallButton Title={constants.BUTTON.DELETE_REQUISITION} onClick={handleDeleteRequisition} ButtonIcon={ <DeleteIcon />} tabIndex = "0" />}
            </Grid>

        </Grid>
   
);
}

export default Requisition