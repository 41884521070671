export function compare(property,order) {
    let sortOrder = 1;
    if(order === "desc"){
        sortOrder = -1;
    }
    return function (sortObject1, sortObject2){
        let sortValue1=property && (typeof sortObject1[property] === "string")  ? sortObject1[property].toUpperCase() : sortObject1[property];
        let sortValue2=property &&  (typeof sortObject2[property] === "string") ? sortObject2[property].toUpperCase() : sortObject2[property];
        if(property === "headCount")
        {
             sortValue1=Number(sortObject1[property].split('/')[0]);
             sortValue2=Number(sortObject2[property].split('/')[0]);
        }
        if(property==="requisitionHeadCount")
        {
            sortValue1=Number(sortObject1["headCount"]);
            sortValue2=Number(sortObject2["headCount"]);
        }
        if(property === "HiringWeek") {
            sortValue1 = Number(sortObject1[property].split('N')[1]);
            sortValue2 = Number(sortObject2[property].split('N')[1]);
        }
        if(sortValue1< sortValue2){
            return -1 * sortOrder;
        }else if(sortValue1>sortValue2){
            return 1 * sortOrder;
        }else{
            return 0 * sortOrder;
        }
    }
}
