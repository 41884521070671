import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";
import {isUserMultiRegion} from "../../Utils/RegionUtil";

export const useGetAllRequisitionsWithoutFilers= () =>{
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS],()=> ActivityServices.getAllRequisitionsWithFilters(),{
        refetchOnWindowFocus:false,
        enabled: !isUserMultiRegion()
    })
}