import React from "react";
import {
    Grid,
    makeStyles
} from "@material-ui/core";
import InputText from "../../components/FormPage/InputText";
import InputLabel from "../../components/FormPage/InputLabel";
import constants from "../../Utils/Constants";
import Formfieldstar from "../../components/FormPage/FormFieldStar";
import {FormPastDatevalidationerror, Formvalidationerror} from "../../components/FormPage/FormValidationError";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import DeleteIcon from '@material-ui/icons/Delete';
import FormInputDatePicker from "../../components/FormPage/FormInputDatePicker";
import SmallButton from "../../components/SmallButton/SmallButton";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {disablePastDate} from "../../Utils/DateUtil";
import {getSelected} from "../../Utils/Util";
import {MuiPickersUtilsProvider,TimePicker} from '@material-ui/pickers'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {isMenaUser} from "../../Utils/RegionUtil";



function MenaOnboardingPhase(props) {
    makeStyles((theme) => ({
        root: {
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    }));

    const contractValidity = getSelected(props.Requisitions[props.RequisitionCount - 1].Vendors) ? getSelected(props.Requisitions[props.RequisitionCount - 1].Vendors).contractValidity : "";

    const handleCreateOnboardingPhase = (event) => {
        props.onCreateOnboardingPhase(event);
    }
    const handleDeleteOnboardingPhase = (event) => {
        event.preventDefault();
        props.onDeleteOnboardingPhase(props.OnboardingPhase.id);
    }
    const handleOnboardingPhaseDataChange = (e) => {
        props.onboardingPhaseDataChange(props.OnboardingPhase.id, e);
    }
    const handleOnboardingShift = (e) => {
        let onboardingTime = {target:{name:'OnboardingShift',
                value:e
            }}
        props.onboardingPhaseDataChange(props.OnboardingPhase.id, onboardingTime);
    }
    const getPhaseString = () => {
        return props.length > 1 ? "(Phase " + props.count + ")" : "";
    }

    const getMenaPhaseString = () => {
        return props.length > 1 ? " " + props.count : "";
    }

    const getOnboardingShiftTime = (OnboardingShift) =>{
        let newDate = new Date();
        if(!OnboardingShift){
            return null;
        }
        newDate.setHours(OnboardingShift.split(':')[0]);
        newDate.setMinutes(OnboardingShift.split(':')[1]);
        return newDate;
    }

    return (
        <Accordion container xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <InputLabel Title= {!isMenaUser() ? "Head Count" + getPhaseString() : "Onboarding phase" + getMenaPhaseString()} />
                </Grid>
            </AccordionSummary>

            <AccordionDetails container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center" className="form_group_item">
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <InputLabel Title="Head Count:" />
                                <Formfieldstar />
                            </Grid>
                            <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                <InputText
                                    error
                                    name="GeneralCount"
                                    value={props.OnboardingPhase.OnboardingPhaseData.GeneralCount}
                                    onChange={handleOnboardingPhaseDataChange}
                                    FieldPlaceHolder="10"
                                    tabIndex="0"
                                />
                                {props.IsFormSubmitted && !props.OnboardingPhase.OnboardingPhaseData.GeneralCount && (
                                    <Formvalidationerror fieldName = "General"/>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item  xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center" className="form_group_item">
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <InputLabel Title="Need by Date:" />
                                <Formfieldstar />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                {!props.isUpdate && (!props.StartDate || !contractValidity)  && <FormInputDatePicker
                                    error
                                    style={{ maxWidth: "175px" }}
                                    name="StartDate"
                                    value={props.OnboardingPhase.OnboardingPhaseData.StartDate}
                                    onChange={handleOnboardingPhaseDataChange}
                                    tabIndex="-1"
                                    isDisabled = {true}
                                /> }
                                {props.StartDate  && contractValidity &&
                                    <FormInputDatePicker
                                        error
                                        style={{ maxWidth: "175px" }}
                                        name="StartDate"
                                        value={props.OnboardingPhase.OnboardingPhaseData.StartDate}
                                        onChange={handleOnboardingPhaseDataChange}
                                        minDate = {props.count === 1 ? (disablePastDate(new Date(), 0)) :
                                            (new Date(props.OnboardingPhases[props.count - 2].OnboardingPhaseData.StartDate).getTime() > new Date().getTime() ? disablePastDate(new Date(props.OnboardingPhases[props.count - 2].OnboardingPhaseData.StartDate), 0) : disablePastDate((new Date()), 0))}
                                        maxDate = {props.StartDate ? props.StartDate < contractValidity ? props.StartDate : contractValidity : contractValidity}
                                        isDisabled  = {props.count === 1 ? false : !props.OnboardingPhases[props.count - 2].OnboardingPhaseData.StartDate}
                                        tabIndex={(props.count === 1 ? false : !props.OnboardingPhases[props.count - 2].OnboardingPhaseData.StartDate) ? "-1" : "0"}
                                    />}
                                {props.IsFormSubmitted && props.OnboardingPhase.OnboardingPhaseData.StartDate && new Date(props.OnboardingPhase.OnboardingPhaseData.StartDate)<new Date(disablePastDate(new Date(), -1)) &&  (
                                    <FormPastDatevalidationerror  fieldName = "Need by date"/>
                                )}
                                {props.IsFormSubmitted && !props.OnboardingPhase.OnboardingPhaseData.StartDate && (
                                    <Formvalidationerror fieldName = "Need by date"/>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Grid container alignItems="center" className="form_group_item">
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <InputLabel Title="Onboarding Shift:" />
                                <Formfieldstar />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker name={"TimePicker"}
                                                clearable={false}
                                                ampm={false}
                                                okLabel={"SET"}
                                                emptyLabel={"HH:MM"}
                                                onChange={handleOnboardingShift}
                                                value={getOnboardingShiftTime(props.OnboardingPhase.OnboardingPhaseData.OnboardingShift)}/>
                                </MuiPickersUtilsProvider>
                                {props.IsFormSubmitted && !props.OnboardingPhase.OnboardingPhaseData.OnboardingShift && (
                                    <Formvalidationerror fieldName="Onboarding Shift"/>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </AccordionDetails>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                {props.count === props.length && <SmallButton Title={constants.BUTTON.CREATE_ONBOARDING_PHASE} onClick={handleCreateOnboardingPhase} ButtonIcon={<AddRoundedIcon />} tabIndex = "0"/>}
                {props.count !== 1 && <SmallButton Title={constants.BUTTON.DELETE_ONBOARDING_PHASE} onClick={handleDeleteOnboardingPhase} ButtonIcon={ <DeleteIcon />} tabIndex = "0" />}
            </Grid>
        </Accordion>
    );
}

export default MenaOnboardingPhase;