import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";

export const useGetAllAuditTrailLogs= (body) =>{
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_AUDIT_LOGS, body],()=> ActivityServices.getAllAuditLogs( body),{
        enabled:body!==null,
        refetchOnWindowFocus:false,
        refetchOnMount: true
    })
}