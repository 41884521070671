import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";
import {isUserMultiRegion} from "../../Utils/RegionUtil";

export const useGetLaborOrder= (body, isEnabled) =>{

    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_LABORORDER,body],()=> ActivityServices.getLaborOrderById(body),{
        enabled:!isUserMultiRegion() && isEnabled,
        refetchOnWindowFocus:false
    })
}