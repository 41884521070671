import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: '#ffffff',
        backgroundColor: '#007980',
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      color: '#ffffff',
        backgroundColor: '#007980',
    },
  }));
  
function UserAvatar(props) {
    const classes = useStyles();
    const username = props.username;

    return (
        
        <div className={classes.root}>
            <Avatar className={classes.small}>{username?username.charAt(0):''}</Avatar>
       </div>
    )
}

export default UserAvatar
