import { orgAxios } from '../api/axios'
import {userDetailAxios} from "../index";


export const UserServices = {
    getUserById,
    getCurrentAuthUserName,
    createUser,
    getUser,
    getAppConfig
}

function getUserById(UserName='') {
    return orgAxios.get(`/user/${UserName}`)
}

function getCurrentAuthUserName() {
    return orgAxios.get(process.env.REACT_APP_AMAZON_AUTH_API)
}

function createUser(body) {
    return orgAxios.post(`/user`, body)
}

function getUser() {
    return userDetailAxios.get(`/user`)
}

function getAppConfig() {
    return userDetailAxios.get('/getAppConfig')
}