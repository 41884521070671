import {compare} from "./Comparator";

export function searchCandidate(candidates,search){
    if(search) {
        return candidates.filter(candidate => candidate.name.toLowerCase().includes(search.toLowerCase()))
    }
    return candidates
}

export function filterRequisitionsByCandidateManagement(requisitions,endDate,businessLine,sites){
    if(endDate) {
        requisitions = requisitions.filter(arr => arr.startDate <= endDate)
    }
    if(businessLine) {
        requisitions = requisitions.filter(arr => arr.businessLine === businessLine)
    }if(sites.length !== 0) {
        requisitions =  requisitions.filter(function (requisition) {
            return requisition.sites.some(site => {
                return sites.indexOf(site) >= 0
            })
        })
    }
    return requisitions
}

export function sortCandidates(rows,sortColumn,order){
    return rows.sort(compare(sortColumn,order))
}