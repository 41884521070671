import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";

export const useGetRlcStatus= (body,isEnabled) =>{
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_RLC_STATUS,body],()=> ActivityServices.getRlcStatusByLaborOrderId(body),{
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        refetchOnMount:true
    })
}