import {eventTaskAxios, eventTaskAxiosWithoutCounter} from '../api/axios'

export const ActivityServices = {
    getTotalActivities,
    getLaborOrderById,
    addActivity,
    editActivity,
    removeActivity,
    addRequisition,
    getAllRequisitions,
    getRequisitionById,
    removeRequisition,
    editRequisition,
    downloadCandidateData,
    downloadCandidateDataByLO,
    getAllCommentsById,
    getAllAuditLogs,
    createComment,
    updateComment,
    uploadAttachments,
    getTotalActivitiesWithFilters,
    getAllRequisitionsWithFilters,
    getAuthorizedSites,
    getAuthorizedUsers,
    getAuthorizedUsersForMultiSites,
    getAuthorizedCountriesForUser,
    validateLaborOrder,
    deleteAttachment,
    getAttachment,
    bulkUpdateLaborOrders,
    bulkUpdateRequisitions,
    getAllVendors,
    createRLCApproval,
    getRLCApproval,
    uploadRlcAttachments,
    deleteRlcAttachment,
    getRlcStatusByLaborOrderId
}

function getTotalActivities(url, body = {}) {
    return eventTaskAxios.post('/laborOrders/getAllLaborOrders', body)
}

function getTotalActivitiesWithFilters( body) {
    return eventTaskAxios.post('/laborOrders/getAllLaborOrdersWithFilters', body)
}

function getLaborOrderById(laborOrderId) {
    return eventTaskAxios.get(`/laborOrders/${laborOrderId}`)
}

function addActivity(body = {}) {
    return eventTaskAxios.post('/laborOrders', body)
}

function createRLCApproval(body = {}) {
    return eventTaskAxios.post('/laborOrders/createRLCApproval', body)
}

function getRLCApproval(body = {}) {
    return eventTaskAxios.post('/laborOrders/getRLCApproval', body)
}

function editActivity(body = {}) {
    return eventTaskAxios.put(`/laborOrders`, body)
}

function removeActivity(body ={}) {
    return eventTaskAxios.put('/laborOrders/delete',body)
}

function addRequisition(body ={}) {
    return eventTaskAxios.post('/requisitions', body)
}

function getAllRequisitions(url, body) {
    return eventTaskAxios.post('/requisitions/getAllRequisitions', body);
}

function getAllRequisitionsWithFilters( body) {
    return eventTaskAxios.post('/requisitions/getAllRequisitionsWithFilters', body);
}

function getRequisitionById(laborOrderId, requisitionId) {
    return eventTaskAxios.get(`/requisitions`,{
        params: {
            laborOrderId: laborOrderId,
            requisitionId: requisitionId
        }
    });
}

function removeRequisition(body) {
    return eventTaskAxios.put('/requisitions/delete', body)
}

function editRequisition(body) {
    return eventTaskAxios.put('/requisitions', body);
}

function downloadCandidateData(body){
    return eventTaskAxios.post('/requisitions/downloadCandidateData',body)
}

function downloadCandidateDataByLO(body){
    return eventTaskAxios.post('/laborOrders/downloadCandidateData',body)
}

function uploadAttachments(body,onUploadProgress){
    return eventTaskAxiosWithoutCounter.post('/comments/uploadAttachments', body,{
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress,
    });
}

function deleteAttachment(body){
    return eventTaskAxiosWithoutCounter.post('/comments/deleteAttachment', body);
}

function uploadRlcAttachments(body,onUploadProgress){
    return eventTaskAxiosWithoutCounter.post('/comments/uploadRlcAttachments', body,{
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress,
    });
}

function deleteRlcAttachment(body){
    return eventTaskAxiosWithoutCounter.post('/comments/deleteRlcAttachment', body);
}

function getAttachment(body){
    return eventTaskAxios.post('/comments/getAttachment', body);
}

function createComment(body){
    return eventTaskAxios.post('/comments/create', body);
}

function getAllCommentsById(parentId) {
    return eventTaskAxios.get(`/comments/getAllComments`,{
        params: {
            parentId: parentId
        }
    })
}

function updateComment(body){
    return eventTaskAxios.post(`/comments/update`, body);
}

function getAuthorizedSites(body){
    return eventTaskAxios.post(`/authorization/getAuthorizedSitesForUser`,body)
}

function getAuthorizedUsers(body){
    return eventTaskAxios.post(`/authorization/getAuthorizedUsersForSite`,body)
}

function getAuthorizedUsersForMultiSites(body){
    return eventTaskAxios.post(`/authorization/getAuthorizedUsersForMultiSites`,body)
}

function getAuthorizedCountriesForUser(alias){
    return eventTaskAxios.get(`/authorization/getAuthorizedCountriesForUser`,{params:{userAlias:alias}})
}

function validateLaborOrder(body){
    return eventTaskAxios.post(`/laborOrders/validateLaborOrder`,body)
}

function bulkUpdateLaborOrders(body){
    return eventTaskAxios.post(`/laborOrders/bulkUpdateLaborOrders`,body)
}

function bulkUpdateRequisitions(body){
    return eventTaskAxios.post(`/requisitions/bulkUpdateRequisitions`,body)
}

function getAllAuditLogs(body){
    return eventTaskAxios.post('/laborOrders/getAuditLogs',body);
}

function getAllVendors(body) {
    return eventTaskAxios.post('/orgs/filter', body)
}

function getRlcStatusByLaborOrderId(body){
    return eventTaskAxiosWithoutCounter.post('/laborOrders/getRLCApproval', body)
}