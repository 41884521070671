import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClientProvider, QueryClient} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools"
import './variables.scss';
import './components/Header/Header.scss';
import './components/Logo/Logo.scss';
import './components/TabBar/TabMain.scss'
import './index.scss';
import './global.scss';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UserServices} from "./Services/users";
import axios from "axios";
import constants from "./Utils/Constants";
const cathodeVersions = require('@amzn/cathode-versions-javascript');

let userDetailBaseURL

let userDetailAxios

let stage

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000*60*30,
      retry:false
    },
  },
});

const init = async () => {
  userDetailBaseURL = sessionStorage.getItem('userDetailBaseURL');

  if(!(userDetailBaseURL)){
    await axios.get('/settings.json')
    .then((res)=>{
      console.log("fetching environment specific URLs")
      userDetailBaseURL = res.data.userDetails
      stage = res.data.stage
    }).catch((err)=>{
      console.log("configuration file not found")
      userDetailBaseURL = process.env.REACT_APP_USER_DETAIL_URL
          stage = process.env.REACT_APP_STAGE
    })

    sessionStorage.setItem('userDetailBaseURL',userDetailBaseURL);
    sessionStorage.setItem(constants.SPECTROMETER.STAGE,stage);

  }
  await setAxios()
  await getUserDetails()

}

const setAxios = () => {
  userDetailAxios = axios.create({
    withCredentials: true,
    baseURL: userDetailBaseURL,
    timeout: 180000
  })
}


const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#007980',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#232f3e',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      'Amazon Ember'
    ].join(','),
  },
});

const getUserDetails = async () => {
  await UserServices.getUser().then((data)=>{
    const userObject = {id:data["data"]["login"],name: data["data"]["login"]};
    console.log("Getting user information from Midway");
    sessionStorage.setItem("session_user",JSON.stringify(userObject));
    window.user = userObject;
    ReactDOM.render(
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
          </ThemeProvider>
        </React.StrictMode>,

        document.getElementById('root')
    );
  }).catch((ex) => {
    console.log("Error fetching user");
    ReactDOM.render(
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
          </ThemeProvider>
        </React.StrictMode>,

        document.getElementById('root')
    );
  });
}

stage = sessionStorage.getItem(constants.SPECTROMETER.STAGE);
//User analytics integration(Spectrometer)
const cathodeConfig = {
  siteName: constants.SPECTROMETER.SITE_NAME,
  appName: (stage !== null &&  stage !== undefined) ? constants.SPECTROMETER.APP_NAME+"-"+stage
      : constants.SPECTROMETER.APP_NAME
}

const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, true)
document.write(scriptTags.boomerangLoaderScript)
document.write(scriptTags.listenerScripts)
document.write(scriptTags.cathodeScript)


init();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export {userDetailAxios}
