import React, {forwardRef, useEffect, useState} from 'react'
import DataTable from '../../components/DataTable/DataTable'
import { ActivityServices } from '../../Services/activities'
import { useLocation, useHistory } from 'react-router-dom'
import constants, {AuthorizationType} from '../../Utils/Constants';
import {searchRequisition, sortRequisitions} from "../../Utils/RequisitionGridOperations";
import {paginate} from "../../Utils/LaborOrderGridOperations";
import {customSortRequisition} from "../../Utils/customSort";
import UnauthorizedAccessDialog from "../PageNotFound/UnauthorizedAccessDialog";
import {useGetAllRequisitions} from "../../ReactQuery/hooks/useGetAllRequisitions";
import { useQueryClient} from 'react-query'
import {disablePastDate} from "../../Utils/DateUtil";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {filterListByCountry} from "../../Utils/RegionUtil";

function RequisitionsGrid(props, ref) {

    const [rowData, setRowData] = React.useState(null);
    const [totalRowsCount, setTotalRowsCount] = React.useState(0);
    const [paginationConfig, setPaginationConfig] = React.useState(props.config);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");
    const [requestBody, setRequestBody] = useState(null)

    const queryClient = useQueryClient()
    const {data: queryRequisitions, error: errorGetAllRequisitions, isFetching:requisitionsLoading} = useGetAllRequisitions(requestBody)

    let urlQueryParams = useLocation();
    let history = useHistory();
    let defaultSortColumn = 'id';
    let defaultSortOrder = 'DESC';

    const tableHeader = [
        { id: 'LaborOrderId', title: 'Labor Order', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
        { id: 'RequisitionId', title: 'Requisition', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'HiringWeek', title: 'Hiring Week', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
        { id: 'Site', title: 'Site', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'VendorName', title: 'Vendor', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'JobRole', title: 'Role', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'startDate', title: 'Need by date', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'FillRate', title: 'Fill Rate', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
        { id: 'status', title: 'Status', sortable: true, headerStyle: { textTransform: 'capitalize'}, rowStyle: { textTransform: 'capitalize' }, visibility: true }
    ]

    useEffect(() => {
        getData()
        let params = new URLSearchParams(urlQueryParams.search);
        let paginationConfigL = {};
        paginationConfigL.sortColumn = params.get('sortColumn') ? params.get('sortColumn') : defaultSortColumn;
        paginationConfigL.sortOrder = params.get('sortOrder') ? params.get('sortOrder') : defaultSortOrder;
        paginationConfigL.pageNo = params.get('pageNo') ? parseInt(params.get('pageNo')) : 1;
        paginationConfigL.pageSize = params.get('pageSize') ? parseInt(params.get('pageSize')) : 50;
        paginationConfigL.searchColumn = params.get('searchColumn') ? params.get('searchColumn') : '';
        paginationConfigL.searchValue = params.get('searchValue') ? params.get('searchValue') : '';
        paginationConfigL.externalFilters = {vendor: '', jobType: ''}
        if (params.get('sortColumn')) {
            paginationConfigL.sortColumn = params.get('sortColumn');
            paginationConfigL.sortOrder = params.get('sortOrder');
        }
        if (params.get('pageNo')) {
            paginationConfigL.pageNo = parseInt(params.get('pageNo'));
        }
        if (params.get('pageSize')) {
            paginationConfigL.pageSize = parseInt(params.get('pageSize'));
        }
        if (params.get("vendor")) {
            paginationConfigL.externalFilters.vendor = params.get("vendor");
        }

        if (params.get("jobType")) {
            paginationConfigL.externalFilters.jobType = params.get("jobType");
        }

        setPaginationConfig(paginationConfigL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearchClick = (searchValue) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchValue;
        paginationConfigL.searchColumn = searchValue ? 'name' : '';
        setPaginationConfig(paginationConfigL);
    }

    const handleExternalFilters = (filters) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.externalFilters = filters;
        paginationConfigL.externalFiltersApplied = props.config.externalFiltersApplied;
        setPaginationConfig(paginationConfigL);
    }

    useEffect(() => {
        if (paginationConfig && paginationConfig.pageSize) {
            operateOnRows()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationConfig, globalRows])

    useEffect(() => {
        if ((props.config.searchValue && props.config.searchValue!=="") || props.config.searchValue==='') {
            handleSearchClick(props.config.searchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.searchValue])

    useEffect(() => {
        if ((props.config.externalFilters)) {
            handleExternalFilters(props.config.externalFilters)
        }
        if(props.config.externalFiltersApplied || props.config.externalFilters.resetAppliedFilter)
            getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.externalFilters])

    const handleSort = (clickedColumn) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.sortColumn = clickedColumn;
        paginationConfigL.sortOrder = paginationConfigL.sortOrder && paginationConfigL.sortOrder === 'asc' ? 'desc' : 'asc';
        setPaginationConfig(paginationConfigL);
    };

    const handlePage = (page) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = page + 1;
        setPaginationConfig(paginationConfigL);
    };

    const handlePageSize = (pageSize) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.pageSize = pageSize;
        setPaginationConfig(paginationConfigL);
    };

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData = searchRequisition(rowsData, paginationConfig.searchValue);
        rowsData = sortRequisitions(rowsData,paginationConfig.sortColumn,paginationConfig.sortOrder);
        setTotalRowsCount(rowsData.length);
        rowsData = paginate(rowsData ,paginationConfig.pageSize, paginationConfig.pageNo);
        setRowData(rowsData);
    }

    const getData = () => {
        let body = {
            laborOrderId: props.LOid,
            alias: JSON.parse(sessionStorage.getItem('session_user')).id,
            filterBy: "LR",
        };
        if(!props.config.externalFiltersApplied){
            body.resetAppliedFilter = props.config.externalFilters.resetAppliedFilter;
        }
        else {
            body = {...body,...props.config.externalFilters}
        }
        if(!props.LOid){
            setRequestBody(body)
        }

        let rows, filters;
        if(props.LOid){
            ActivityServices.getAllRequisitions(null,body).then((resp) => {
                rows = resp && resp.data && resp.data.RequisitionInfos
                props.enableFilters(false)
                props.downloadRequisitionHandler(rows);
                populateGrid(rows, filters)
            }).catch((error) => {
                if (error && error.data && (error.data.message === constants.ERROR.NO_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.SITE_BASED);
                }
                else if (error && error.data && (error.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                    setShowUnauthorizedDialog(true);
                    setAuthType(AuthorizationType.BRASS);
                }
                setRowData([]);
                setTotalRowsCount(0);
            })
        }

    };

    useEffect(() => {
        setRowData(null)
    },[requestBody])

    useEffect(() => {
        if(errorGetAllRequisitions){
            if ( errorGetAllRequisitions.data && (errorGetAllRequisitions.data.message === constants.ERROR.NO_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if ( errorGetAllRequisitions.data && (errorGetAllRequisitions.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }
            else if (errorGetAllRequisitions.data && (errorGetAllRequisitions.data.statusCode === constants.ERROR.CODE.PAYLOAD_TOO_LARGE)){
                props.snackbarShowMessage(
                    `${constants.ERROR.FETCHING_ERROR} ${errorGetAllRequisitions.data.message}`,
                    "error",
                    "4000",
                    ""
                );
            }
            else if (errorGetAllRequisitions.data && (errorGetAllRequisitions.data.statusCode === constants.ERROR.CODE.GATEWAY_TIMEOUT)){
                props.snackbarShowMessage(
                    `${constants.ERROR.FETCHING_ERROR} ${constants.ERROR.BACKEND_TIMEOUT_ERROR}`,
                    "error",
                    "4000",
                    ""
                );
            }
            setRowData([]);
            setTotalRowsCount(0);
            queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS,null])
        }
        else{
            const queryData = queryClient.queryCache
                .findAll(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                .sort((a, b) => b.state.dataUpdatedAt - a.state.dataUpdatedAt)[0].state.data
            let rows = queryData && queryData.data && queryData.data.requisitionInfos;
            let filters = queryData && queryData.data && queryData.data.filterData;
            props.downloadRequisitionHandler(rows);
            props.enableFilters(true)
            if(!requisitionsLoading)
            populateGrid(rows, filters)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[queryRequisitions, errorGetAllRequisitions])

    const populateGrid = (rows, filters) => {
        let rowsData = []
        if (rows) {
            rows.forEach(row => {
                rowsData.push({
                    startDate: row.onboardingPhaseDetails && row.onboardingPhaseDetails.length > 0 ? getLatestOnboardingPhaseDate(row.onboardingPhaseDetails) : "",
                    status: row.requisitionStatus === constants.STATUS.WIP_DB ? constants.STATUS.WIP_FRONTEND : row.requisitionStatus,
                    statusPreferenceCode : constants.STATUS_PREFERENCE_CODES.REQUISITIONS[row.requisitionStatus],
                    VendorId : row.vendorId ? row.vendorId : "",
                    LaborOrderId : row.laborOrderId ? row.laborOrderId : "",
                    JobId : row.jobId ? row.jobId : "",
                    RequisitionId :row.requisitionId ? row.requisitionId : "",
                    Site : row.site ? row.site : "",
                    JobRole : row.jobRole ? row.jobRole : "",
                    country: row.country ? row.country : constants.INDIA_REGION,
                    FillRate : row.headCount ? row.fillRate? row.fillRate+"/"+row.headCount : "0/"+row.headCount : "0",
                    JobType : row.jobType ? row.jobType : "",
                    VendorName : row.vendorName ? row.vendorName : "",
                    MinLeadPool : row.minLeadPool ? row.minLeadPool : "",
                    Balance : row.balance ? row.balance : "0",
                    RequisitionStatus : row.requisitionStatus ?( row.requisitionStatus === constants.STATUS.WIP_DB ? constants.STATUS.WIP_FRONTEND : row.requisitionStatus): "",
                    Reason : row.reason? row.reason : "",
                    Comment : row.comment? row.comment : "",
                    CreatedDate : row.createdDate ? row.createdDate : "",
                    WfsWatchersList : row.wfsWatchersList? row.wfsWatchersList : [],
                    VendorWatchersList : row.vendorWatchersList? row.vendorWatchersList : [],
                    CandidateCount : row.candidateCount ? row.candidateCount : "0",
                    BusinessLine : row.businessLine ? row.businessLine : "",
                    HiringWeek : row.hiringWeek ? row.hiringWeek : "-",
                    RequisitionOldStatus : row.requisitionOldStatus ? row.requisitionOldStatus : null,
                    OnboardingPhaseDetails: row.onboardingPhaseDetails? row.onboardingPhaseDetails:[],
                    Tenure: row.tenure ? row.tenure:null,
                    LaborOrderEndDate : row.endDate? row.endDate:null,
                    is_selected: false
                });
            });
            rowsData = filterListByCountry(rowsData)
        }
        if(filters){
            props.updateFilter(filters);
        }
        else{
            let externalFilters = {}
            externalFilters.jobRoles=[];
            externalFilters.countries=[];
            externalFilters.vendorId='';
            externalFilters.status=["OPEN","ACCEPTED","WORK_IN_PROGRESS","DECLINED"];
            externalFilters.sites = [];
            externalFilters.startDate = disablePastDate(new Date(),-7);
            externalFilters.endDate = disablePastDate(new Date(), 21);
            externalFilters.startHiringWeek = "";
            externalFilters.endHiringWeek = "";
            externalFilters.resetAppliedFilter = true;
            props.updateFilter(externalFilters)
        }
        setRowData(customSortRequisition(rowsData));
        props.setSelectedRequisitionsList(rowsData)
        setGlobalRows(rowsData);
        setTotalRowsCount(rowsData.length)
    }

    const rowAction = (e, row) => {
        history.push({pathname:`/${constants.REQUISITIONS.ROUTE.VIEW}/${row.LaborOrderId}&${row.RequisitionId}`});
    }

    const getIndex = (rowId, rows, isSelectionNeeded) => {
        let selectedIndex = -1
        for(let index = 0; index < rows.length; index++) {
            if(rows[index].RequisitionId === rowId) {
                if((isSelectionNeeded && rows[index].is_selected) || !isSelectionNeeded ) {
                    selectedIndex = index;
                    break;
                }
            }
        }
        return selectedIndex
    }

    const rowSelect = (e, row) => {
        const selectedIndex = getIndex(row.RequisitionId, rowData,false);
        let newRowData = [...rowData];
        newRowData[selectedIndex].is_selected = !newRowData[selectedIndex].is_selected;
        setRowData(newRowData);
        props.setSelectedRequisitionsList(globalRows)

    }

    const handleSelectAllClick = (event) => {
        let newRowData = [...rowData];
        newRowData.forEach(row => row.is_selected = event.target.checked)
        setRowData(newRowData);
        props.setSelectedRequisitionsList(globalRows)

    };

    const getRowsCheckedCount = (rowData) => {
        let checkedCount = 0;
        if(rowData)
        rowData.forEach(row => checkedCount = row.is_selected ?  checkedCount + 1: checkedCount);
        return checkedCount;
    }

    const getLatestOnboardingPhaseDate = (onboardingPhases) => {
        let currentDate = new Date()
        currentDate = currentDate.toISOString().split('T')[0];
        let latestPhaseDate = onboardingPhases[onboardingPhases.length - 1].startDate;
        for(let index = 0; index < onboardingPhases.length; index++) {
            if(onboardingPhases[index].startDate >= currentDate) {
                return onboardingPhases[index].startDate
            }
        }
        return latestPhaseDate;
    }

    return (
        <div className='amz_datatable activities_grid'>
            <DataTable
                isLaborOrder = {false}
                columnData={tableHeader}
                rowData={(requisitionsLoading || !rowData) ? [] :rowData}
                pageSize={paginationConfig.pageSize ? paginationConfig.pageSize : 25}
                handlePageSize={handlePageSize}
                pageNo={paginationConfig.pageNo ? paginationConfig.pageNo : 1}
                handlePage={handlePage}
                handleSort={handleSort}
                sortColumn={paginationConfig.sortColumn ? paginationConfig.sortColumn : null}
                sortOrder={paginationConfig.sortOrder ? paginationConfig.sortOrder : null}
                count={totalRowsCount}
                rowAction={rowAction}
                noDataFound={(requisitionsLoading || !rowData)? <CircularProgress/> : 'No Requisitions Found'}
                getIndex = {getIndex}
                rowSelect = {rowSelect}
                onSelectAllClick = {handleSelectAllClick}
                getRowsCheckedCount = {getRowsCheckedCount}
            />
            {showUnauthorizedDialog && (
                <UnauthorizedAccessDialog
                    handleCancel={() => {
                        setShowUnauthorizedDialog(false);
                    }}
                    authorizationType={authType}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
        </div>
    )
}

export default withAlertSnackBar(forwardRef(RequisitionsGrid))
