import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Private from "../../Routes/Private";
import PageNotFound from "../PageNotFound/PageNotFound";
import React from "react";
import CandidateDataReports from "./CandidateDataReports";
import ReportTabs from "../../components/TabBar/ReportTabs";

function Reports() {
    return (
        <>
            <Router>
                <div className="Reports">
                    <ReportTabs/>
                </div>
                <Switch>
                    <Private path="/reports" exact component={CandidateDataReports} />
                    <Private path="/reports/candidateData" exact component={CandidateDataReports} />
                    <Route path="*" exact component={PageNotFound} />
                </Switch>
            </Router>
        </>
    );
}

export default Reports