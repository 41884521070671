import { orgAxios } from '../api/axios'
import { eventTaskAxios } from '../api/axios'


export const OrganizationServices = {
    getTotalOrganizations,
    getSiteByVendor,
    removeOrganization,
    getAllParentVendors
}

function getTotalOrganizations(url, body, isGet = false) {
    if(isGet) {
        return eventTaskAxios.get(url)
    }
    else{
        return orgAxios.post(url, body)
    }
}
function removeOrganization(organizationId, UserId) {
    return orgAxios.delete(`/orgs/${organizationId}?userId=${UserId}`)
}

function getSiteByVendor(id) {
    return orgAxios.get('/orgs/sitesbyvendor/' + id)
}

function getAllParentVendors(){
    return eventTaskAxios.get('/orgs/listParentVendors');
}
