import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {
    DialogActions,
    DialogContent, FormControl,
    InputLabel,
    OutlinedInput,
    Select, Switch,
    tableCellClasses,
    TextField
} from "@mui/material";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import {formatDate} from "../../Utils/DateUtil";
import {StencilProvider} from "@amzn/stencil-react-components/context";
import {RichTextEditor} from '@amzn/stencil-react-components/rte'
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {styled} from "@mui/material/styles";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import AddWatchersOrApprovers from "../../pages/Common/AddWatchersOrApprovers";
import SendIcon from '@mui/icons-material/Send';
import MenuItem from "@mui/material/MenuItem";
import constants from "../../Utils/Constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoHelpIcon from "../FormPage/InfoHelpIcon";
import Constants from "../../Utils/Constants";
import {useEffect, useRef, useState} from "react";
import {ActivityServices} from "../../Services/activities";
import LinearWithValueLabel from "../ProgressBar/LinearLabelProgressBar";
import {useGetAllSites} from "../../ReactQuery/hooks/useGetAllSites";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ALIAS_DENY_LIST} from "../../Utils/CreateRLCDenyList";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const approvalPriorityList = [
    {
        value: 'Low',
        label:'Low'
    },
    {
        value: 'Medium',
        label:'Medium'
    },
    {
        value: 'High',
        label:'High'
    }
];

function createData(id,inputName, inputValues, inputType,showFeild) {
    return { id,inputName, inputValues, inputType,showFeild};
}

const keyDetailsInputList = [
    createData('keyDetailsInput01','Approval for n/n+1/n+2', ["n","n+1","n+2"], "MultiSelect",true),
    createData('keyDetailsInput02','Reason Code', ["1: Volume Change","2: TBH/BTPH Change","3: Absenteeism Assumption Change","4: Attrition Assumption Change","5: New Projects At Site","6: Low ALFA Turnout","7: L&D Capacity Constraints","8: WFS Fill Miss or Delay/ Other WFS Constraints","9: Uncontrollable Event (weather, riots, etc.)","10: New Launch/Closures (Temp/Permanent)","11: Transfers from/To other sites","12: Others (Pls comment)"], "MultiSelect",true),
    createData('keyDetailsInput08','Reason if Reason Code is 12 (Others)', "", "Text",true),
    createData('keyDetailsInput03','Site Name', "", "Text",true),
    createData('keyDetailsInput04','Change in HC requirement', ["HC revision - Upward","HC revision - Downward","OB - Postponed","OB - Preponed","Other","No Change"], "SingleSelect",true),
    createData('keyDetailsInput05','Change in Need by date', ["Yes","No"], "SingleSelect",true),
    // createData('keyDetailsInput06','Change in URC split', ["Yes","No"], "MultiSelect",true),
    // createData('keyDetailsInput07','Change in Contract Tenure', ["Yes","No"], "MultiSelect",true)
];

function CreateRLCModal(props) {

    const getApprovalKeyDetailPairValue = (keyDetail) =>{
        if(keyDetail.inputName==="Site Name") return props.site.join(', ');
        return keyDetail.inputType==="Text"?"":[]
    }

    const [open, setOpen] = React.useState(props.isActive);
    const approvalTitle = React.useRef(`WFS_LO_Submission: India-<${props.site.join(', ')}>-<current finance week #>`);
    const approvalNeedByDate = React.useRef(formatDate(new Date(),+1));
    const approvalPriority = React.useRef("");
    const approvalSummary = React.useRef("");
    const [approversList, setApproversList] = React.useState([]);
    const [viewersList, setViewersList] = React.useState([]);
    const [adminsList, setAdminsList] = React.useState([]);
    const [approvalKeyDetailsPair,setApprovalKeyDetailsPair] = React.useState(keyDetailsInputList.map(function(keyDetail){return {id:keyDetail.id,name:keyDetail.inputName,value:getApprovalKeyDetailPairValue(keyDetail),isSelected:false}}));
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [progressInfos, setProgressInfos] = React.useState([{uploadOperation:false}]);
    const attachmentsAsync = useRef([]);
    const [fileLimit,setFileLimit] = useState(false);
    const isApproversGrouped = useRef(false);
    const [showLoadingIndicator,setShowLoadingIndicator] = useState(false);
    const [showAuthorizedUsersLoadingIcon,setAuthorizedUsersLoadingIcon] = useState(false);
    const formSubmitError = useState("");
    const [isSubmitButtonDisable, setIsSubmitButtonDisable,] = useState(true);

    const {data: querySites, isFetching: sitesLoading, error: sitesError} = useGetAllSites();

    //watchers for the lo site, used to prepopulate approval viewers
    useEffect(() => {
        if(sitesError){
            props.snackbarShowMessage(`${constants.ERROR.FETCHING_SITE_VIEWER}`, "error");
            setIsSubmitButtonDisable(false)
        }
        else if(querySites){
            let querySitesList = (querySites && querySites.data) || [];
            let siteViewerListL = [];
            querySitesList.filter((m) => props.site.includes(m.siteId)).map((m,idx,currArray)=>(m.agencyName && m.agencyName!=="None")?m.agencyName.split(',').forEach(obj=>!ALIAS_DENY_LIST.includes(obj)&&siteViewerListL.push(obj)):"");
            let viewerListL = [...viewersList,...new Set(siteViewerListL)]
            setViewersList(viewerListL);
            setIsSubmitButtonDisable(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[querySites,sitesError])


    //POC's & admin for the lo site,, used to prepopulate approval admins
    useEffect(() =>{
        updateSitePocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() =>{
        if(progressInfos && progressInfos[0] && progressInfos[0].uploadOperation){
            progressInfos.forEach((progressFile,index) =>{
                if(index>0 && progressFile.percentage===0){
                    const formDataRequest = new FormData();
                    formDataRequest.append("prefix",props.parentID);
                    formDataRequest.append("files", progressFile.file);
                    uploadAttachmentsHandler(formDataRequest,index).then((res) =>{
                        progressInfos[index].percentage = 100;
                        setProgressInfos(progressInfos);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[progressInfos]);

    const updateSitePocs = async () => {
        setAuthorizedUsersLoadingIcon(true);
        await ActivityServices.getAuthorizedUsersForMultiSites({sites:props.site,withAdmins:true}).then (( res ) => {
            setAuthorizedUsersLoadingIcon(false);
            let adminsListL=[...adminsList, ...new Set(res.data.authorizedUsers)];
            adminsListL = adminsListL.filter(obj => !ALIAS_DENY_LIST.includes(obj));
            setAdminsList (adminsListL);
        }).catch (( error ) => {
            setAuthorizedUsersLoadingIcon(false);
            props.snackbarShowMessage (`${constants.ERROR.FETCHING_SITE_ADMIN}`, "error")
        });
    }

    const uploadAttachmentsHandler = async (formDataRequest,index) => {
        let _progressInfos=[...progressInfos];
        _progressInfos[0].uploadOperation=false;
        return await ActivityServices.uploadRlcAttachments (formDataRequest, ( event ) => {
            _progressInfos[index].percentage=Math.round ((100 * event.loaded) / event.total);
            setProgressInfos (_progressInfos);
        }).then (( res ) => {
            if(res.data && res.data.error){
                props.snackbarShowMessage(`${res.data.error}`, "error")
            }else {
                let data=res && res.data;
                attachmentsAsync.current.push ({attachmentFileUri:data.key,attachmentName:data.name,title:data.name});
            }
            formDataRequest.delete ("files");
            return res;
        }).catch (( error ) => {
            props.snackbarShowMessage (`${constants.ERROR.COMMENT_ADD_ERROR}:${error}`, "error")
        })
    }

    const handleKeyDetailsPairChange = (event,changedKeyDetailPair) => {
        const approvalKeyDetailsPairL = [...approvalKeyDetailsPair];
        const objIndex = approvalKeyDetailsPairL.findIndex(obj => obj.id === changedKeyDetailPair.id);
        approvalKeyDetailsPairL[objIndex].isSelected = true;
        approvalKeyDetailsPairL[objIndex].value = event.target.value;
        setApprovalKeyDetailsPair(approvalKeyDetailsPairL);
    };

    const handleClose = () => {
        props.onClose();
        approvalTitle.current = `WFS_LO_Submission: India-<${props.site.join(', ')}>-<current finance week #>`;
        approvalSummary.current = "";
        approvalPriority.current = "";
        approvalNeedByDate.current = formatDate(new Date(),+1);
        setApprovalKeyDetailsPair(keyDetailsInputList.map(function(keyDetail){return {id:keyDetail.id,name:keyDetail.inputName,value:[],isSelected:false}}));
        setUploadedFiles([]);
        setProgressInfos([{uploadOperation:false}]);
        setAdminsList([]);
        setViewersList([]);
        setApproversList([]);
        setOpen(false);
    };

    const validateInput = ()=>{
        let errorString = [];
        if(!approvalNeedByDate.current){
            errorString.push(constants.VALIDATE_CREATE_RLC_INPUT_ERRORS.NEED_BY_REQUIRED);
        }if(approvalKeyDetailsPair.find (obj => obj.name==="Change in HC requirement").value.length===0 ){
            errorString.push(constants.VALIDATE_CREATE_RLC_INPUT_ERRORS.HC_REQUIREMENT_REQUIRED)
        }if(approversList.length===0){
            errorString.push(constants.VALIDATE_CREATE_RLC_INPUT_ERRORS.ONE_APPROVER_REQUIRED)
        }
        formSubmitError.current = errorString.join(", ");
        return errorString.length===0;
    }

    const handleCreate = () => {
        if(validateInput()) {
            const createRLCRequestBody={};
            createRLCRequestBody.laborOrderIdList=props.LaborOrderIds.map (function ( obj ) {
                return obj.laborOrderId
            });
            createRLCRequestBody.title=approvalTitle.current;
            createRLCRequestBody.neededOnDate=approvalNeedByDate.current;
            createRLCRequestBody.priority=approvalPriority.current? approvalPriority.current.toUpperCase():constants.RLC_PRIORITY_LIST.LOW.toUpperCase();
            createRLCRequestBody.summary=approvalSummary.current;
            createRLCRequestBody.rlcType=approvalKeyDetailsPair.find (obj => obj.name==="Change in HC requirement").value.length!==0 ? approvalKeyDetailsPair.find (obj => obj.name==="Change in HC requirement").value : "";
            createRLCRequestBody.viewerList=viewersList.map (obj => obj.trim ());
            createRLCRequestBody.approvalAdminList=adminsList.map (obj => obj.trim ());
            createRLCRequestBody.attributeList=keyDetailsInputList.map (function ( obj ) {
                    const inputType=(obj.inputType==="SingleSelect") ? "SINGLE_DROPDOWN" : (obj.inputType==="MultiSelect") ? "MULTI_DROPDOWN" : "SIMPLE_TEXT";
                    const keyDetailPairIndex=approvalKeyDetailsPair.findIndex (keyDetailpair => keyDetailpair.id===obj.id);
                    const keyDetailPairSelectedValue=approvalKeyDetailsPair[keyDetailPairIndex].value;
                    let inputValue=keyDetailPairSelectedValue;
                    if (inputType==="SINGLE_DROPDOWN" || inputType==="MULTI_DROPDOWN") {
                        inputValue=[];
                        obj.inputValues.forEach (objInputValue => {
                            inputValue.push ({
                                selected: keyDetailPairSelectedValue.includes (objInputValue),
                                value: objInputValue
                            })
                        })
                        inputValue=JSON.stringify (inputValue);
                    }
                    return {
                        name: obj.inputName,
                        type: inputType,
                        value: inputValue
                    }
                }
            );
            createRLCRequestBody.approvalPlan={
                approvers: approversList,
                type: isApproversGrouped.current ? "GROUP" : "CHAIN"
            }
            createRLCRequestBody.documentList=attachmentsAsync.current;
            createRLCApproval (createRLCRequestBody);
        }else{
            props.snackbarShowMessage (`${formSubmitError.current}`, "warning",3000)
        }
    }

    async function createRLCApproval(body){
        setShowLoadingIndicator(true);
        return await  ActivityServices.createRLCApproval(body).then((res)=>{
            setShowLoadingIndicator(false);
            if(res.data.response==='Failed'){
                props.snackbarShowMessage(`${constants.ERROR.CREATE_RLC_ERROR}: ${res.data.reason}`, "error",3000);
            }else {
                props.snackbarShowMessage (`${ constants.CRUD_SUCCESS_ALERTS.CREATED }`);
                setTimeout (props.onSuccess, 1500)
            }
        }).catch( (error) => {
            setShowLoadingIndicator(false);
            props.snackbarShowMessage(`${constants.ERROR.CREATE_RLC_ERROR}: ${error.data.Message}`, "error",3000);
        })
    }

    const checkLimitOfFileUpload = files =>{
        let limitExceeded = false;
        files.forEach(file =>{
            if(file.size===Constants.ATTACHMENTS.MAX_SIZE) setFileLimit(true);
            if(file.size > Constants.ATTACHMENTS.MAX_SIZE) {
                props.snackbarShowMessage(
                    `${constants.ATTACHMENTS.LIMIT_ERROR}`,
                    "warning"
                );
                setFileLimit(true);
                limitExceeded = true;
            }
        });
        return limitExceeded;
    }

    const fileUploadProgressValue = (file)=>{
        return progressInfos.find(obj => (obj.file && obj.file.name === file.name)) && progressInfos.find(obj => (obj.file && obj.file.name === file.name)).percentage;
    }

    const handleDeleteAttachment = async (file) => {

        let uploadFilesL = [...uploadedFiles];
        uploadFilesL = uploadFilesL.filter(obj => obj.name !== file.name);
        setUploadedFiles(uploadFilesL);
        if(attachmentsAsync.current){
            let inputFile = attachmentsAsync.current.find(obj => obj.attachmentName===file.name);
            if (inputFile) {
                await deleteAttachment(inputFile.attachmentFileUri);
            }
        }
    }

    const deleteAttachment = async (inputFileUri) => {
        let body = { s3FilePath :inputFileUri }
        setShowLoadingIndicator(true);
        await ActivityServices.deleteRlcAttachment(body).then(res => {
            setShowLoadingIndicator(false);
            attachmentsAsync.current= attachmentsAsync.current.filter (file => file.attachmentFileUri!==inputFileUri);
        }).catch((error) => {
            setShowLoadingIndicator(false);
            props.snackbarShowMessage(`${constants.ATTACHMENTS.DELETE_ATTACHMENT_ERROR}:${ error }`, "error");
        })
    }

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let _progressInfos = [...progressInfos];
        _progressInfos[0].uploadOperation = true;
        if (!checkLimitOfFileUpload(files)) {
            files.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    uploaded.push(file);
                    _progressInfos.push({ percentage: 0, file: file });
                }
                return false;
            })
            setUploadedFiles(uploaded)
            setProgressInfos(_progressInfos);
        }
    }

    const handleMultipleAttachments = (e) =>{
        const selectedFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(selectedFiles);
    }

    function CreateKeyDetailsDropdown(keyDetails){
        return (
            <FormControl sx={{ m: 1, width: 220 }} style={{margin:0,padding:0}}>
                { (keyDetails.inputType !== "Text" && keyDetails.showFeild) ? <InputLabel id="demo-multiple-name-label" style={{margin:0,padding:0}}>Select</InputLabel>:""}
                { (keyDetails.inputType ==="MultiSelect" && keyDetails.showFeild)  ?
                    <Select
                        labelId="key-details-pair-label"
                        id={keyDetails.id}
                        style={{margin:"3px",padding:"0px !important"}}
                        multiple
                        value={approvalKeyDetailsPair.find(obj => obj.id === keyDetails.id).value}
                        onChange={(event) => handleKeyDetailsPairChange(event,keyDetails)}
                        input={<OutlinedInput label="Name" />}
                    >
                        {keyDetails.inputValues.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select> : (keyDetails.inputType === "SingleSelect" && keyDetails.showFeild)  ?
                        <Select
                            labelId="key-details-pair-label"
                            id={keyDetails.id}
                            style={{margin:"3px",padding:"0px !important"}}
                            value={approvalKeyDetailsPair.find(obj => obj.id === keyDetails.id).value}
                            onChange={(event) => handleKeyDetailsPairChange(event,keyDetails)}
                            input={<OutlinedInput label="Name" />}
                        >
                            {keyDetails.inputValues.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select> :  keyDetails.showFeild &&
                        <TextField
                            id={keyDetails.id}
                            size="small"
                            label="Enter Value"
                            style={{margin:"3px",padding:"0px !important"}}
                            variant="outlined"
                            defaultValue={keyDetails.inputName==="Site Name"?props.site.join(', '):""}
                            onChange={(event) => handleKeyDetailsPairChange(event,keyDetails)}
                        />
                }
            </FormControl>);
    }

    return (
        <div className={showLoadingIndicator?"loading_indicator":""}>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }} style={{backgroundColor:"#007486"}}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Create Red Level Change Request
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <DialogContentText style={{fontWeight:"bold",color:"black"}}>
                        Basic Details
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} xl={6} key="rlcInput01" style={{marginTop:"0.2rem"}}>
                            <TextField
                                autoFocus
                                id="title"
                                label="Approval request title"
                                type="text"
                                fullWidth
                                InputLabelProps={{ required: true }}
                                onChange={(e) => approvalTitle.current = e.target.value}
                                variant="outlined"
                                defaultValue={`WFS_LO_Submission: India-<${props.site.join(', ')}>-<current finance week #>`}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} xl={3} key="rlcInput02" style={{marginTop:"0.2rem"}}>
                            <TextField
                                id="approvalNeedBy"
                                fullWidth
                                label="Approval Needed By"
                                type="date"
                                sytle={{borderColor:"#007486"}}
                                inputProps={{ min:formatDate(new Date(),+1) }}
                                InputLabelProps={{ shrink: true, required: true }}
                                variant="outlined"
                                onChange={(e) => approvalNeedByDate.current = e.target.value}
                                defaultValue={formatDate(new Date(),+1)}
                            />
                        </Grid>
                        <Grid item xs={2} sm={4} md={3} xl={3} key="rlcInput03" style={{marginTop:"0.2rem"}}>
                            <TextField
                                id="priority"
                                fullWidth
                                select
                                label="Priority"
                                type="Select"
                                variant="outlined"
                                defaultValue={constants.RLC_PRIORITY_LIST.LOW}
                                InputLabelProps={{ shrink: true, required: true }}
                                onChange={(e) => approvalPriority.current = e.target.value}
                            >
                                {approvalPriorityList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{marginTop:"1.3rem"}}>

                        <Grid item xs={12} sm={12} md={8} xl={8} key="rlcInput04" style={{marginTop:"0.2rem"}}>
                            <DialogContentText style={{fontWeight:"bold",color:"black"}}>
                                Approval request summary
                            </DialogContentText>
                            <StencilProvider>
                                <RichTextEditor
                                    id="test-date"
                                    defaultHeight="334px"
                                    maxHeight="600px"
                                    defaultContent="<span>Please enter the summary of the changes requested and the business justification for the changes here. Please add the Regional HRM, Regional Finance Manager and Regional WFS Manager in the approval chain</span>"
                                    onChange={editor => approvalSummary.current = editor.getText()}
                                    style={{zIndex:99999}}
                                />
                            </StencilProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} xl={4} key="rlcInput05" style={{marginTop:"0.2rem"}}>
                            <DialogContentText style={{fontWeight:"bold",color:"black"}}>
                                Key Details
                            </DialogContentText>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{margin:0,padding:"8px 16px"}}>Name</StyledTableCell>
                                            <StyledTableCell align="right" style={{margin:0,padding:"8px 16px"}}>Values</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {keyDetailsInputList.map((keyDetail) => (
                                            <StyledTableRow key={keyDetail.inputName}>
                                                <StyledTableCell component="th" scope="row" style={{margin:0,padding:"0px 8px"}}>
                                                    {keyDetail.inputName}{keyDetail.inputName==="Change in HC requirement"?"*":""}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{width:"250px",margin:0,padding:"0px 2px"}}>
                                                    {CreateKeyDetailsDropdown(keyDetail)}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>

                    <DialogContentText style={{fontWeight:"bold",color:"black",marginTop:"1.3rem"}}>
                        Approval Plan &nbsp; &nbsp;<small style={{fontWeight:"light",color:"#6e6e6e"}}>Group<Switch defaultChecked onChange={() => isApproversGrouped.current = !isApproversGrouped.current} />Chain</small>
                    </DialogContentText>

                    <Grid container spacing={2} style={{marginTop:"0.3rem"}}>
                        <Grid item xs={12} sm={12} md={4} xl={4} key="rlcInput05">
                            <AddWatchersOrApprovers watchersList={approversList} setWatchersList={setApproversList} isUpdateRequired={false} isMandatory={true} label={"Enter RLC Approvers alias"} />
                        </Grid>
                        {sitesLoading ?  <CircularProgress/> :
                        <Grid item xs={12} sm={12} md={4} xl={4} key="rlcInput06">
                            <AddWatchersOrApprovers watchersList={viewersList} setWatchersList={setViewersList} isUpdateRequired={false} isMandatory={false} label={"Enter RLC Viewers alias"} />
                        </Grid>}
                        {showAuthorizedUsersLoadingIcon ? <CircularProgress/> :
                            <Grid item xs={12} sm={12} md={4} xl={4} key="rlcInput07">
                            <AddWatchersOrApprovers watchersList={adminsList} setWatchersList={setAdminsList} isUpdateRequired={false} isMandatory={false} label={"Enter RLC Admin alias"} />
                        </Grid>}
                    </Grid>

                    <DialogContentText style={{fontWeight:"bold",color:"black",marginTop:"1.3rem"}}>
                        Attachments
                        <InfoHelpIcon FieldToolTip= {constants.COMMENTS.ATTACHMENT_TOOLTIP}
                                      IconClassName={<InfoOutlinedIcon />}
                                      Title="Attachments info"/>
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} xl={12} key="rlcInput05" style={{marginTop:"0.2rem"}}>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container justify="center" alignItems="center" draggable="true" style={{border:"2px dashed rgba(0, 0, 0, 0.5)"}}>
                                <p style={{fontSize:"1.2rem",textTransform: "capitalize",fontWeight:"bold"}}>Upload File(s) </p><span>&nbsp;or</span>
                                <Button variant="outlined" component="label" style={{display: "flex",margin:"10px",padding:"5px"}}><p style={{fontSize:"1.2rem",textTransform: "capitalize",color:"#007486",borderColor:"#007486"}}>Browse</p>
                                    <input hidden multiple type="file" onChange={handleMultipleAttachments} disabled={fileLimit}/>
                                </Button>
                            </Grid>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid style={{marginTop:"9px", margin: "16px"}}>
                                    {uploadedFiles.map((file,counter) => (
                                        <Grid id={`commentUploadedAttachment${counter}`} container key={`commentUploadedAttachment${counter}`} style={{boxShadow:"none", background:"#f2f2f2", margin:"5px 0 5px 5px", borderRadius:"4px"}} >
                                            <Grid item xl={3} lg={3} md={3} sm={3} xs={4} style={{padding:"3px",paddingLeft:"8px"}}>
                                                <Typography sx={{fontSize: 14, color: "#007486", marginRight: "5px"}} color="#inherit">{file.name}</Typography>
                                            </Grid>
                                            <Grid item xl={8} lg={8} md={8} sm={8} xs={8} style={{padding:"3px"}}>
                                                <LinearWithValueLabel value={fileUploadProgressValue(file)} />
                                            </Grid>
                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} justify="flex-end" style={{padding:"1px",paddingRight:"1px"}}>
                                                <Button variant="outlined" disabled={fileUploadProgressValue(file) && fileUploadProgressValue(file)!==100} style={{color:"#007486",marginLeft:"10px",padding:"1px 20px 1px 8px"}} onClick={()=>handleDeleteAttachment(file)} endIcon={<CloseIcon />}/>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} style={{color:"#007486",borderColor:"#007486"}}>Discard this request </Button>
                    <Button variant="contained" onClick={handleCreate} style={{backgroundColor:"#007486",borderColor:"#007486"}} disabled={isSubmitButtonDisable} endIcon={<SendIcon />}>Create Approval request </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default withAlertSnackBar(CreateRLCModal);