import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../ButtonSecondary/ButtonSecondary';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from "@material-ui/icons/Check";

function FormActionAlertDialog(props) {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleYes = () => {
        setOpen(false);
      props.handleYes();
    }

    const handleClose = () => {
      setOpen(false);
      props.handleNo();
    };

    return (
        <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert_dialog">{"Are you sure want to continue?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.Message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <ButtonSecondary variant="outlined" Title="Confirm" onClick={handleYes} color="danger" autoFocus  ButtonIcon={ <CheckIcon /> } />
            <ButtonPrimary variant="outlined" Title="Cancel" autoFocus onClick={handleClose} color="primary"  ButtonIcon={ <CloseIcon /> } />
          </DialogActions>
      </Dialog>
    </div>
    )
}

FormActionAlertDialog.propTypes = {
    
}

export default FormActionAlertDialog
