export const ALIAS_DENY_LIST = [
    'JobProcessor',
    'harshriv',
    'lakshant',
    'venkadap',
    'iampatel',
    'mohitmk',
    'vpgrover',
    'shreyv',
    'CandidateProcessor',
    'manigk',
    'shivkrmh',
    'msuhasb',
    'mohanvs',
    'shailach',
    'hvhqa',
    'hvhqaregression',
    'dheeraa',
    'VendorPortal'
]