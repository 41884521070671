import React, { memo } from 'react'
import { Route} from 'react-router'

const Private = ({ component: Component, acl, ...rest }) => {
  return (
    <Route {...rest} render={props => (
        <Component {...props} />
    )} />
  )
}

export default memo(Private)
