import React from "react";
import { Grid } from "@material-ui/core";
import FormPageSubHeading from "../../../components/FormPage/FormPageSubHeading";
import InputLabel from "../../../components/FormPage/InputLabel";
import Typography from '@material-ui/core/Typography';
import FormFieldView from "../../../components/FormPage/FormFieldView";
import constants from "../../../Utils/Constants"
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`related-tab-${index}`}
            aria-labelledby={`related-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function ViewOnboardingPhase(props) {

    return (
        <div>
            { props.length === 1 && <Grid item xs={12} className="text-left d-flex">
                <FormPageSubHeading Title={constants.FORM_DETAILS.PHASE} />
            </Grid> }
            { props.length !== 1 && <Grid item xs={12} className="text-left d-flex">
                <FormPageSubHeading Title={constants.FORM_DETAILS.PHASE + " - Phase " + props.Index} />
            </Grid> }
            <Grid container alignItems="top" style = {{marginTop: "15px", marginLeft: "125px"}}>
                
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container alignItems="center" className="form_group_item">
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <InputLabel Title="Male:" />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <FormFieldView Content={props.Phase.general} />
                            </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container alignItems="center" className="form_group_item">
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <InputLabel Title="Specially Abled:" />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <FormFieldView Content={props.Phase.peopleWithDisability} />
                            </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container alignItems="center" className="form_group_item">
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <InputLabel Title="Female:" />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <FormFieldView Content={props.Phase.women} />
                            </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container alignItems="center" className="form_group_item">
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <InputLabel Title="Transgender:" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormFieldView Content={props.Phase.transgender} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container alignItems="center" className="form_group_item">
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <InputLabel Title="Need by Date:" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormFieldView Content={props.Phase.startDate} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container alignItems="center" className="form_group_item">
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <InputLabel Title="Onboarding Shift:" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormFieldView Content={props.Phase.onboardingShift} />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>

    )
}

export default withAlertSnackBar(ViewOnboardingPhase);