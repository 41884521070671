import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: "20px",
        marginTop: "75px",
        borderRadius: "5em",
        textTransform: "none",
    },
}));

function ClearButton(props) {
    const classes = useStyles();
    useHistory();
    return (
        <Button
            onClick={props.onClick ? props.onClick : null}
            variant="outlined"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={props.ButtonIcon}
            tabIndex={props.tab_index}
        >
            {props.Title}
        </Button>
    );
}

export default ClearButton;
