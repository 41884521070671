import axios from 'axios'
import constants from "../Utils/Constants";

let counter = 0;

let orgBaseURL

let eventTaskBaseURL

let siteBaseURL

let careerPortalURL

let careerSiteURL

let orgAxios

let eventTaskAxios
let eventTaskAxiosWithoutCounter

let siteAxios

const init = async () => {

  orgBaseURL = sessionStorage.getItem('orgBaseURL');
  eventTaskBaseURL = sessionStorage.getItem('eventTaskBaseURL');
  siteBaseURL = sessionStorage.getItem('siteBaseURL');
  careerPortalURL = sessionStorage.getItem('careerPortalURL');
  careerSiteURL = sessionStorage.getItem('careerSiteURL');

  if(!(orgBaseURL && eventTaskBaseURL && siteBaseURL && careerPortalURL)){
    await axios.get('/settings.json')
      .then((res)=>{
        console.log("fetching environment specific URLs")
        orgBaseURL = res.data.vendorManagementProxy
        eventTaskBaseURL = res.data.lormProxy
        siteBaseURL = res.data.siteManagementProxy
        careerPortalURL = res.data.careerPortalHost
        careerSiteURL = res.data.careerSiteHost
      }).catch((err)=>{
        console.log("configuration file not found")
        orgBaseURL = process.env.REACT_APP_ORG_BASE_URL
        eventTaskBaseURL = process.env.REACT_APP_EVENT_TASK_BASE_URL
        siteBaseURL = process.env.REACT_APP_SITE_BASE_URL
        careerPortalURL = process.env.REACT_APP_CAREER_PORTAL_URL
        careerSiteURL = process.env.REACT_APP_CAREER_SITE_URL
      })

      sessionStorage.setItem('orgBaseURL',orgBaseURL);
      sessionStorage.setItem('eventTaskBaseURL',eventTaskBaseURL);
      sessionStorage.setItem('siteBaseURL',siteBaseURL);
      sessionStorage.setItem('careerPortalURL',careerPortalURL);
      sessionStorage.setItem('careerSiteURL', careerSiteURL)
  }
        
  setAxios();

}

const setAxios = () => {
  orgAxios = axios.create({
    withCredentials: true,
    baseURL: orgBaseURL,
    timeout: 180000
  })
  orgAxios.interceptors.request.use(handleSuccessRequest, handleErrorRequest)
  orgAxios.interceptors.response.use(handleSuccess, handleError)

  eventTaskAxios = axios.create({
    withCredentials: true,
    baseURL: eventTaskBaseURL,
    timeout: 180000
  })
  eventTaskAxios.interceptors.request.use(handleSuccessRequest, handleErrorRequest)
  eventTaskAxios.interceptors.response.use(handleSuccess, handleError)

  eventTaskAxiosWithoutCounter = axios.create({
    withCredentials: true,
    baseURL: eventTaskBaseURL,
    timeout: 180000
  })

  eventTaskAxiosWithoutCounter.interceptors.request.use(handleRequestWithoutCounter, handleErrorWithoutCounter)
  eventTaskAxiosWithoutCounter.interceptors.response.use(handleRequestWithoutCounter, handleErrorWithoutCounter)

  siteAxios = axios.create({
    withCredentials: true,
    baseURL: siteBaseURL,
    timeout: 180000
  })
  siteAxios.interceptors.request.use(handleSuccessRequest, handleErrorRequest)
  siteAxios.interceptors.response.use(handleSuccess, handleError)

}

const handleRequestWithoutCounter = (request) => {
  return request
}

const handleErrorWithoutCounter = (error) => {
  return Promise.reject(error)
}

const handleSuccessRequest = (request) => {
  if(constants.REACT_QUERY.LOADING_ICON_URLS.includes(request.url) || (request.url === '/requisitions' && !request.params))
  document.body.classList.add('loading_indicator')
  counter = counter + 1;
  return request
}

const handleErrorRequest = (error) => {
  document.body.classList.remove('loading_indicator')
  counter = counter + 1;
  return Promise.reject(error)
}

const handleSuccess = (response) => {
  counter = counter -1 ;
  if(counter ===0 ){
    document.body.classList.remove('loading_indicator')
  }
  return response
}

const handleError = (error) => {
  counter = counter -1 ;
  if(counter ===0 ){
    document.body.classList.remove('loading_indicator')
  }
  if (error.message === 'Network Error') {
    // The user doesn't have internet
    return Promise.reject(error.response)
  }
  try {
    switch (error.response.status) {
      case 400:
        document.body.classList.remove('loading_indicator')
        break
      case 401:
        //Un authorized   
        document.body.classList.remove('loading_indicator')
        break
      case 404:
        // Show 404 page
        break
      case 500:
        // Serveur Error redirect to 500
        break
      default:
        Promise.reject(error.response)
        break
    }
  } catch (e) {
  }
  return Promise.reject(error.response)
}

init()

export {
  orgAxios,
  eventTaskAxios,
  eventTaskAxiosWithoutCounter,
  eventTaskBaseURL,
  orgBaseURL,
  siteAxios,
  init
}
