import React, { useState, useEffect, useRef } from "react";
import {useHistory, useParams} from "react-router-dom";
import RequisitionsGrid from "./RequisitionsGrid";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import SearchIcon from "@material-ui/icons/Search";
import constants, {LR_DOWNLOAD_TEMPLATE_MAP} from "../../Utils/Constants";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import RequisitionsFilterDialog from "./RequisitionsFilterDialog";
import Demo from "../../components/DropdownMenu/customDropdownMenu";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ListIcon from '@material-ui/icons/List';
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import xlsx from "json-as-xlsx";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {disablePastDate} from "../../Utils/DateUtil";
import {SyncDisabled, CheckCircleSharp} from "@material-ui/icons";
import FormActionAlertDialog from "../../components/FormPage/FormActionAlertDialog";
import {ActivityServices} from "../../Services/activities";
import DeleteIcon from '@material-ui/icons/Delete';
import { useQueryClient} from 'react-query';
import {getUserCountry} from "../../Utils/Util";
import {downloadLOLRCountryFilter} from "../../Utils/RegionUtil";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 550,
    },
    input: {
        flex: 1,
        fontSize: "1.2rem",
        color: "#006170",
    },
    iconButton: {
        padding: 10,
        color: "#006170",
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function Requisitions(props) {
    const [paginationConfig, setPaginationConfig] = useState({pageNo: 1, pageSize: 25,externalFiltersApplied: false, externalFilters: {vendorId: '', jobRoles: [],countries: [],sites:[],resetAppliedFilter:false, status:[],startDate:disablePastDate(new Date(),-14), endDate:disablePastDate(new Date(), 91)}});
    const [searchConfig, setSearchConfig] = useState({});
    const [externalFiltersConfig, setExternalFiltersConfig] = React.useState({vendorId:'',status:[],sites:[], jobRoles:[], countries: []});
    const [showFormFilterDialog, setShowFormFilterDialog] = useState(false);
    const [isFiltersEnabled, setIsFiltersEnabled] = useState(true);
    const [requisitions,setRequisitions] = useState();
    const [selectedRequisitionsList, setSelectedRequisitionsList] = useState([]);
    const [showInactiveConfirmation,setShowInactiveConfirmation] = useState(false);
    const [showDeleteConfirmation,setShowDeleteConfirmation] = useState(false);
    const [showFulfillConfirmation,setShowFulfillConfirmation] = useState(false);
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
    const [processedRequisitions, setProcessedRequisitions] = useState([])

    const params = useParams();
    const queryClient = useQueryClient();
    let history = useHistory();
    const classes = useStyles();
    const gridRef = useRef();

    useEffect(() => {
        setSearchConfig('');
        setExternalFiltersConfig({vendorId: '', jobRoles: [],countries: [],status:["OPEN","ACCEPTED","WORK_IN_PROGRESS","DECLINED"], sites:[],startDate:disablePastDate(new Date(),-7), endDate:disablePastDate(new Date(), 21)})
    }, [])

    useEffect(() => {
        processSelectedRequisitions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isActionMenuOpen])

    const handleSearchValueChange = (e) => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = e.target.value;
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.searchValue = e.target.value;
        if(e.keyCode === 13){
            e.preventDefault();
        }
        else{
            setSearchConfig(searchConfigL);
            setPaginationConfig(paginationConfigL);
        }
    };

    const processSelectedRequisitions = () => {
        let processedREQs = []
        selectedRequisitionsList.forEach((requisition) =>{
            if(requisition.is_selected){
                processedREQs.push({
                    requisitionId: requisition.RequisitionId,
                    laborOrderId: requisition.LaborOrderId
                })
            }
        } )
        setProcessedRequisitions(processedREQs)
    }

    const applyFilters = (externalFilters) => {
        let externalFiltersConfigL = { ...externalFiltersConfig };
        externalFiltersConfigL = { ...externalFiltersConfigL, ...externalFilters };
        setExternalFiltersConfig(externalFiltersConfigL);
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.externalFilters = externalFilters;
        paginationConfigL.externalFiltersApplied = true;
        setPaginationConfig(paginationConfigL);
        setShowFormFilterDialog(false);
    };

    const updateFiltersFromResponse = (externalFilters) =>{
        let externalFiltersConfigL = { ...externalFiltersConfig };
        externalFiltersConfigL = { ...externalFiltersConfigL, ...externalFilters };
        externalFiltersConfigL.alias = "";
        setExternalFiltersConfig(externalFiltersConfigL);
    };

    const toggleFilters = (externalFilters) => {
        setShowFormFilterDialog(true);
    };

    const handleSearchClick = () => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchConfig.searchValue;
        setPaginationConfig(paginationConfigL);
    };

    const InactiveActivityConfirm  =  () =>{
        if(processedRequisitions.length > constants.REQUISITIONS.SUPPORTED_LIMIT.BULK_INACTIVE){
            props.snackbarShowMessage(constants.ERROR.BULK_UPDATE_LIMIT_PREFIX + constants.REQUISITIONS.SUPPORTED_LIMIT.BULK_INACTIVE + constants.ERROR.BULK_UPDATE_LR_LIMIT_SUFFIX, "error")
        }
        else{
            setShowInactiveConfirmation(true)
        }
    }

    const DeleteActivityConfirm  =  () =>{
        if(processedRequisitions.length > constants.REQUISITIONS.SUPPORTED_LIMIT.BULK_DELETE){
            props.snackbarShowMessage(constants.ERROR.BULK_UPDATE_LIMIT_PREFIX + constants.REQUISITIONS.SUPPORTED_LIMIT.BULK_DELETE + constants.ERROR.BULK_UPDATE_LR_LIMIT_SUFFIX, "error")
        }
        else{
            setShowDeleteConfirmation(true)
        }
    }

    const FulfillActivityConfirm  =  () =>{
        if(processedRequisitions.length > constants.REQUISITIONS.SUPPORTED_LIMIT.BULK_FULFILLED){
            props.snackbarShowMessage(constants.ERROR.BULK_UPDATE_LIMIT_PREFIX + constants.REQUISITIONS.SUPPORTED_LIMIT.BULK_FULFILLED + constants.ERROR.BULK_UPDATE_LR_LIMIT_SUFFIX, "error")
        }
        else{
            setShowFulfillConfirmation(true)
        }
    }

    const BulkUpdateActivity =async (status) => {
        setShowFulfillConfirmation(false)
        setShowDeleteConfirmation(false)
        setShowInactiveConfirmation(false)
        setIsActionMenuOpen(false)
        let body ={
            status:status,
            requisitions: processedRequisitions,
            vendorRequest: false
        }
        await ActivityServices.bulkUpdateRequisitions(body).then((resp)=>{
            const schema = constants.LR_BULK_UPDATE_SCHEMA
            let xlsxData = [
                {
                    sheet: `Response`,
                    columns: schema,
                    content: resp.data.requisitions
                }
            ]

            let settings = {
                fileName: `BulkUpdateRequisitionResponse`,
                extraLength: 3,
                writeOptions: {}
            }
            xlsx(xlsxData, settings)
            props.snackbarShowMessage(constants.CRUD_SUCCESS_ALERTS.PROCESSED)
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)

        }).catch((error) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)

            props.snackbarShowMessage(
                `${constants.ERROR.UPDATE_ERROR}`,
                "error"
            );
        })
    }

    const CreateRequisition = () => {
        history.push(`${constants.REQUISITIONS.ROUTE.CREATE}`);
    };
    const ShowAllRequisitions = () => {
        history.push(`../../${constants.REQUISITIONS.ROUTE.MAIN}`);
        window.location.reload(false);
    };

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            handleSearchClick();
        }
    }

    const DownloadRequisitions = async () => {
        const schema=LR_DOWNLOAD_TEMPLATE_MAP[getUserCountry()];
        const requisitionsByCountry = downloadLOLRCountryFilter(requisitions);
        let xlsxData=[
            {
                sheet: `RequisitionDetails`,
                columns: schema,
                content: requisitionsByCountry
            }]

        let settings={
            fileName: `RequisitionWorkbook`,
            extraLength: 3,
            writeOptions: {width: 10},
        }

        await xlsx (xlsxData, settings).wi
        props.snackbarShowMessage (`File successfully downloaded`);
    }

    const requisitionActions = [
        {
            id: "1",
            Title:`${constants.BUTTON.CREATE_REQUISITION}`,
            Icon: AddBoxIcon,
            onClick: CreateRequisition
        },
        {
            id: "2",
            Title: `Show All ${constants.REQUISITIONS.TITLE}`,
            Icon: ListIcon,
            onClick: ShowAllRequisitions
        },
        {
            id: "3",
            Title: `${constants.BUTTON.DOWNLOAD_REQUISITIONS}`,
            Icon: ArrowDownward,
            onClick: DownloadRequisitions
        },
        {
            id: "4",
            Title: constants.BUTTON.INACTIVE,
            Icon:SyncDisabled,
            onClick: InactiveActivityConfirm,
            disabled: processedRequisitions.length ===0
        },
        {
            id: "5",
            Title: constants.BUTTON.DELETE,
            Icon:DeleteIcon,
            onClick: DeleteActivityConfirm,
            disabled: processedRequisitions.length ===0
        },
        {
            id: "6",
            Title: constants.BUTTON.MARK_FULFILLED,
            Icon:CheckCircleSharp,
            onClick: FulfillActivityConfirm,
            disabled: processedRequisitions.length ===0
        }
    ]

    let validFiltersCount = Object.values(externalFiltersConfig).filter(
        (filterItem) => (filterItem !== "" && filterItem.length >0)
    ).length-1;

    return (
        <div className="amz_content_view">
            <Container id="amz_requisitionJsx" style={{maxWidth:"100%",margin:"0"}}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="mb-15">

                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} >
                        {validFiltersCount > 0 && (
                            <ButtonPrimary
                                isDisabled = {!isFiltersEnabled}
                                Title={`${constants.BUTTON.FILTER} (${
                                    validFiltersCount > 0 ? validFiltersCount : ""
                                })`}
                                onClick={toggleFilters}
                                ButtonIcon={<FilterListRoundedIcon />}
                            />
                        )}
                        {!validFiltersCount > 0 && (
                            <ButtonSecondary
                                isDisabled = {!isFiltersEnabled}
                                Title={`${constants.BUTTON.FILTER} ${
                                    validFiltersCount > 0 ? validFiltersCount : ""
                                }`}
                                onClick={toggleFilters}
                                ButtonIcon={<FilterListRoundedIcon />}
                            />
                        )}
                    </Grid>

                    <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Paper item xs={12} sm={12} md={12} lg={12} xl={12} component="form" className={classes.paper}>
                            <SearchIcon className={classes.iconButton} />
                            <InputBase
                                className={classes.input}
                                placeholder={constants.REQUISITIONS.SEARCH_PLACEHOLDER}
                                inputProps={constants.REQUISITIONS.SEARCH_PLACEHOLDER}
                                value={searchConfig.searchValue ? searchConfig.searchValue : ""}
                                onChange={handleSearchValueChange}
                                onKeyPress={handleKeyPress}
                            />
                            <Divider className={classes.divider} orientation="vertical" />
                            <ButtonSecondary Title="Search" onClick={handleSearchClick} />
                        </Paper>
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <div className="form_page_actions">
                                <Demo isActionMenuOpen = {isActionMenuOpen} setIsActionMenuOpen={setIsActionMenuOpen} option={requisitionActions}/>
                            </div>
                    </Grid>

                </Grid>

                <div className="d-flex">
                    <RequisitionsGrid
                        View="page"
                        config={paginationConfig}
                        ref={gridRef}
                        LOid={params.LOfilter}
                        downloadRequisitionHandler={setRequisitions}
                        enableFilters = {setIsFiltersEnabled}
                        updateFilter={updateFiltersFromResponse}
                        setSelectedRequisitionsList = {setSelectedRequisitionsList}
                    />
                </div>
                
            </Container>
            {
                showInactiveConfirmation &&
                <FormActionAlertDialog Message="Please confirm to mark the selected Requisitions Inactive" handleYes={()=>BulkUpdateActivity(constants.STATUS.INACTIVE)}  handleNo={() => { setShowInactiveConfirmation(false);setIsActionMenuOpen(false) }} />
            }
            {
                showDeleteConfirmation &&
                <FormActionAlertDialog Message="Please confirm to Delete the selected Requisitions" handleYes={()=>BulkUpdateActivity(constants.STATUS.DELETED)}  handleNo={() => { setShowDeleteConfirmation(false);setIsActionMenuOpen(false) }} />
            }
            {
                showFulfillConfirmation &&
                <FormActionAlertDialog Message="Please confirm to mark the selected Requisitions Fulfilled" handleYes={()=>BulkUpdateActivity(constants.STATUS.FULFILLED)}  handleNo={() => { setShowFulfillConfirmation(false);setIsActionMenuOpen(false) }} />
            }
            {showFormFilterDialog && (
                <RequisitionsFilterDialog
                    filterData={externalFiltersConfig}
                    handleApply={applyFilters}
                    handleCancel={() => {
                        setShowFormFilterDialog(false);
                    }}
                />
            )}
        </div>
    );
}

export default withAlertSnackBar(Requisitions);
