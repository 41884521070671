import React, {useState, useEffect} from 'react'
import Title from '../Title/Title'
import UserAvatar from '../UserAvatar/UserAvatar'
import UserName from '../UserName/UserName'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { UserServices} from "../../Services/users"
import {ActivityServices} from "../../Services/activities";
import HomeIcon from '@material-ui/icons/Home';
import axios from "axios";
import {isUserMultiRegion} from "../../Utils/RegionUtil";
import UnauthorizedAccessDialog from "../../pages/PageNotFound/UnauthorizedAccessDialog";
import {AuthorizationType} from "../../Utils/Constants";



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      color: '#ffffff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#ffffff',
    },
    title: {
      flexGrow: 1,
      textAlign: 'center',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      logoutButton:{
        color: '#ffffff',
        textTransform: 'Capitalize',
      }
  }));

const Header = () => {
    const classes = useStyles();
    const [userdetails, setUserDetails] = useState({});
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = useState(false);


    useEffect( () => {
        async function fetchData() {
            let sessionUser = sessionStorage.getItem('session_user');
            if (sessionUser) {
                setUserDetails(JSON.parse(sessionUser));
                console.log("Fetching Session User");
            }else{
                await UserServices.getUser().then((data)=>{
                    const userObject = {id:data["data"]["login"],name: data["data"]["login"]};
                    sessionStorage.setItem("session_user",JSON.stringify(userObject));
                    setUserDetails(userObject);
                    console.log("Setting Session User");
                })
            }
            sessionUser = sessionStorage.getItem('session_user');
            await ActivityServices.getAuthorizedCountriesForUser(JSON.parse(sessionUser)["id"]).then((resp)=>{
                sessionStorage.setItem("authorizedCountries",resp["data"]["authorizedCountries"] )
            })
            if (isUserMultiRegion()) {setShowUnauthorizedDialog(true)}
        }
        fetchData();

    }, []);

    const getHomeBaseUrl = async () => {
        let homeBaseUrl
        await axios.get(`${window.location.origin}/settings.json`)
            .then((res)=>{
                console.log("fetching environment specific URL")
                homeBaseUrl = res.data.hiringPortalHome
            }).catch((err)=>{
                console.log("configuration file not found")
                homeBaseUrl =  process.env.REACT_APP_HIRING_PORTAL_BASE_URL;
            })
        return homeBaseUrl
    }

    const homePageRedirect = async () => {
        window.location.href = await getHomeBaseUrl((data)=> data);
    }

    return (
        <div>
            <AppBar position="static" color="secondary" className={classes.root}>
                <Toolbar>
                  <HomeIcon style={{transform: 'scale(1.8)', cursor: 'pointer'}} onClick={homePageRedirect}/> 
                    <Typography variant="h6" className={classes.title}>
                        <Title />
                    </Typography>
                    
                    <UserAvatar username={userdetails.id?userdetails.name:''}/> 
                    <Typography variant="h6" >
                    <UserName username={userdetails.id?userdetails.name:''} /> 
                    
                    </Typography>
                </Toolbar>
            </AppBar>
            {showUnauthorizedDialog && (
                <UnauthorizedAccessDialog
                    handleCancel={() => {
                        setShowUnauthorizedDialog(false);
                    }}
                    authorizationType={AuthorizationType.MULTI_REGION}
                />
            )}
        </div>
    )
}

export default Header
