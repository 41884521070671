import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    textTransform: "none",
  },
}));

function ButtonPrimary(props) {
  const classes = useStyles();
  useHistory();
  return (
    <Button
      disabled={props.isDisabled}
      onClick={props.onClick ? props.onClick : null}
      variant="contained"
      color="primary"
      size="large"
      className={classes.button}
      startIcon={props.ButtonIcon}
      tabIndex={props.tab_index}
    >
      {props.Title}
    </Button>
  );
}

export default ButtonPrimary;
