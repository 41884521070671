import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {OrganizationServices} from "../../Services/organizations";


export const useGetAllParentVendors= () =>{
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_PARENT_VENDORS],()=> OrganizationServices.getAllParentVendors(),{
        refetchOnWindowFocus:false,
        refetchOnMount:false
    });
}