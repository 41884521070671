import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import React from "react";
import {Alert} from "@mui/material";
import {AuthorizationType} from "../../Utils/Constants";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    a :{
        color: "white",
        textDecoration : "None"
    },

}));

function BrassAuthorizationMessage() {
    return (
        <Alert variant="filled" severity="error">
            You are not authorized to access data. Please request India LORM access to the following team <a href="https://permissions.amazon.com/a/team/WFS-INDIA-LORM">https://permissions.amazon.com/a/team/WFS-INDIA-LORM</a> or MENA LORM access to the following team <a href="https://permissions.amazon.com/a/team/WFS_Facing_Services_permissions_MENA">https://permissions.amazon.com/a/team/WFS_Facing_Services_permissions_MENA</a>
        </Alert>
    );
}

function SiteAuthorizationMessage() {
    return (
        <Alert variant="filled" severity="error">
            You are not authorized to access data. Please drop a note to <a href="mailto:inwfs-swayam-admins@amazon.com">inwfs-swayam-admins@amazon.com</a> or <a href="mailto:menawfs-hba-admins@amazon.com">menawfs-hba-admins@amazon.com</a> to request access to India or MENA sites respectively.
        </Alert>
    );
}

function MultiRegionAccessMessage() {
    return (
        <Alert variant="filled" severity="error">
            You have access to both India and MENA region sites which is not allowed at the moment. Please reach out to <a href="mailto:menawfs-hba-admins@amazon.com">menawfs-hba-admins@amazon.com</a> to correct this.
        </Alert>
    );
}

function getAlertMessage(authType) {
  let MESSAGE_MAP = new Map();
    MESSAGE_MAP.set(AuthorizationType.BRASS, <BrassAuthorizationMessage/> );
    MESSAGE_MAP.set(AuthorizationType.MULTI_REGION, <MultiRegionAccessMessage/> );
    MESSAGE_MAP.set(AuthorizationType.SITE_BASED, <SiteAuthorizationMessage/>);

  return MESSAGE_MAP.get(authType)
}

function UnauthorizedAccessDialog(props) {
    const open = true;
    const classes = useStyles();
    return (
        <div style={{minWidth: '350px'}}>
            <Dialog
                fullScreen={false}
                open={open}>

                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">Unauthorized Access</Typography>
                </MuiDialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={12} direction="column" justify="space-around">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={12}>
                                {
                                    getAlertMessage(props.authorizationType)
                                }
                            </Grid>

                        </Grid>

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

UnauthorizedAccessDialog.propTypes = {

}

export default UnauthorizedAccessDialog