import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    head: {
        backgroundColor: 'rgba(40,39,36,0.13)',
        color: 'rgba(1,7,30,0.75)',
    },
    body: {
        fontSize: 14,
    },
    root: {
        width: '98%',
    },
    span: {
        fontSize:10,
    },
    paper: {
        padding: 1,
        textAlign: 'center',
        color: '#456745',
    }
});

export default function CandidateDataTable(props) {

    const classes = useStyles();

    const getHRISColor = (columnId,rowData) => {
        if(columnId === "rehireFlag"){
            switch(rowData.rehireFlag){
                case "N" : return {color:"RED"};
                case "Y" : return {color:"GREEN"};
                default : return {};
            }
        }

        if(rowData.hiringStage === "Onboarding - Complete"){
            if(rowData.createdBy === "SYSTEM_ADMIN"){
                return {color:"#ff6600"};
            }
            return {color:"GREY"};
        }

        if(rowData.onBoardedRequisition !== " - "){
            return {color:"#000099"};
            
        }

        switch(rowData.rehireFlag){
            case "N" : return {color:"GREY"};
            default : return {};
        }
    }


    return (
        <Paper className={classes.root}>
            <TableContainer className='amz_data_table'>
                <Table aria-label="sticky table" className={'candidate-data-table'}>
                    <TableHead style={{backgroundColor: '#F1F4F7',color:'black', position: 'sticky' }}>
                        <TableRow>
                            {props.columnData.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth}}
                                    onClick={() => { column.sortable && props.handleSort(column.id) }}
                                    sortDirection={((props.sortColumn === column.id) ? props.sortOrder : false)}
                                    className={`${((props.sortColumn === column.id) ? props.sortOrder : (column.sortable ? 'default' : null))} ${column.sortable ? 'sortable' : null} ${column.headerClass ? column.headerClass : ''}`}
                                >
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rowData.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={`${row.name}_${Math.random()}`}>
                                    {props.columnData.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={`${column.id}_${row.name}_${Math.random()}`} align={column.align} style={ getHRISColor(column.id,row)}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        {
                            props.rowData.length === 0 && <TableRow>
                                <TableCell colSpan={props.columnData.length} style={{ textAlign: 'center' }}>
                                    <p>{props.noDataFound?props.noDataFound : 'No Records Found'}</p>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10,25,50,75,100]}
                component="div"
                count={props.count}
                rowsPerPage={props.pageSize}
                page={props.pageNo - 1}
                options={{
                    paging: false,
                    headerStyle: {
                        backgroundColor: "#378FC3",
                        color: "#FFF",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold"
                    },
                }}
                onChangePage={(e, page) => {
                    props.handlePage(page)
                }}
                onChangeRowsPerPage={(e) => props.handlePageSize(e.target.value)}
            />
        </Paper>
    );
}
