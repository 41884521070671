import {
    Container,
    Grid,
} from "@material-ui/core";
import InputLabel from "../../components/FormPage/InputLabel";
import Formfieldstar from "../../components/FormPage/FormFieldStar";
import InfoHelpIcon from "../../components/FormPage/InfoHelpIcon";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormInputDatePicker from "../../components/FormPage/FormInputDatePicker";
import {Formvalidationerror} from "../../components/FormPage/FormValidationError";
import React, {useEffect, useState} from "react";
import constants, {AuthorizationType, BUSINESS_LINE_MAP} from "../../Utils/Constants";
import MultiAutoComplete from "../../components/FormPage/MultiAutoComplete";
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DownloadButton from "../../components/DownloadButton/DownloadButton";
import ClearIcon from '@material-ui/icons/Clear';
import ClearButton from "../../components/ClearButton/ClearButton";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {ReportServices} from "../../Services/reports";
import {disablePastDate} from "../../Utils/DateUtil";
import SelectSingle from "../../components/FormPage/SelectSingle";
import {useGetAllSites} from "../../ReactQuery/hooks/useGetAllSites";
import {useGetAuthorizedSites} from "../../ReactQuery/hooks/useGetAuthorizedSites";
import {useGetAllRequisitionsWithoutFilers} from "../../ReactQuery/hooks/useGetAllRequisitionsWithoutFilers";
import {useGetAllLaborOrdersWithoutFilters} from "../../ReactQuery/hooks/useGetAllLaborOrdersWithoutFilters";
import UnauthorizedAccessDialog from "../PageNotFound/UnauthorizedAccessDialog";
import {getUserCountry} from "../../Utils/Util";

function CandidateDataReports(props) {
    const [formData, setFormData] = useState({latestNeedByDateFrom: disablePastDate(new Date(), -7), latestNeedByDateTo: disablePastDate(new Date(), 21)});
    const [businessLines, setBusinessLines] = useState( JSON.parse(JSON.stringify(BUSINESS_LINE_MAP[getUserCountry()])));
    const [sites, setSites] = useState([]);
    const [globalSites, setGlobalSites] = useState([]);
    const [laborOrderIds, setLaborOrderIds] = useState([]);
    const [globalLaborOrderIds, setGlobalLaborOrderIds] = useState([]);
    const [requisitions, setRequisitions] = useState([]);
    const [globalRequisitions, setGlobalRequisitions] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [requisitionRequest, setRequisitionRequest] = useState(null);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");

    makeStyles((theme) => ({
        button: {
            marginLeft: "200px",
            borderRadius: "5em",
            textTransform: "none",
        },
    }));

    const {data: querySites, isFetching: sitesLoading} = useGetAllSites()
    const {data: queryAuthorizedSites, isFetching: authorizedSitesLoading} = useGetAuthorizedSites()
    const {data: queryRequisitions, error: errorGetAllRequisitions, isFetching: requisitionsLoading} = useGetAllRequisitionsWithoutFilers()
    const {data: queryLaborOrders, isFetching: laborOrdersLoading} = useGetAllLaborOrdersWithoutFilters(true)

    useEffect( () => {
        function fetchData() {
            fetchLaborOrders()
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryLaborOrders]);

    useEffect(() => {
        fetchRequisitions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[requisitionRequest, queryRequisitions, errorGetAllRequisitions])

    useEffect(() => {
        if(querySites && queryAuthorizedSites && queryAuthorizedSites.data){
            let querySitesList = (querySites && querySites.data) || [];
            let authorizedSites = (queryAuthorizedSites && queryAuthorizedSites.data && queryAuthorizedSites.data.authorizedSites) || []
            let sitesL = querySitesList.map((m) => ({
                id: m.siteId,
                is_selected: false,
                name: m.siteId ? m.siteId : "",
                businessLine: m.businessLine ? m.businessLine : "",
                wfsTeam: m.agencyName ? m.agencyName : "",
            }));
            if(! authorizedSites.includes("ADMIN")) {
                sitesL = sitesL.filter((site) => authorizedSites.some((authSite) => (authSite === site.id)))
            }
            setSites(sitesL);
            setGlobalSites(sitesL)
        }
    },[querySites, queryAuthorizedSites])

    const fetchLaborOrders =  () => {
            let data = (queryLaborOrders && queryLaborOrders.data && queryLaborOrders.data.laborOrders) || [];
            data = data.filter(item => item.laborOrderStatus !== "DELETED");
            let laborOrdersL = data.map((laborOrder) => ({
                id: laborOrder.laborOrderId,
                is_selected: false,
                name: laborOrder.laborOrderId ? laborOrder.laborOrderId : "",
                site: laborOrder.site ? laborOrder.site : "",
                businessLine: laborOrder.businessLine ? laborOrder.businessLine : "",
                needByDate: laborOrder.startDate ? laborOrder.startDate : "",
            }));
            setLaborOrderIds(laborOrdersL);
            setGlobalLaborOrderIds(laborOrdersL);
            if(laborOrdersL.length > 0)
                setRequisitionRequest(laborOrdersL)
            // fetchRequisitions(laborOrdersL);
    }

    const fetchRequisitions =  (laborOrderIdsL) => {
            let data = (queryRequisitions && queryRequisitions.data && queryRequisitions.data.requisitionInfos) || [];
            if(requisitionRequest && queryRequisitions && queryRequisitions.data){
                data = data.filter(item => item.requisitionStatus !== "DELETED");
                let requisitionsL = data.map((requisition) => ({
                    id: requisition.requisitionId,
                    is_selected: false,
                    name: requisition.requisitionId ? requisition.requisitionId : "",
                    businessLine: requisition.businessLine ? requisition.businessLine : "",
                    site: requisition.site ? requisition.site : "",
                    laborOrder: requisition.laborOrderId ? requisition.laborOrderId : "",
                    needByDate: requisition.laborOrderId && requisitionRequest.filter(laborOrder => laborOrder.id === requisition.laborOrderId).length > 0 ?
                        requisitionRequest.filter(laborOrder => laborOrder.id === requisition.laborOrderId)[0].needByDate : ""
                }));

                setRequisitions(requisitionsL);
                setGlobalRequisitions(requisitionsL);
            }
            if (errorGetAllRequisitions && errorGetAllRequisitions.data && (errorGetAllRequisitions.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS);
            }

    }

    const handleDate = (event) => {
        setIsFormSubmitted(false);
        let formDataL = { ...formData };
        formDataL[event.target.name] = event.target.value;
        setFormData(formDataL);
        if(!formDataL.latestNeedByDateFrom || !formDataL.latestNeedByDateTo) {
            resetList(sites);
            resetList(laborOrderIds);
            resetList(requisitions);
        }
        else {
            event.target.name === "latestNeedByDateFrom" ? filterLaborOrders(event.target.value, formDataL.latestNeedByDateTo, businessLines, sites)
                : filterLaborOrders(formDataL.latestNeedByDateFrom, event.target.value, businessLines, sites)
            event.target.name === "latestNeedByDateFrom" ? filterRequisitions(event.target.value, formDataL.latestNeedByDateTo, businessLines, sites, requisitions)
                : filterRequisitions(formDataL.latestNeedByDateFrom, event.target.value, businessLines, sites, requisitions)
        }
    }


    const businessLinesChangeHandler = (event) => {
        setIsFormSubmitted(false);
        let businessLinesL = [...businessLines];
        let formDataL = { ...formData };
        let value = event.target.value;
        businessLinesL.forEach((businessLine) => {
            businessLine.is_selected = value && businessLine.id === value;
        });
        setBusinessLines(businessLinesL);
        let sitesL = [...globalSites];

        resetListOnBusinessLineChange(value, sitesL);
        resetListOnBusinessLineChange(value, [...globalLaborOrderIds]);
        resetListOnBusinessLineChange(value, [...globalRequisitions]);

        if(value) {
            sitesL = sitesL.filter(item => value === item.businessLine);
        }
        setSites(sitesL);
        filterLaborOrders(formDataL.latestNeedByDateFrom, formDataL.latestNeedByDateTo, businessLinesL, sitesL);
        filterRequisitions(formDataL.latestNeedByDateFrom, formDataL.latestNeedByDateTo,businessLinesL, sitesL, laborOrderIds);
    };

    const sitesChangeHandler = (event, value) => {
        setIsFormSubmitted(false);
        let sitesL = [...sites];
        let formDataL = { ...formData };
        let selectedIds = value && value.length > 0 ? value.map((mapItem) => mapItem.name) : [];
        sitesL.forEach((site) => {
            site.is_selected = selectedIds && selectedIds.length > 0 && selectedIds.includes(site.name);
        });
        setSites(sitesL);
        resetListOnSiteChange(selectedIds, [...globalLaborOrderIds])
        resetListOnSiteChange(selectedIds, [...globalRequisitions])
        filterLaborOrders(formDataL.latestNeedByDateFrom, formDataL.latestNeedByDateTo, businessLines, sitesL);
        filterRequisitions(formDataL.latestNeedByDateFrom, formDataL.latestNeedByDateTo,businessLines, sitesL, laborOrderIds);
    };

    const laborOrderIdsChangeHandler = (event, value) => {
        setIsFormSubmitted(false);
        let laborOrderIdsL = [...laborOrderIds];
        let formDataL = {...formData};
        let selectedIds = value && value.length > 0 ? value.map((mapItem) => mapItem.name) : [];
        laborOrderIdsL.forEach((laborOrder) => {
            laborOrder.is_selected = selectedIds && selectedIds.length > 0 && selectedIds.includes(laborOrder.name);
        });
        setLaborOrderIds(laborOrderIdsL);
        resetListOnLaborOrderChange(selectedIds, [...globalRequisitions])
        filterRequisitions(formDataL.latestNeedByDateFrom, formDataL.latestNeedByDateTo,businessLines, sites, laborOrderIdsL);
    }

    const requisitionsChangeHandler = (event, value) => {
        setIsFormSubmitted(false);
        let requisitionsL = [...requisitions];
        let selectedIds = value && value.length > 0 ? value.map((mapItem) => mapItem.name) : [];
        requisitionsL.forEach((requisition) => {
            requisition.is_selected = selectedIds && selectedIds.length > 0 && selectedIds.includes(requisition.name);
        });
        setRequisitions(requisitionsL);
    }

    const filterLaborOrders = (needByDateFrom, needByDateTo, businessLinesL, sitesL) => {
        let laborOrdersL = [...globalLaborOrderIds];
        businessLinesL = businessLinesL.filter(businessLine => businessLine.is_selected).map(businessLineObject => businessLineObject.name)
        sitesL = (sitesL.filter(site => site.is_selected)).map(siteObject => siteObject.name);
        laborOrdersL = laborOrdersL.filter(laborOrder => (needByDateFrom ? laborOrder.needByDate >= needByDateFrom : true) &&
            (needByDateTo ? laborOrder.needByDate <= needByDateTo : true) &&
            (businessLinesL.length > 0 ? businessLinesL.includes(laborOrder.businessLine) : true) &&
            (sitesL.length > 0 ? sitesL.includes(laborOrder.site) : true)
        );
        setLaborOrderIds(laborOrdersL);
    }

    const filterRequisitions = (needByDateFrom, needByDateTo, businessLinesL, sitesL, laborOrdersL) => {
        let requisitionsL = [...globalRequisitions];
        businessLinesL = businessLinesL.filter(businessLine => businessLine.is_selected).map(businessLineObject => businessLineObject.name)
        sitesL = (sitesL.filter(site => site.is_selected)).map(siteObject => siteObject.name);
        laborOrdersL = (laborOrdersL.filter(laborOrder => laborOrder.is_selected)).map(laborOrderObject => laborOrderObject.name);
        requisitionsL = requisitionsL.filter(requisition => (needByDateFrom ? requisition.needByDate >= needByDateFrom : true) &&
            (needByDateTo ? requisition.needByDate <= needByDateTo : true) &&
            (businessLinesL.length > 0 ? businessLinesL.includes(requisition.businessLine) : true) &&
            (sitesL.length > 0 ? sitesL.includes(requisition.site) : true) &&
            (laborOrdersL.length > 0 ? laborOrdersL.includes(requisition.laborOrder) : true)
        );
        setRequisitions(requisitionsL);
    }
    const resetListOnBusinessLineChange = (value, list) => {
        list.forEach((item) => {
            if(item.businessLine !== value) {
                item.is_selected = false
            }
        })
    }
    const resetListOnSiteChange = (selectedItems, list) => {
        list.forEach((item) => {
            if(selectedItems.length > 0 && !selectedItems.includes(item.site)) {
                item.is_selected = false
            }
        })
    }

    const resetListOnLaborOrderChange = (selectedItems, list) => {
        list.forEach((item) => {
            if(selectedItems.length > 0 && !selectedItems.includes(item.laborOrder)) {
                item.is_selected = false
            }
        })
    }

    const validateForm = () => {
        let isFormValid = true;
        if (
            !formData.latestNeedByDateFrom  ||
            !formData.latestNeedByDateTo ||
            !businessLines.find((businessLine) => businessLine.is_selected)
        )
            isFormValid = false;

        return isFormValid;
    };

    const downloadCandidateData = () => {
        setIsFormSubmitted(true);
        if(validateForm()) {
            let selectedRequisitions = requisitions.filter((filterItem) => filterItem.is_selected).map((mapItem) => mapItem)
            let requestBody = {
                requisitions :  selectedRequisitions.length > 0 ? selectedRequisitions : requisitions,
                country : getUserCountry()
            }
            ReportServices.getCandidateDataByFilters(requestBody).then(data => {
                props.snackbarShowMessage(`File successfully downloaded`);
                let link=document.createElement('a');
                link.download = data.config.data.replace(/[{}"]+/g, "").split(":")[1];
                link.href=data.data.candidateByFiltersS3Url;
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    document.body.removeChild(link);
                }, 200);
            }).catch(error => {
                props.snackbarShowMessage(`${error.data.message}` ,"error");
            })
            setIsFormSubmitted(false);
        }
    }

    const clearData = () => {
        setIsFormSubmitted(false);
        let formDataL = {...formData};
        formDataL.latestNeedByDateFrom = "";
        formDataL.latestNeedByDateTo = "";
        setFormData(formDataL);
        let sitesL = [...globalSites];
        let laborOrdersL = [...globalLaborOrderIds];
        let requisitionsL = [...globalRequisitions];
        resetList(businessLines);
        resetList(sitesL);
        resetList(laborOrdersL);
        resetList(requisitionsL);
        setSites(sitesL);
        setLaborOrderIds(laborOrdersL);
        setRequisitions(requisitionsL);
    }

    const resetList = (list) => {
        list.forEach(item => {
            item.is_selected = false
        })
        return list;
    }

        return (
        <div className="amz_content_view mb-100">
            <Container maxWidth="lg">
                <Grid container style = {{marginTop: "100px"}}>
                    <Grid container alignItems="top" className="form_group_list">
                        <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <InputLabel Title="Need By Date From" />
                                    <Formfieldstar />
                                    <InfoHelpIcon
                                        FieldToolTip="This filter is on the Need-by-Date of labor orders"
                                        IconClassName={<InfoOutlinedIcon />}
                                        Title="Latest Need-by-Date"
                                    />
                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <FormInputDatePicker
                                        error
                                        style={{ maxWidth: "175px", marginRight: "35px" }}
                                        name="latestNeedByDateFrom"
                                        value={formData?.latestNeedByDateFrom}
                                        onChange={handleDate}
                                        tabIndex="0"
                                        minDate = {disablePastDate(new Date(), -7)}
                                        maxDate = {formData?.latestNeedByDateTo}
                                    />
                                    {isFormSubmitted && !formData.latestNeedByDateFrom && (
                                        <Formvalidationerror fieldName = "Need-By-Date From" />
                                    )}
                                    <InputLabel Title="To" />
                                </Grid>
                                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                                        <FormInputDatePicker
                                            error
                                            name="latestNeedByDateTo"
                                            value={formData?.latestNeedByDateTo}
                                            onChange={handleDate}
                                            tabIndex="0"
                                            minDate = {formData?.latestNeedByDateFrom}
                                            maxDate = {disablePastDate(new Date(), 21)}
                                        />
                                        {isFormSubmitted && !formData.latestNeedByDateTo && (
                                            <Formvalidationerror fieldName = "Need-By-Date To" />
                                        )}
                                    </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <InputLabel Title="Business line:" />
                                    <Formfieldstar />
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <SelectSingle
                                        error
                                        id="Business Lines"
                                        name="Business Lines"
                                        options={businessLines}
                                        handleChange={businessLinesChangeHandler}
                                        tab_index="0"
                                    />
                                    {isFormSubmitted &&
                                    !businessLines.find((businessLine) => businessLine.is_selected) && (
                                        <Formvalidationerror fieldName = "Business Line" />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <InputLabel Title="Site(s):" />
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <MultiAutoComplete
                                        isLoading={sitesLoading || authorizedSitesLoading}
                                        error
                                        id="Sites"
                                        name="Sites"
                                        options={sites}
                                        onChange={sitesChangeHandler}
                                        tab_index="0"
                                        isDisabled = {!(businessLines.find((businessLine) => businessLine.is_selected) && formData.latestNeedByDateFrom && formData.latestNeedByDateTo)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <InputLabel Title="Labor Order(s):" />
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <MultiAutoComplete
                                        isLoading={laborOrdersLoading}
                                        error
                                        id="Labor Orders"
                                        name="Labor Orders"
                                        options={laborOrderIds}
                                        onChange={laborOrderIdsChangeHandler}
                                        tab_index="0"
                                        isDisabled = {!(businessLines.find((businessLine) => businessLine.is_selected) && formData.latestNeedByDateFrom && formData.latestNeedByDateTo)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                            <Grid container alignItems="center" className="form_group_item">
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                    <InputLabel Title="Requisition(s):" />
                                </Grid>
                                <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                                    <MultiAutoComplete
                                        isLoading={requisitionsLoading || laborOrdersLoading}
                                        error
                                        id="Requisitions"
                                        name="Requisitions"
                                        options={requisitions}
                                        onChange={requisitionsChangeHandler}
                                        tab_index="0"
                                        isDisabled = {!(businessLines.find((businessLine) => businessLine.is_selected) && formData.latestNeedByDateFrom && formData.latestNeedByDateTo)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Container>
            <div style = {{float: "right"}}>
                <ClearButton id="btn_clearButton" Title={"Clear"} onClick = {clearData} ButtonIcon={<ClearIcon/>} tab_index="0"/>
                <DownloadButton id = "btn_downloadButton" Title={"Download Candidate Data"} onClick = {downloadCandidateData} ButtonIcon={<ArrowDownward/>} tab_index="0"/>
            </div>
            {showUnauthorizedDialog && (
                <UnauthorizedAccessDialog
                    handleCancel={() => {
                        setShowUnauthorizedDialog(false);
                    }}
                    authorizationType={authType}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
        </div>
    )
}

export default withAlertSnackBar(CandidateDataReports)
