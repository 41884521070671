
export function customSortLaborOrder(inputObjectArray){
    inputObjectArray.sort((objectFirst,objectSecond) => {
        if(objectFirst.statusPreferenceCode > objectSecond.statusPreferenceCode)
            return 1;
        else if(objectFirst.statusPreferenceCode === objectSecond.statusPreferenceCode)
            if(objectFirst.laborOrderId < objectSecond.laborOrderId)
                return 1;
            else return 0;
        else return 0;
    });
    return inputObjectArray;
}

export function customSortRequisition(inputObjectArray){
    inputObjectArray.sort((objectFirst,objectSecond) => {
        if(objectFirst.statusPreferenceCode > objectSecond.statusPreferenceCode)
            return 1;
        else if(objectFirst.statusPreferenceCode === objectSecond.statusPreferenceCode)
            if(objectFirst.requisitionId < objectSecond.requisitionId)
                return 1;
            else return -1;
        else return -1;
    });
    return inputObjectArray;
}