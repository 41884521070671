import constants from "./Constants";

export function getName(firstName, middleName, lastName) {
    return firstName + middleName + lastName;
}

export function getAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function getDiversity(gender, speciallyAbled) {
    if(speciallyAbled === "Yes" || speciallyAbled === "Y") {
        return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.SA;
    }

    switch(gender) {
        case constants.CANDIDATE_MANAGEMENT.GENDERS.MALE:
            return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.MALE
        case constants.CANDIDATE_MANAGEMENT.GENDERS.M:
            return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.MALE
        case constants.CANDIDATE_MANAGEMENT.GENDERS.FEMALE:
            return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.FEMALE
        case constants.CANDIDATE_MANAGEMENT.GENDERS.F:
            return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.FEMALE
        case constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.TRANSGENDER:
            return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.TRANSGENDER
        case constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.OTHERS:
            return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.TRANSGENDER
        default: return constants.CANDIDATE_MANAGEMENT.HIRING_CATEGORIES.OTHER
    }
}


export function getCandidateHiringStage(hiringStage){
    let result = constants.CANDIDATE_MANAGEMENT.HIRING_STAGES.DB_UI_MAP[hiringStage]
    if(result!== undefined){
        return result
    }
    else {
        return hiringStage
    }
}