import {useQuery} from "react-query";
import {ActivityServices} from "../../Services/activities";
import Constants from "../../Utils/Constants";
import {isUserMultiRegion} from "../../Utils/RegionUtil";

export const useGetAllLaborOrders= (body) =>{

    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS,body],()=> ActivityServices.getTotalActivitiesWithFilters(body),{
        staleTime:1000*60*5,enabled: !isUserMultiRegion() && body!==null
    })
}