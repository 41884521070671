import React, {useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../../components/ButtonSecondary/ButtonSecondary';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SelectSingle from "../../components/FormPage/SelectSingle";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import InputLabel from "../../components/FormPage/InputLabel";
import constants from "../../Utils/Constants";
import MultiAutoComplete from "../../components/FormPage/MultiAutoComplete";
import FormInputDatePicker from "../../components/FormPage/FormInputDatePicker";
import {disablePastDate, enableRangeDate} from "../../Utils/DateUtil";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {useGetAllSites} from "../../ReactQuery/hooks/useGetAllSites";
import {useGetAuthorizedSites} from "../../ReactQuery/hooks/useGetAuthorizedSites";
import {useQueryClient} from "react-query";
import Switch from "@mui/material/Switch";
import Formfieldstar from "../../components/FormPage/FormFieldStar";
import {useGetAllParentVendors} from "../../ReactQuery/hooks/useGetAllParentVendors";
import {getVendorsList} from "../../Utils/Util";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function RequisitionsFilterDialogVertical( props) {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [jobRoles, setJobRoles] = useState(constants.ARRAYS.IN_JOB_ROLES);
    const [statuses, setStatuses] = React.useState(constants.ARRAYS.LR_STATUSES);
    const [vendors, setVendors] = React.useState([]);
    const [filterDates, setFilterDates] = useState({startDate:disablePastDate(new Date(),-7), endDate:disablePastDate(new Date(),21)});
    const [areFiltersChanged, setAreFiltersChanged] = useState(false);
    const [hiringWeekStartDate,setHiringWeekStartDate] = useState(constants.ARRAYS.HIRING_WEEK_START_DATE)
    const [hiringWeekEndDate,setHiringWeekEndDate] = useState(constants.ARRAYS.HIRING_WEEK_END_DATE)
    const [filterByHiringWeek,setFilterByHiringWeek] = useState(false);
    const [sites, setSites] = useState([]);
    const {data: queryParentVendors,isFetching: parentVendorsLoading} = useGetAllParentVendors();

    const classes = useStyles();

    const {data: querySites, isFetching: sitesLoading} = useGetAllSites()
    const {data: queryAuthorizedSites, isFetching: authorizedSitesLoading} = useGetAuthorizedSites()
    const queryClient = useQueryClient()

    useEffect(() => {

        async function fetchData() {
            const filterData = props.filterData;
            const selectedJobRole = filterData.jobRoles;
            const selectedStatus = filterData.status;
            const selectedStartDate = filterData.startDate;
            const selectedEndDate = filterData.endDate;
            const selectedStartHiringWeek = filterData.startHiringWeek;
            const selectedEndHiringWeek = filterData.endHiringWeek;

            jobRoles.forEach((jobRole)=>{
                jobRole.is_selected = selectedJobRole.includes(jobRole.name)
            })
            statuses.forEach((status) => {
                status.is_selected = selectedStatus.includes(status.id)
            })
            if(selectedStartHiringWeek!=="" && selectedEndHiringWeek!==""){
                setFilterByHiringWeek(true);
                hiringWeekStartDate.forEach((hiringWeek)=>{
                    hiringWeek.is_selected = hiringWeek.name === selectedStartHiringWeek
                })
                hiringWeekEndDate.forEach((hiringWeek)=>{
                    hiringWeek.is_selected = hiringWeek.name === selectedEndHiringWeek
                })
            }else {
                let filterDatesL={...filterDates}
                filterDatesL.startDate=selectedStartDate
                filterDatesL.endDate=selectedEndDate
                setFilterDates (filterDatesL)
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(queryParentVendors && queryParentVendors.data){
            let vendorNamelist= getVendorsList(queryParentVendors.data.parentVendors);
            vendorNamelist.forEach((vendor)=>{
                vendor.is_selected = vendor.id === props.filterData.vendorId;
            })
            setVendors(vendorNamelist);
        } // eslint-disable-next-line
    },[queryParentVendors,parentVendorsLoading,props.filterData.vendor])

    useEffect(() => {
        const filterData = props.filterData;
        const selectedSite = filterData.sites;
        if(querySites && queryAuthorizedSites && queryAuthorizedSites.data){
            let querySitesList = (querySites && querySites.data) || [];
            let authorizedSites = (queryAuthorizedSites && queryAuthorizedSites.data && queryAuthorizedSites.data.authorizedSites) || [];
            let sitesL = querySitesList.map((m) => ({
                id: m.siteId,
                is_selected: false,
                name: m.siteId ? m.siteId : "",
            }));
            if(! authorizedSites.includes("ADMIN")) {
                sitesL = sitesL.filter((site) => authorizedSites.some((authSite) => (authSite === site.id)))
            }
            sitesL.forEach((site)=>{
                site.is_selected = selectedSite.includes(site.name)
            })
            setSites(sitesL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[querySites, queryAuthorizedSites])


    const requisitionJobRoleChangeHandler = (event, value) => {
        let selectedIds = value && value.length > 0 ? value.map((mapItem) => mapItem.name) : [];
        let jobRolesL = [ ...jobRoles]
        jobRolesL.forEach((jobRole) => {
            jobRole.is_selected =
                selectedIds && selectedIds.length > 0 && selectedIds.includes(jobRole.name);
        });
        setAreFiltersChanged(true)
        setJobRoles(jobRolesL);
    };

    function isApplyButtonDisabled(){
        if(filterByHiringWeek){
            return (!getSelectedHiringWeek(hiringWeekStartDate) || !getSelectedHiringWeek(hiringWeekEndDate));
        }else{
            return (!filterDates.endDate || !areFiltersChanged);
        }
    }

    const requisitionStatusChangeHandler = (event, value) => {
        let selectedIds = value && value.length > 0 ? value.map((mapItem) => mapItem.name) : [];
        let statusesL = [ ...statuses]
        statusesL.forEach((status) => {
            status.is_selected =
                selectedIds && selectedIds.length > 0 && selectedIds.includes(status.name);
        });
        setAreFiltersChanged(true)
        setStatuses(statusesL);
    };

    const requisitionVendorChangeHandler = (event) => {
        let value = event.target.value;
        let vendorsL = [...vendors];
        vendorsL.forEach((vendor) => {
            vendor.is_selected = value && vendor.id === value;
        });
        setAreFiltersChanged(true);
        setVendors(vendorsL);
    }

    const requisitionSiteChangeHandler = (event, value) => {
        let selectedIds = value && value.length > 0 ? value.map((val) => val.id) : []
        let sitesL = [...sites];
        sitesL.forEach((site) => {
            site.is_selected = selectedIds && selectedIds.length > 0 && selectedIds.includes(site.id)
        });
        setAreFiltersChanged(true)
        setSites(sitesL);
    }

    const requisitionStartDateChangeHandler = (event) => {
        let filterDatesL = {...filterDates}
        filterDatesL.startDate = event.target.value
        filterDatesL.endDate = ''
        setAreFiltersChanged(true)
        setFilterDates(filterDatesL)
    }

    const requisitionEndDateChangeHandler = (event) => {
        let filterDatesL = {...filterDates}
        filterDatesL.endDate = event.target.value
        setAreFiltersChanged(true)
        setFilterDates(filterDatesL)
    }

    const hiringWeekStartDateChangeHandler =(event) => {
        let value = event.target.value;
        let hiringWeekStartDateL = [ ...hiringWeekStartDate ];
        hiringWeekStartDateL.forEach((hiringWeek) => {
            hiringWeek.is_selected = value && hiringWeek.name === value;
        });
        setHiringWeekStartDate(hiringWeekStartDateL);
    }

    const hiringWeekEndDateChangeHandler =(event) => {
        let value = event.target.value;
        let hiringWeekEndDateL = [ ...hiringWeekEndDate ];
        hiringWeekEndDateL.forEach((hiringWeek) => {
            hiringWeek.is_selected = value && hiringWeek.name === value;
        });
        setAreFiltersChanged(true)
        setHiringWeekEndDate(hiringWeekEndDateL);
    }

    const handleApply = () => {
        setOpen(false);
        let externalFilters = {}
        externalFilters.jobRoles=jobRoles.filter((filterItem) => filterItem.is_selected).map((mapItem) => mapItem.id);
        externalFilters.status=statuses.filter((filterItem) => filterItem.is_selected).map((mapItem) => mapItem.id);
        externalFilters.sites=sites.filter((filterItem) => filterItem.is_selected).map((mapItem) => mapItem.id);
        externalFilters.vendorId = vendors.filter((filterItem) => filterItem.is_selected).map((mapItem) => mapItem.id).join(',');
        externalFilters.startDate = filterByHiringWeek ? "" : filterDates.startDate;
        externalFilters.endDate = filterByHiringWeek ? "" : filterDates.endDate;
        externalFilters.startHiringWeek = filterByHiringWeek?getSelectedHiringWeek(hiringWeekStartDate):"";
        externalFilters.endHiringWeek = filterByHiringWeek?getSelectedHiringWeek(hiringWeekEndDate):"";
        externalFilters.resetAppliedFilter = false;
        setAreFiltersChanged(false);
        props.handleApply(externalFilters);
        queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS,externalFilters])
    }

    function getSelectedHiringWeek(hiringWeeks){
        let selectedHiringWeek = hiringWeeks.find(hiringWeeks=> hiringWeeks.is_selected);
        return selectedHiringWeek ? selectedHiringWeek.name: '';
    }

    const handleClear = () => {
        setOpen(false);
        let externalFilters = {}
        externalFilters.jobRoles=[];
        externalFilters.vendorId='';
        externalFilters.status=[];
        externalFilters.sites = [];
        externalFilters.startHiringWeek=""
        externalFilters.endHiringWeek=""
        externalFilters.startDate = disablePastDate(new Date(),-7);
        externalFilters.endDate = disablePastDate(new Date(), 21);
        externalFilters.resetAppliedFilter = true;
        setAreFiltersChanged(false);
        props.handleApply(externalFilters);
        queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS,externalFilters])
    };

    const handleClose = () => {
        props.handleCancel();
    };

    const switchChangeHandler = () =>{
        setAreFiltersChanged(false);
        setFilterByHiringWeek(prevState => !prevState)
    }

    return (
        <div style={{minWidth: '350px'}}>
        <Dialog
            fullScreen={fullScreen}
            open={open}>

            <MuiDialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">Apply filters</Typography>
                <IconButton id="amz_requisitionFilterGridCloseButton" aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={3} direction="column" justify="space-around">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={2}>
                            <Grid container direction="row" justifyContent="space-between">
                                <InputLabel Title="Need By Date/Hiring Week:" />
                                <Switch label={"switch"} onChange={switchChangeHandler} checked={filterByHiringWeek} size="small"/>
                            </Grid>
                            <Grid container direction="column">
                                <Grid>
                                    <InputLabel Title="From" />
                                    <Formfieldstar />
                                </Grid>
                                {
                                    !filterByHiringWeek ? <FormInputDatePicker
                                        id="filterStartDate"
                                        style={{maxWidth: "175px"}}
                                        name="LaborOrderStartDate"
                                        value={filterDates.startDate}
                                        onChange={requisitionStartDateChangeHandler}
                                        tabIndex="0"
                                    /> : <SelectSingle isDisabled={!filterDates.endDate} id="hiringWeekStartDate"
                                                       name="hiringWeek" fieldName="Hiring Week"
                                                       options={hiringWeekStartDate}
                                                       handleChange={hiringWeekStartDateChangeHandler}/>
                                }
                                <Grid>
                                    <InputLabel Title="To" />
                                    <Formfieldstar />
                                </Grid>
                                {
                                    !filterByHiringWeek ? <FormInputDatePicker
                                        id = "filterEndDate"
                                        style={{ maxWidth: "175px" }}
                                        name="LaborOrderEndDate"
                                        value={filterDates.endDate}
                                        onChange={requisitionEndDateChangeHandler}
                                        tabIndex="0"
                                        maxDate = {enableRangeDate(new Date(filterDates.startDate))}
                                        minDate = { disablePastDate(new Date(filterDates.startDate),0)}
                                        isDisabled = {!filterDates.startDate}
                                    />: <SelectSingle isDisabled={!filterDates.endDate} id="hiringWeekEndDate"
                                                      name="hiringWeek" fieldName="Hiring Week"
                                                      options={hiringWeekEndDate}
                                                      handleChange={hiringWeekEndDateChangeHandler}/>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={2}>
                            <InputLabel Title="Job Role" />
                            <MultiAutoComplete disabled= {!filterDates.endDate} id="JobRole" name="JobRole" options={jobRoles} onChange={requisitionJobRoleChangeHandler} />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={2}>
                            <InputLabel Title="Site" />
                            <MultiAutoComplete isLoading={sitesLoading || authorizedSitesLoading} disabled= {!filterDates.endDate} id="Site" name="Site" options={sites} onChange={requisitionSiteChangeHandler} />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={2}>
                            <InputLabel Title="Requisition Status" />
                            <MultiAutoComplete disabled= {!filterDates.endDate} id="Status"  name="Status" options={statuses} onChange={requisitionStatusChangeHandler} />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form_group_item_report" p={2}>
                            <InputLabel Title="Vendor" />
                            <SelectSingle isDisabled= {!filterDates.endDate} id="Vendor" name="Vendor" fieldName = "vendors" options={vendors} isLoading={parentVendorsLoading} handleChange={requisitionVendorChangeHandler} />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonSecondary id="btn_resetButton" variant="outlined" Title="Reset" onClick={handleClear} color="danger" ButtonIcon={ <CloseIcon />} />
                <ButtonPrimary id="btn_applyButton" isDisabled={isApplyButtonDisabled()} variant="outlined" Title="Apply" onClick={handleApply} color="primary" autoFocus ButtonIcon={ <CheckIcon /> } />
            </DialogActions>
        </Dialog>
    </div>
    )
}

RequisitionsFilterDialogVertical.propTypes = {

}

export default withAlertSnackBar(RequisitionsFilterDialogVertical)