import React from "react";
import {IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField} from "@mui/material";
import {Mail} from "@material-ui/icons";
import {List} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import constants from "../../Utils/Constants";
import {testPattern} from "../../Utils/PatternUtil";
import Grid from "@material-ui/core/Grid";
import {styled} from "@mui/material/styles";

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function AddWatchersOrApprovers( props) {
    const [watcherInput, setWatcherInput] = React.useState("");
    const [watcherInputError, setWatcherInputError] = React.useState(false);
    const [watcherLabel, setWatcherLabel] = React.useState(props.label);
    const [watcherHelperText, setWatcherHelperText] = React.useState(constants.WATCHER.DEFAULT_MESSAGES.WATCHER_HELPER_TEXT);

    const handleWatcherChange = (event) => {
        setWatcherInput(event.target.value);
    }

    const validateErrorMessage = (watcherInput) => {
        if (!testPattern(watcherInput, new RegExp("^[a-z]([a-z0-9]){2,7}$"))) {
            return constants.WATCHER.ERROR_MESSAGES.PATTERN_MISMATCH;
        } else if(props.watchersList.includes(watcherInput + constants.WATCHER.EMAIL_SUFFIX)) {
            return constants.WATCHER.ERROR_MESSAGES.WATCHER_ALREADY_PRESENT;
        }
        return null;
    }

    const validateWatcherInput = (watcherInput) => {
        const errorMessage = validateErrorMessage(watcherInput);
        if (errorMessage) {
            setWatcherLabel(constants.WATCHER.ERROR_MESSAGES.WATCHER_LABEL);
            setWatcherInputError(true);
            setWatcherHelperText(errorMessage);
            return false;
        }
        //Success Case
        setWatcherLabel(props.label);
        setWatcherInputError(false);
        setWatcherHelperText(constants.WATCHER.DEFAULT_MESSAGES.WATCHER_HELPER_TEXT)
        return true;
    }

    const updateWatcherRow = async (updatedWatcherList) => {
        let isWatcherUpdateSuccess = true;
        if(props.isUpdateRequired){
            await props.updateWatcher(updatedWatcherList).catch(()=>{
                props.snackbarShowMessage(`${constants.WATCHER.ERROR_MESSAGES.WATCHER_UPDATE_FAILED}`,
                    "error", "3000");
                isWatcherUpdateSuccess = false});
        }
        return isWatcherUpdateSuccess;
    }

    const addWatcher = async (watcherInput) => {
        if (validateWatcherInput(watcherInput)) {
            let newWatcher = watcherInput; //adding amazon.com to alias
            const updatedWatcherList = [newWatcher, ...props.watchersList];
            const isWatcherListUpdated = await updateWatcherRow(updatedWatcherList);
            if (isWatcherListUpdated) {  //check if network call is success
                props.setWatchersList(updatedWatcherList);
                setWatcherInput("");
            }
        }
    }

    const removeWatcher = async (selectedWatcher) => {
        const updatedWatcherList = props.watchersList.filter((watcher) => watcher !== selectedWatcher);
        const isWatcherListUpdated = await updateWatcherRow(updatedWatcherList);
        if (isWatcherListUpdated) { //check if network call succeeds
            props.setWatchersList(updatedWatcherList)
        }
    }

    return(
        <Grid container spacing={2} style={{width:"100%",marginLeft:"0.5rem",border:"1px solid rgba(0, 0, 0, 0.5)"}}>
        <Grid item alignContent="center" style={{width:"100%"}}>
            <TextField helperText={watcherHelperText} id="watcherInput" label={watcherLabel} style={{width:"85%"}} InputLabelProps={{ shrink: true, required: props.isMandatory }}
                       value={watcherInput} onChange={handleWatcherChange} error={watcherInputError}/>
            <IconButton aria-label="add" onClick={()=>addWatcher(watcherInput)} style={{border:"1px solid #c4c4c4",borderRadius:"0%",width:"15%",height:"70%"}}>
                Add
            </IconButton>
        </Grid>
            <Grid item style={{height:"100px",width:"100%",overflowY:"auto",paddingTop:"0px"}}>
        <List style={{width:"100%",paddingTop:"0px"}}>
            {props.watchersList.map((watcher) => {
                return (
                    <StyledListItem
                        key={watcher}
                        disablePadding={true}
                        dense={true}
                        style={{width:"95%"}}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={()=>removeWatcher(watcher)}>
                                <CloseIcon/>
                            </IconButton>
                        }
                    >
                        <ListItemButton sx={{ maxWidth: "50px"}}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                                <Mail/>
                            </ListItemIcon>
                            <ListItemText primary={watcher} sx={{ maxWidth: "50px" }}/>
                        </ListItemButton>
                    </StyledListItem>
                )})}
        </List>
            </Grid>
    </Grid>);
}

export default AddWatchersOrApprovers;