import React, {useEffect, useState} from "react";
import {Card, CardContent, InputBase, List, ListItem, ListItemText, Paper} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import constants from "../../../Utils/Constants";
import {useGetAllAuditTrailLogs} from "../../../ReactQuery/hooks/useGetAllAuditTrailLogs";
import {withAlertSnackBar} from "../../../HOComponents/AlertSnackBarHOC";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import Container from "@material-ui/core/Container";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import {searchAuditLog} from "../../../Utils/AuditTrailLogsUtils";
import {getUserCountry} from "../../../Utils/Util";

const useStyles = makeStyles((theme) => ({
    divider: {
        width: "95%",
        height: "0",
        margin:"0.4rem auto",
        border: "1px solid #D1D5DA"
    },
    ul: {
        listStylePosition: "inside",
        listStyle:"circle",
        borderLeft: "solid 2px #FC6600"
    },
    inputLeft: {
        flex: 1,
        fontSize: "1rem",
        color:"black",
        marginLeft:"5px"
    },
    iconButtonGrid: {
        padding: 0,
        color: "#687078",
        marginLeft:"0.3rem"
    },
}));

function AuditLogCards(props){
    const [rowData, setRowData] = React.useState([]);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [searchConfig, setSearchConfig] = useState({});
    const {data: queryLogs, isFetching: logsLoading,error:logError} = useGetAllAuditTrailLogs({laborOrderId:props.config.pk,requisitionId:props.config.sk,entityType:props.config.type,country: getUserCountry()});
    const classes = useStyles();

    useEffect(()=>{
        if(logError){
            props.snackbarShowMessage(
                `${constants.ERROR.FETCHING_ERROR} ${logError.data.message}`,
                "error",
                "4000",
            );
            setRowData ([]);
            setGlobalRows([]);
        }else{
            let data = (queryLogs && queryLogs.data && queryLogs.data.auditTrail) || [];
            if(data && data.length!==0)
                prepopulate(data);
        }
        // eslint-disable-next-line
    },[queryLogs,logError]);

    useEffect(() => {
        if(Object.keys(searchConfig).length !== 0) {
            operateOnRows ()
        }
        // eslint-disable-next-line
    },[searchConfig.searchValue])

    const prepopulate =  (auditLogs) => {
        let rowsData = []
        if (auditLogs){
            auditLogs.forEach(auditLog => {
                let attributesList = [];
                auditLog.attributesChanged.forEach(attribute =>{
                    attributesList.push({
                        key:attribute.key,
                        newValue: attribute.newValue,
                        oldValue: attribute.oldValue
                    })
                })
                rowsData.push({
                    modifiedBy: auditLog.modifiedBy,
                    modifiedDate: auditLog.modifiedDate,
                    actionType: auditLog.actionType,
                    entityType: auditLog.entityType? constants.AUDIT_TRAIL.ENTITY_TYPE[auditLog.entityType]:"",
                    attributesChangedList: attributesList
                })
            })
        }
        setRowData(rowsData)
        setGlobalRows(rowsData)
    }

    const handleSearchClear = () => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = "";
        setSearchConfig(searchConfigL);
    };

    const handleSearchValueChange = (e) => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = e.target.value;
        if(e.keyCode === 13){
            e.preventDefault();
        }
        else{
            setSearchConfig(searchConfigL);
        }
    };

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData = searchAuditLog(rowsData, searchConfig.searchValue);
        setRowData(rowsData);
    }

    return (
        <Container id="viewRequisitionAuditTrail" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",borderRadius:"8px"}} maxWidth="lg">
            <Grid container direction="row" style={{paddingTop:"1.1rem"}} justifyContent="space-evenly">
            <Paper component="form" style={{width:"85%",marginLeft:"8px",display:"flex",boxShadow:"none",border:"1px solid #aab7b8",alignItems:"center"}}>
                <SearchIcon className={classes.iconButtonGrid} />
                <InputBase
                    className={classes.inputLeft}
                    placeholder={constants.AUDIT_TRAIL.SEARCH_PLACEHOLDER}
                    inputProps={constants.AUDIT_TRAIL.SEARCH_PLACEHOLDER}
                    value={searchConfig.searchValue ? searchConfig.searchValue : ""}
                    onChange={handleSearchValueChange}
                />
            </Paper>
                <Button variant="contained" style={{textTransform:"capitalize"}} onClick={handleSearchClear} >Clear Search</Button>
                <Divider id = "candidateManagementDivider" className={classes.divider} style={{width:"100%",marginTop:"0.8rem"}}/>
            </Grid>
        <Grid
            container
            direction="column"
            justifyContent="left"
            alignItems="left"
            spacing={0}>
            {!logsLoading ? rowData.map((auditLog,index)=>{ return (
                    <Card key={"amz_auditTrailCard"+index.toString()} style={{border: "none",backgroundColor:"transparent",marginTop:"0.3rem"}} variant="outlined">
                        <CardContent style={{padding:"0.25rem"}}>
                                <Grid id={"amz_auditLogItem"+index.toString()} container direction="row" justifyContent="flex-start">
                                    <Grid item style={{width:"2%",margin:"auto 0"}}>
                                        <DoubleArrowIcon style={{color:"#007486",fontSize:"1.5rem"}}/>
                                    </Grid>
                                    <Grid item  style={{width:"98%"}}>
                                        <Grid container direction="row">
                                            <Typography style={{fontSize: "1rem", color: "#FC6600", margin: "0 0.3rem"}}>
                                                {auditLog.modifiedBy === "SYSTEM_GENERATED_EVENT" ? "Event triggered by System" : auditLog.modifiedBy}
                                            </Typography>
                                            {index === (globalRows.length - 1)?
                                                <Typography style={{
                                                    fontSize: "0.75em",
                                                    color: "#808080",
                                                    paddingTop: "0.35rem"
                                                }}>
                                                    created this {auditLog.entityType ? auditLog.entityType :" issue "} on
                                                </Typography> :
                                                <Typography style={{
                                                    fontSize: "0.75em",
                                                    color: "#808080",
                                                    paddingTop: "0.35rem"
                                                }}>
                                                    {auditLog.actionType===constants.AUDIT_TRAIL.EVENT_TYPE_INSERT ? "created ":"edited " }
                                                    {auditLog.entityType ? auditLog.entityType===constants.AUDIT_TRAIL.ENTITY_TYPE_COMMENT ? auditLog.entityType : " this " + auditLog.entityType :" issue "} on
                                                </Typography>
                                            }
                                            <Typography style={{fontSize: "0.9em",margin: "0 0.3rem",paddingTop:"0.1rem"}}>
                                                <b>{auditLog.modifiedDate}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid container direction="column" style={{width:"98%",marginLeft:"2%"}}>
                                            <Typography style={{fontSize: "1rem"}}>
                                                Modifications :
                                            </Typography>
                                            <Grid item style={{marginLeft:"3rem"}}>
                                                <List dense={false} className={classes.ul} style={{padding:"0"}}>
                                                    {auditLog.attributesChangedList && auditLog.attributesChangedList.map((attributeChanged,index)=>{
                                                        return (<ListItem key={"auditLogsAttributesChanged"+index} alignItems="flex-start" style={{padding:"0.2rem 0.3rem"}}>
                                                            <HdrStrongIcon />
                                                            <ListItemText style={{margin:"0",paddingLeft:"0.15rem",fontSize: "0.8em",textTransform:"capitalize"}}>{attributeChanged.key}<span style={{fontSize: "0.75em",color:"#808080",padding:"0 0.1rem",textTransform:"lowercase"}}>modified to </span>{attributeChanged.newValue}</ListItemText>
                                                        </ListItem>);
                                                    })}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            <Divider id = "candidateManagementDivider" className={classes.divider}/>
                        </CardContent>
                    </Card>)}):
                <Grid id="amz_requisitionVerticalGridNoOption" container style={{height:"45vh"}}>
                {logsLoading && <div style={{margin:"auto"}}><CircularProgress/></div>}</Grid> }
        </Grid>
        </Container>
    )
}

export default withAlertSnackBar(AuditLogCards);