import React from 'react'
import PropTypes from 'prop-types'

function Title(props) {
    return (
       <> {props.title}</>
    )
}

Title.defaultProps = {
    title: 'Labor Order and Requisition Management',
}

Title.propTypes = {
    title: PropTypes.string,
}

export default Title
