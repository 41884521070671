import React, { Component } from 'react'
import { NavLink } from "react-router-dom";

class ReportTabs extends Component {
    onPaths(paths) {
        return (match, location) => {
            return paths.includes(location.pathname);
        };
    }

    setSelected = (tab) => {
        this.setState({ selected: tab});
    }

    render() {
        return (
            <div>
                <ul className='amz-tabs'>
                    <NavLink className="amz-tab-item" activeClassName="active" to="/reports/candidateData" isActive={this.onPaths(["/reports", "reports//candidateData"])}><li className='amz-tab-link'>Candidate Data</li></NavLink>
                </ul>
            </div>
        );
    }
}
export default ReportTabs
