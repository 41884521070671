import React, {useState, useEffect, useRef} from "react";
import Grid from "@material-ui/core/Grid";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import constants from "../../Utils/Constants";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import {Tune} from "@material-ui/icons";
import RequisitionsFilterDialogVertical from "./RequisitionsFilterDialogVertical";
import RequisitionsVerticalGrid from "./RequisitionsVerticalGrid";
import CandidateDataGrid from "./CandidateDataGrid";
import {Container, InputBase, Paper} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import {useHistory, useLocation} from "react-router-dom";
import {disablePastDate} from "../../Utils/DateUtil";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
    verticalGridBackground: {
        backgroundColor: "#F1F4F7",
        height: "calc(100vh - 115px)",
        minWidth: "27%",
        overflowY:"auto",
        overflowX: "hidden"
    },
    text:{
        margin: "auto 0.8em"
    },
    divider:{
        width: "100%",
        height:"0",
        border: "1px solid #D1D5DA"
    },
    iconButton: {
        padding: 10,
        color: "#000000",
    },
    iconButtonGrid: {
        padding: 10,
        color: "#000000",
    },
    paper: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 450,
        height: 45,
        borderColor: "#000000"
    },
    paperVerticalGrid: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 450,
        height: 35,
    },
    input: {
        flex: 1,
        fontSize: "1.2rem",
        color: "#000000",
    },
    inputLeft: {
        flex: 1,
        fontSize: "1.2rem",
        color:"black",
        marginRight:"5px"
    },
    dividerGrid: {
        height: 28,
        margin: 4,
    }
}));

function CandidateManagement() {
    const [paginationConfig, setPaginationConfig] = useState({laborOrderId: '', requisitionId:'',pageNo: 1, pageSize: 25, externalFilters: {vendorId: '', jobRoles: [],sites:[],resetAppliedFilter:false, status:[],startDate:disablePastDate(new Date(),-14), endDate:disablePastDate(new Date(), 91)}});
    const [searchConfig, setSearchConfig] = useState({});
    const [searchConfigLeftGrid, setSearchConfigLeftGrid] = useState({});
    const [externalFiltersConfig, setExternalFiltersConfig] = React.useState({businessLine:'', endDate:'',sites:''});
    const [showFormFilterDialog, setShowFormFilterDialog] = useState(false);

    const classes = useStyles();
    const gridRef = useRef();
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        setSearchConfig('');
        setExternalFiltersConfig({vendorId: '', jobRoles: [],status:["OPEN","ACCEPTED","WORK_IN_PROGRESS","DECLINED"], sites:[],startDate:disablePastDate(new Date(),-7), endDate:disablePastDate(new Date(), 21)})
    }, [])

    useEffect(() =>{
        if (location.state && location.state.laborOrderId && location.state.laborOrderId) {
            let paginationConfigL={...paginationConfig};
            paginationConfigL.laborOrderId=location.state.laborOrderId;
            paginationConfigL.requisitionId=location.state.requisitionId;
            setPaginationConfig (paginationConfigL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.state]);


    const toggleFilters = (externalFilters) => {
        setShowFormFilterDialog(true);
    };

    let validFiltersCount = Object.values(externalFiltersConfig).filter(
        (filterItem) => (filterItem !== "" && filterItem.length >0)
    ).length-1;



    const applyFilters = (externalFilters) => {
        setShowFormFilterDialog(false);
        let externalFiltersConfigL = { ...externalFiltersConfig };
        externalFiltersConfigL = { ...externalFiltersConfigL, ...externalFilters };
        setExternalFiltersConfig(externalFiltersConfigL);
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.externalFilters = externalFilters;
        paginationConfigL.externalFiltersApplied = true;
        setPaginationConfig(paginationConfigL);
        setShowFormFilterDialog(false);
    };

    const handleSearchValueChange = (e) => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = e.target.value;
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.searchValue = e.target.value;
        if(e.keyCode === 13){
            e.preventDefault();
        }
        else{
            setSearchConfig(searchConfigL);
            setPaginationConfig(paginationConfigL);
        }
    };
    const handleSearchClick = () => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchConfig.searchValue;
        setPaginationConfig(paginationConfigL);
    };

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            handleSearchClick();
        }
    };

    const updateCandidateGrid = (requisition) => {
        if(requisition.laborOrderId && requisition.requisitionId){
            let paginationConfigL = { ...paginationConfig };
            paginationConfigL.laborOrderId = requisition.laborOrderId;
            paginationConfigL.requisitionId = requisition.requisitionId;
            setPaginationConfig(paginationConfigL);
            history.replace( {state: {}} );
        }
    };

    const handleSearchValueChangeLeftGrid = (e) => {
        let searchConfigL = { ...searchConfigLeftGrid };
        searchConfigL.searchValue = e.target.value;
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.searchValue = e.target.value;
        if(e.keyCode === 13){
            e.preventDefault();
        }
        else{
            setSearchConfigLeftGrid(searchConfigL);
        }
    };

    const updateFiltersFromResponse = (externalFilters) =>{
        let externalFiltersConfigL = { ...externalFiltersConfig };
        externalFiltersConfigL = { ...externalFiltersConfigL, ...externalFilters };
        externalFiltersConfigL.alias = "";
        setExternalFiltersConfig(externalFiltersConfigL);
    };

    return (
        <div id = "candidateManagementDiv" className="amz_content_new_view" >

            <Grid id = "candidateManagementGridContainer" container direction="row">

            <Grid
                xs={3} sm={3} md={3} lg={3} xl={3}
                direction="row"
                className={classes.verticalGridBackground}>

                <Grid
                    direction="column"
                    container
                    className="headerVerticalRequisition"
                >

                <Grid
                    id = "amz_filterCandidateManagement"
                    container
                    direction="row"
                    style={{margin:"1rem auto"}}
                    justify="space-between"
                    alignItems="center">

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Paper component="form" className={classes.paperVerticalGrid} style={{width:"90%",marginLeft:"8px",backgroundColor:"#F1F4F7",color:"black",boxShadow:"none",borderBottom:"1px solid black"}}>
                            <InputBase
                                className={classes.inputLeft}
                                placeholder={constants.REQUISITIONS.SEARCH_PLACEHOLDER_CANDIDATE_MANAGEMENT}
                                inputProps={constants.REQUISITIONS.SEARCH_PLACEHOLDER_CANDIDATE_MANAGEMENT}
                                value={searchConfigLeftGrid.searchValue ? searchConfigLeftGrid.searchValue : ""}
                                onChange={handleSearchValueChangeLeftGrid}
                            />
                            <SearchIcon className={classes.iconButtonGrid} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ButtonSecondary
                            id = "amz_buttonSecondaryCandidateManagement"
                            style={{margin: "auto 0.5em", color: "#000000", border: "none",paddingLeft:"1.7rem"}}
                            Title={`${constants.BUTTON.FILTER} ${
                                validFiltersCount > 0 ? validFiltersCount : ""
                            }`}
                            onClick={toggleFilters}
                            ButtonIcon={<Tune/>}
                        />
                    </Grid>
                </Grid>

                <Divider id = "candidateManagementDivider" className={classes.divider}/>
                </Grid>
                <RequisitionsVerticalGrid config={paginationConfig} ref={gridRef} searchConfig={searchConfigLeftGrid} updateFilter={updateFiltersFromResponse} updateCandidateGridBySelectedRequistion={updateCandidateGrid}/>
            </Grid>

            <Container id = "candidateManagementContainer" style={{width:"73%",margin:"0px",paddingRight:"4rem"}}>
                <Grid  id = "candidateManagementGridContainer" container direction="row" justify="space-between" alignItems="center" className="mb-10">
                    <Grid style = {{margin : "0.4rem 0px"}} item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link className="primary_color" onClick={() => (history.push(`/${constants.REQUISITIONS.ROUTE.VIEW}/${paginationConfig.laborOrderId}&${paginationConfig.requisitionId}`))}>
                                {constants.REQUISITIONS.TITLE_SINGULAR}
                            </Link>
                            <Typography color="textPrimary">{paginationConfig.requisitionId ?paginationConfig.requisitionId:'Requisition-ID'}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid id = "candidateManagementGridContainer" container item justify="space-between">
                        <Paper id = "candidateManagementPaper"  item xs={12} sm={12} md={12} lg={12} xl={12} component="form" className={classes.paper}>
                            <SearchIcon  id = "candidateManagementSearchIcon" className={classes.iconButtonGrid} />
                            <InputBase
                                id = "candidateManagementInputBase"
                                className={classes.input}
                                placeholder={constants.CANDIDATE_MANAGEMENT.SEARCH_PLACEHOLDER}
                                inputProps={constants.CANDIDATE_MANAGEMENT.SEARCH_PLACEHOLDER}
                                value={searchConfig.searchValue ? searchConfig.searchValue : ""}
                                onChange={handleSearchValueChange}
                                onKeyPress={handleKeyPress}
                            />
                            <Divider id = "candidateManagementDivider" className={classes.dividerGrid} orientation="vertical" />
                            <ButtonSecondary id = "amz_CandidateManagementSearchButton" size = {"medium"} color = {"secondary"} Title="Search" onClick={handleSearchClick} />
                        </Paper>
                    </Grid>
                </Grid>

                <div style={{float: "right"}}>
                    <div style={{fontSize:"12px"}}><div class='box orange'></div> Auto-Moved: Candidate did not exist against this requisition originally</div>
                </div>
                <br/>
                <div style={{float: "right"}}>
                    <div style={{fontSize:"12px"}}><div class='box blue'></div> Candidate 'Onboarding - Complete' against different requisition(s)</div>
                </div>
                <br/>

                <div className="d-flex" style={{paddingTop:"1%"}}>
                    <CandidateDataGrid View="page" config={paginationConfig} />
                </div>
                
            </Container>

            </Grid>
            {showFormFilterDialog && (
                <RequisitionsFilterDialogVertical
                    filterData={externalFiltersConfig}
                    handleApply={applyFilters}
                    handleCancel={() => {
                        setShowFormFilterDialog(false);
                    }}
                />
            )}
        </div>
    );
}

export default CandidateManagement;