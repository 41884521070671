import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function SelectSingle(props) {
  let selected =
    props.options &&
    props.options.length > 0 &&
    props.options.find((f) => f.is_selected)
      ? props.options.find((f) => f.is_selected).id
      : 0;
  return (
    <Select
      className={`amz_select ${props.cname}`}
      value={selected}
      onChange={props.handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      tabIndex={props.tab_index}
      disabled={props.isDisabled}>
      <MenuItem value="0">
        {
            props.isLoading? <em> Loading... </em>:(props.options && (props.options.length > 0) ? <em>Choose option</em> : <em> No {props.fieldName} </em>)}
      </MenuItem>
      {props.options &&
        props.options.length > 0 &&
        props.options.map((o) => <MenuItem value={o.id}>{o.name}</MenuItem>)}
    </Select>
  );
}

export default SelectSingle;
