import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Grid} from "@material-ui/core";
import InputLabel from "../../components/FormPage/InputLabel";
import React, {useEffect, useRef, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    Card,
    CardContent,
    TextField,
    Typography
} from "@mui/material";
import Container from "@material-ui/core/Container";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import Link from "@material-ui/core/Link";
import EditIcon from "@material-ui/icons/Edit";
import CommentActions from "./CommentActions";
import DeleteIcon from "@material-ui/icons/Delete";
import Constants from "../../Utils/Constants";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from "@material-ui/core/Button";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";
import LinearWithValueLabel from "../../components/ProgressBar/LinearLabelProgressBar";
import Box from "@mui/material/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoHelpIcon from "../../components/FormPage/InfoHelpIcon";
import {checkCommentRegex} from "../../Utils/PatternUtil";

function CommentsLog(props) {
    const [commentTextArea, setCommentTextArea] = useState("");
    const [comments, setComments] =useState([]);
    const [editCommentTextArea, setEditCommentTextArea] = useState("");
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [progressInfos, setProgressInfos] = React.useState([{uploadOperation:false}]);
    const [fileLimit,setFileLimit] = useState(false);
    const [isCommentModified,setIsCommentModified] = useState(false);
    const attachmentsAsync = useRef([]);

    useEffect(() =>{
        if(progressInfos && progressInfos[0] && progressInfos[0].uploadOperation){
            progressInfos.forEach((progressFile,index) =>{
                if(index>0 && progressFile.percentage===0){
                    const formDataRequest = new FormData();
                    formDataRequest.append("prefix",props.parentID);
                    formDataRequest.append("files", progressFile.file);
                    uploadAttachmentsHandler(formDataRequest,index).then((res) =>{
                        if(res && res.data && res.data.error){
                            setTimeout(()=> window.location.reload(),2000)
                        }
                        else if(index===progressInfos.length-1) {
                            setIsCommentModified (true);
                        }
                        progressInfos[index].percentage = 100;
                        setProgressInfos(progressInfos);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[progressInfos]);

    useEffect(() => {
        let commentsL = []
        props.comments.forEach((comment) => {
            if(comment.status !== Constants.COMMENTS.STATUSES.DELETED){
                commentsL.push({
                    author: comment.author,
                    time: comment.time,
                    date: comment.date,
                    comment: comment.comment,
                    attachments: comment.attachments,
                    isBeingEdited: false,
                    vendorKamEmail: comment.vendorKamEmail,
                    vendorName: comment.vendorName,
                    status: comment.status,
                    createdDateTimestamp: comment.createdDateTimestamp

                });
            }
        })
        setComments(commentsL)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.comments])

    const handleAddComment = (event) => {
        setCommentTextArea(event.target.value)
        setIsCommentModified(true);
    }
    const handleEditComment = (event) => {
        setEditCommentTextArea(event.target.value)
    }

    const handleEditAction =(commentObject)=>{
        let commentsL = [...comments]
        commentsL.forEach((comment)=>{
            comment.isBeingEdited = comment.createdDateTimestamp === commentObject.createdDateTimestamp
            if(comment.isBeingEdited) setEditCommentTextArea(comment.comment)
        })
        setComments(commentsL)
    }

    const handleEditCancelComment =()=>{
        let commentsL = [...comments]
        commentsL.forEach((comment)=>{
            comment.isBeingEdited = false
        })
        setComments(commentsL)
    }

    const handleEditSaveComment =(commentObject,isSaveOnDeleteAttachment)=>{
        let editedComment = {
            comment: editCommentTextArea,
            attachments:isSaveOnDeleteAttachment?attachmentsAsync.current:commentObject.attachments,
            parentId: props.parentID,
            createdDateTimestamp: commentObject.createdDateTimestamp,
            status:Constants.COMMENTS.STATUSES.UPDATED
        }
        if(checkCommentRegex(editCommentTextArea)){
            if(isSaveOnDeleteAttachment) {
                attachmentsAsync.current=[];
            }
            props.onEditComment(editedComment)
        }
        else{
            props.snackbarShowMessage(
                `${constants.COMMENTS.HTML_TAG_EXCEPTION}`,
                "error"
            );
        }

    }

    const handleDeleteAction = (commentObject) => {
        if(commentObject.attachments){
            commentObject.attachments.forEach(attachment=>{
                deleteAttachment(null,attachment);
            })
        }
        let deletedComment = {
            comment: commentObject.comment,
            attachments:commentObject.attachments,
            parentId: props.parentID,
            createdDateTimestamp: commentObject.createdDateTimestamp,
            status:Constants.COMMENTS.STATUSES.DELETED
        }
        props.onDeleteComment(deletedComment)
        setIsCommentModified(false);
    }

    const addComment = () =>{
        let newComment = {
            parentId: props.parentID,
            createdDateTimestamp: new Date().toISOString(),
            author:  JSON.parse(sessionStorage.getItem("session_user")).id,
            date: new Date().toLocaleDateString('en-GB'),
            time: new Date().toLocaleString('en-US', { hour: 'numeric',minute:'numeric',second:'numeric', hour12: true }),
            comment: commentTextArea,
            attachments:attachmentsAsync.current,
            laborOrderId: props.LOID,
            country: props.country
        }
        if(checkCommentRegex(commentTextArea)){
            attachmentsAsync.current = [];
            setCommentTextArea("")
            setUploadedFiles([]);
            setProgressInfos([{uploadOperation:false}]);
            setFileLimit(false);
            setIsCommentModified(false);
            props.onAddComment(newComment)
        }
        else{
            props.snackbarShowMessage(
                `${constants.COMMENTS.HTML_TAG_EXCEPTION}`,
                "error"
            );
        }

    }

    const trimFileName = fileName =>{
        if(fileName.length <= 25)
            return fileName;
        else
            return fileName.slice(0,20) + " ...";
    }

    const getFileName = data =>{
        return data.split('/')[1];
    }

    const deleteAttachment = async (comment,attachedFile) =>{
        let body = {
            s3FilePath : props.parentID + "/" + attachedFile
        }
        await ActivityServices.deleteAttachment(body).then(res => {
            if(comment && comment.attachments) {
                attachmentsAsync.current=comment.attachments.filter (file => file!==attachedFile);
            }
            handleEditSaveComment(comment,true);
        }).catch((error) => {
            console.log(error);
        })
    }

    const getAttachment = async (attachedFile) =>{
        let body = {
            s3FilePath : props.parentID + "/" + attachedFile
        }
        await ActivityServices.getAttachment(body).then((data) => {
            let link = document.createElement('a');
            link.download = data.config.data.replace(/[{}"]+/g, "").split(":")[1];
            link.href = data.data.fileSignedLink;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 200);
        }).catch((error) => {
            props.snackbarShowMessage(
                `${constants.ERROR.COMMENT_ADD_ERROR}`,
                "error"
            )
        })
    }

    const uploadAttachmentsHandler = async (formDataRequest,index) => {
        let _progressInfos=[...progressInfos];
        _progressInfos[0].uploadOperation=false;
        return await ActivityServices.uploadAttachments (formDataRequest, ( event ) => {
            _progressInfos[index].percentage=Math.round ((100 * event.loaded) / event.total);
            setProgressInfos (_progressInfos);
        }).then (( res ) => {
            if(res.data && res.data.error){
                props.snackbarShowMessage(
                    `${res.data.error}`,
                    "error"
                )
            }else {
                let data=res && res.data;
                attachmentsAsync.current.push (data.uuid + '/' + data.name);
            }
            formDataRequest.delete ("files");
            return res;
        }).catch (( error ) => {
            props.snackbarShowMessage (
                `${constants.ERROR.COMMENT_ADD_ERROR}`,
                "error"
            )
        })
    }

    const checkLimitOfFileUpload = files =>{
        let limitExceeded = false;
        files.forEach(file =>{
            if(file.size===Constants.ATTACHMENTS.MAX_SIZE) setFileLimit(true);
            if(file.size > Constants.ATTACHMENTS.MAX_SIZE) {
                props.snackbarShowMessage(
                    `${constants.ATTACHMENTS.LIMIT_ERROR}`,
                    "warning"
                );
                setFileLimit(false);
                limitExceeded = true;
            }
        });
        return limitExceeded;
    }

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let _progressInfos = [...progressInfos];
        _progressInfos[0].uploadOperation = true;
        if (!checkLimitOfFileUpload(files)) {
            setIsCommentModified(false);
            files.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    uploaded.push(file);
                    _progressInfos.push({ percentage: 0, file: file });
                }
                return false;
            })
            setUploadedFiles(uploaded)
            setProgressInfos(_progressInfos);
        }
    }

    const handleMultipleAttachments = (e) =>{
        const selectedFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(selectedFiles);
    }

    let options = [
        {
            id:`editBtn`,
            Title:"Edit",
            onClick:handleEditAction,
            Icon: EditIcon,

        },
        {
            id:`deleteBtn`,
            Title:"Delete",
            onClick :handleDeleteAction,
            Icon: DeleteIcon,
        }];

    return(
        <Container style={{paddingTop:"30px", paddingLeft:"0px", paddingRight: "0px"}}  maxWidth="xl">
            <Accordion defaultExpanded className="form_group_item">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="comment-header">
                    <Grid style={{marginLeft: "20px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <InputLabel Title= {"Comments log"} />
                    </Grid>
                </AccordionSummary>

                <AccordionDetails id="comment-body" sx={{borderTop:"1px solid #bfbfbf"}} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid p={30} style={{marginLeft: "20px", marginTop:"0px"}} item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="top" >
                        {comments.length===0 && !props.isLoading && <Typography variant="h6">No Comments Found</Typography>}
                        {props.isLoading ? <Grid container justifyContent={"space-around"} alignItems={"center"}> <CircularProgress/></Grid> :
                        comments.map((comment, counter) => (
                            <Card id={`cardComment${counter}`} key={`cardComment${counter}`} style={{boxShadow:"none", background:"#f2f2f2", marginBottom:"5px"}}>
                                <CardContent style={{paddingBottom:"3px", paddingTop:"4px"}}>
                                    <Grid container   alignItems="left"  xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Grid container spacing={0}>
                                            {!comment.vendorKamEmail &&
                                                <Link href={`${Constants.COMMENTS.PHONE_TOOL_URL}${comment.author}`} target="_blank" style={{color:"#007486", fontSize: 14, marginRight:"5px" }} color="#0073e6">{`${comment.author} `}
                                                </Link>
                                            }
                                            {comment.vendorKamEmail &&
                                                <Typography sx={{fontSize: 14, color: "#007486", marginRight: "5px"}}
                                                            color="#inherit">{`${comment.vendorKamEmail} (${comment.author}, ${comment.vendorName}) `}
                                                </Typography>
                                            }
                                            <Typography id={`txtTime${counter}`} sx={{ fontSize: 14 }} color="#595959">{` commented on ${comment.date} at ${comment.time}`}</Typography>
                                        </Grid>
                                        {!comment.isBeingEdited &&
                                            <Typography id={`txtComment${counter}`} sx={{ fontSize: 15 }}>{comment.comment}
                                            </Typography>
                                        }
                                        {JSON.parse(sessionStorage.getItem("session_user")).id===(comment.author) && !comment.isBeingEdited &&
                                            <CommentActions comment={comment} option={options}/>}
                                        {comment.isBeingEdited &&
                                            <TextField style={{backgroundColor: 'white'}} onChange={handleEditComment} rows={3} sx={{width: "100%"}} value={editCommentTextArea} multiline/>
                                        }
                                        {comment.isBeingEdited &&
                                        <Grid container justify="flex-end"  xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <ButtonSecondary id="btnCancelComment" onClick={handleEditCancelComment} Title="Cancel" tabIndex="0"/>
                                            <ButtonSecondary id="btnEditComment" isDisabled={(editCommentTextArea && !editCommentTextArea.trim()) || editCommentTextArea === comment.comment} onClick={()=>handleEditSaveComment(comment,false)} Title="Save" tabIndex="0"/>
                                        </Grid>
                                        }
                                    </Grid>
                                    <Grid id="attachmentGrid" style={{marginRight:"20px"}}>
                                        {comment.attachments && comment.attachments.length!==0 &&
                                            comment.attachments.map((attachment,counter) => (
                                                <Grid id={`commentAttachment${counter}`} container spacing={2} key={`commentAttachment${counter}`} style={{boxShadow:"none", background:"white", margin:"4px 0px",padding:"2px",borderColor:"#f2f2f2", borderRadius:"6px"}} >
                                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9} style={{background:"white",padding:"0px",paddingLeft:"5px"}}>
                                                        <Button component="label" style={{margin:"0",marginRight:"4px",padding:"0px"}} onClick={()=>getAttachment(attachment)}>
                                                        <Typography sx={{fontSize: 14, color: "#007486", marginRight: "5px"}} color="#inherit">{getFileName(attachment)}</Typography>
                                                        </Button>
                                                    </Grid>
                                                    {comment.isBeingEdited && <Grid item xl={3} lg={3} md={3} sm={3} xs={3} style={{background:"white",padding:"2px"}}>
                                                        <Box display="flex" justifyContent="flex-end"><Button component="label" style={{margin:"0",marginRight:"4px",padding:"0px"}} onClick={()=>deleteAttachment(comment,attachment)}>
                                                            <Typography sx={{fontSize: 14, color: "#007486",textTransform: "capitalize"}} color="#inherit">Delete Attachment</Typography></Button>
                                                        </Box>
                                                    </Grid>}
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}

                    </Grid>
                    <Grid item style={{marginTop:"10px", marginLeft: "30px"}}  xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TextField style={{display:(props.parentStatus === Constants.COMMENTS.STATUSES.DELETED || props.parentStatus === Constants.COMMENTS.STATUSES.INACTIVE) ? "none": "inline-flex"}} id="commentTextField" name="NewComment" onChange={handleAddComment} rows={3} value={commentTextArea} sx={{width:"100%"}} placeholder="Add new comment" multiline />
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
                        <Grid style={{marginTop:"9px", marginLeft: "30px"}}>
                            {uploadedFiles.map((file,counter) => (
                                <Grid id={`commentUploadedAttachment${counter}`} container spacing={2} key={`commentUploadedAttachment${counter}`} style={{boxShadow:"none", background:"#f2f2f2", margin:"5px 0 5px -1px", borderRadius:"4px"}} >
                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={4} style={{padding:"3px",paddingLeft:"8px"}}>
                                    <Typography sx={{fontSize: 14, color: "#007486", marginRight: "5px"}} color="#inherit">{trimFileName(file.name)}</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={8} style={{padding:"3px",paddingRight:"8px"}}>
                                        <LinearWithValueLabel value={progressInfos[counter+1] && progressInfos[counter+1].percentage} />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                        {
                            !(props.parentStatus === Constants.COMMENTS.STATUSES.DELETED || props.parentStatus === Constants.COMMENTS.STATUSES.INACTIVE) &&
                            <Grid item container justify="flex-end" alignItems="center">
                                <InfoHelpIcon FieldToolTip= {constants.COMMENTS.ATTACHMENT_TOOLTIP}
                                              IconClassName={<InfoOutlinedIcon />}
                                              Title="Attachments info"/>
                                <Button variant="outlined" component="label" style={{display: "flex",margin:"0",padding:"5px"}} startIcon={<AttachFileIcon />}><p style={{fontSize:"0.8rem",marginLeft:"-8px",textTransform: "capitalize"}}>Attach file...</p>
                                    <input hidden multiple type="file" onChange={handleMultipleAttachments} disabled={fileLimit}/>
                                </Button>
                                <ButtonSecondary style={{display: "flex"}} id="btnAddComment" isDisabled={!isCommentModified} onClick={addComment} Title="Add comment" tabIndex="0"/>
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}
export default withAlertSnackBar(CommentsLog);