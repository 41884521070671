import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import LaborOrdersGrid from "./LaborOrdersGrid";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import SearchIcon from "@material-ui/icons/Search";
import constants, {LO_DOWNLOAD_TEMPLATE_MAP} from "../../Utils/Constants";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import LaborOrdersFilterDialog from "./LaborOrdersFilterDialog";
import Demo from "../../components/DropdownMenu/customDropdownMenu";
import AddIcon from '@mui/icons-material/Add';
import ApprovalIcon from '@mui/icons-material/Approval';
import AddBoxIcon from "@material-ui/icons/AddBox";
import BulkUploadDialog from "./BulkUploadDialog";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import xlsx from "json-as-xlsx";
import {disablePastDate} from "../../Utils/DateUtil";
import {SyncDisabled, CheckCircleSharp} from "@material-ui/icons";
import FormActionAlertDialog from "../../components/FormPage/FormActionAlertDialog";
import {ActivityServices} from "../../Services/activities";
import DeleteIcon from '@material-ui/icons/Delete';
import { useQueryClient} from 'react-query';
import BulkUploadSelectionDialog from "./BulkUploadSelectionDialog";
import CreateRLCModal from "../../components/Modals/CreateRLCModal";
import {getUserCountry} from "../../Utils/Util";
import {downloadLOLRCountryFilter, isMenaUser} from "../../Utils/RegionUtil";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 550,
    },
    input: {
        flex: 1,
        fontSize: "1.2rem",
        color: "#006170",
    },
    iconButton: {
        padding: 10,
        color: "#006170",
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function LaborOrders(props) {
    const [paginationConfig, setPaginationConfig] = useState({pageNo: 1, pageSize: 25,externalFiltersApplied: false, externalFilters: {jobRoles: [], countries: [], businessLine:'', sites: [],status:[],resetAppliedFilter:false,startHiringWeek:"",endHiringWeek:"", startDate:disablePastDate(new Date(),-7), endDate:disablePastDate(new Date(),21)}});
    const [searchConfig, setSearchConfig] = useState({});
    const [externalFiltersConfig, setExternalFiltersConfig] = React.useState({jobRoles:[], countries: [], sites:[], status:[],businessLine:'' });
    const [showFormFilterDialog, setShowFormFilterDialog] = useState(false);
    const [showBulkUploadDialog, setShowBulkUploadDialog] = useState(false);
    const [showSelectBulkUploadType, setShowSelectBulkUploadType] = useState(false);
    const [bulkUploadType, setBulkUploadType] = useState("");
    const [laborOrders,setLaborOrders] = useState();
    const [selectedLaborOrdersList, setSelectedLaborOrdersList] = useState([]);
    const [showInactiveConfirmation,setShowInactiveConfirmation] = useState(false);
    const [showDeleteConfirmation,setShowDeleteConfirmation] = useState(false);
    const [showFulfillConfirmation,setShowFulfillConfirmation] = useState(false);
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
    const [processedLaborOrders, setProcessedLaborOrders] = useState([])
    const [showCreateRLCFlyout, setShowCreateRLCFlyout] = useState(false);


    let history = useHistory();
    const queryClient = useQueryClient();
    const classes = useStyles();
    const gridRef = useRef();

    useEffect(() => {
        setSearchConfig('');
        setExternalFiltersConfig({jobRoles: [],businessLine:'', sites: [],status:["OPEN"],startHiringWeek:"",endHiringWeek:"", startDate:disablePastDate(new Date(),-7), endDate:disablePastDate(new Date(),21), countries: [] })
    }, [])

    useEffect(() => {
        processSelectedLaborOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isActionMenuOpen])


    const handleSearchValueChange = (e) => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = e.target.value;
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.searchValue = e.target.value;
        paginationConfigL.searchColumn = searchConfig.searchColumn ? "site" : "";
        if(e.keyCode === 13){
            e.preventDefault();
        }
        else{
            setSearchConfig(searchConfigL);
            setPaginationConfig(paginationConfigL)
        }
    };

    const applyFilters = (externalFilters) => {
        let externalFiltersConfigL = { ...externalFiltersConfig };
        externalFiltersConfigL = { ...externalFiltersConfigL, ...externalFilters };
        setExternalFiltersConfig(externalFiltersConfigL);
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.externalFilters = externalFilters;
        paginationConfigL.externalFiltersApplied = true;
        setPaginationConfig(paginationConfigL);
        setShowFormFilterDialog(false);
    };

    const processSelectedLaborOrders = () => {
        let processedLOs = []
        selectedLaborOrdersList.forEach((laborOrder) =>{
            if(laborOrder.is_selected){
                processedLOs.push({
                    laborOrderId: laborOrder.LaborOrderId
                })
            }
        } )
        setProcessedLaborOrders(processedLOs)
    }

    const menaBulkUpload = () => {
        setShowSelectBulkUploadType(false);
        setShowBulkUploadDialog(true);
        setBulkUploadType(constants.LO_LR_BULK_UPLOAD.MENA_BUSINESS_LINES);
    }

    const updateFiltersFromResponse = (externalFilters) =>{
        let externalFiltersConfigL = { ...externalFiltersConfig };
        externalFiltersConfigL = { ...externalFiltersConfigL, ...externalFilters };
        externalFiltersConfigL.alias = "";
        setExternalFiltersConfig(externalFiltersConfigL);
    };

    const toggleFilters = (externalFilters) => {
        setShowFormFilterDialog(true);
    };

    const handleSearchClick = () => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchConfig.searchValue;
        paginationConfigL.searchColumn = searchConfig.searchColumn ? "site" : "";
        setPaginationConfig(paginationConfigL);
    };

    const CreateLaborOrder = () => {
        history.push(`${constants.LABOR_ORDERS.ROUTE.CREATE}`);
    };

    const CreateRLCApproval = () => {
        setShowCreateRLCFlyout(true);
    };

    const BulkUploadLOLR = () => {
        setIsActionMenuOpen(false)
        setShowSelectBulkUploadType(true);
    }

    const DownloadLaborOrders = async () => {
        const schema= LO_DOWNLOAD_TEMPLATE_MAP[getUserCountry()];
        const laborOrderByCountry = downloadLOLRCountryFilter(laborOrders);
        let xlsxData=[
            {
                sheet: `LaborOrderDetails`,
                columns: schema,
                content: laborOrderByCountry
            }]
        let settings={
            fileName: `LaborOrderWorkbook`,
            extraLength: 3,
            writeOptions: {width: 10},
        }

        await xlsx (xlsxData, settings).wi
        props.snackbarShowMessage (`File successfully downloaded`);
    }

    const InactiveActivityConfirm  =  () =>{
        if(processedLaborOrders.length > constants.LABOR_ORDERS.SUPPORTED_LIMIT.BULK_INACTIVE){
            props.snackbarShowMessage(constants.ERROR.BULK_UPDATE_LIMIT_PREFIX + constants.LABOR_ORDERS.SUPPORTED_LIMIT.BULK_INACTIVE + constants.ERROR.BULK_UPDATE_LO_LIMIT_SUFFIX, "error")
        }
        else{
            setShowInactiveConfirmation(true)
        }
    }

    const DeleteActivityConfirm  =  () =>{
        if(processedLaborOrders.length > constants.LABOR_ORDERS.SUPPORTED_LIMIT.BULK_DELETE){
            props.snackbarShowMessage(constants.ERROR.BULK_UPDATE_LIMIT_PREFIX + constants.LABOR_ORDERS.SUPPORTED_LIMIT.BULK_DELETE + constants.ERROR.BULK_UPDATE_LO_LIMIT_SUFFIX, "error")
        }
        else{
            setShowDeleteConfirmation(true)
        }
    }

    const FulfillActivityConfirm  =  () =>{
        if(processedLaborOrders.length > constants.LABOR_ORDERS.SUPPORTED_LIMIT.BULK_FULFILLED){
            props.snackbarShowMessage(constants.ERROR.BULK_UPDATE_LIMIT_PREFIX + constants.LABOR_ORDERS.SUPPORTED_LIMIT.BULK_FULFILLED + constants.ERROR.BULK_UPDATE_LO_LIMIT_SUFFIX, "error")
        }
        else{
            setShowFulfillConfirmation(true)
        }
    }

    const BulkUpdateActivity =async (status) => {
        setShowFulfillConfirmation(false)
        setShowDeleteConfirmation(false)
        setShowInactiveConfirmation(false)
        setIsActionMenuOpen(false)
        let body ={
            status:status,
            laborOrders: processedLaborOrders
        }
        await ActivityServices.bulkUpdateLaborOrders(body).then((resp)=>{

            const schema = constants.LO_BULK_UPDATE_SCHEMA
            let xlsxData = [
                {
                    sheet: `Response`,
                    columns: schema,
                    content: resp.data.laborOrders
                }
            ]

            let settings = {
                fileName: `BulkUpdateLaborOrderResponse`,
                extraLength: 3,
                writeOptions: {}
            }
             xlsx(xlsxData, settings)
            props.snackbarShowMessage(constants.CRUD_SUCCESS_ALERTS.PROCESSED)
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)

        }).catch((error) => {
            queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)

            props.snackbarShowMessage(
                `${constants.ERROR.UPDATE_ERROR}`,
                "error"
            );
        })
    }

    const laborOrderActions = [
        {
            id: "1",
            Title:`${constants.BUTTON.CREATE_LABOR_ORDER}`,
            Icon: AddIcon,
            onClick: CreateLaborOrder
        },
        {
            id: "2",
            Title: `${constants.BUTTON.CREATE_BULK_LO_LR}`,
            Icon: AddBoxIcon,
            onClick: BulkUploadLOLR
        },
        {
            id: "3",
            Title: `${constants.BUTTON.CREATE_RLC}`,
            Icon: ApprovalIcon,
            onClick: CreateRLCApproval,
            disabled: processedLaborOrders.length ===0
        },
        {
            id: "4",
            Title: `${constants.BUTTON.DOWNLOAD_LABOR_ORDER}`,
            Icon: ArrowDownward,
            onClick: DownloadLaborOrders
        },
        {
            id: "5",
            Title: constants.BUTTON.INACTIVE,
            Icon:SyncDisabled,
            onClick: InactiveActivityConfirm,
            disabled: processedLaborOrders.length ===0
        },
        {
            id: "6",
            Title: constants.BUTTON.DELETE,
            Icon:DeleteIcon,
            onClick: DeleteActivityConfirm,
            disabled: processedLaborOrders.length ===0
        },
        {
            id: "7",
            Title: constants.BUTTON.MARK_FULFILLED,
            Icon:CheckCircleSharp,
            onClick: FulfillActivityConfirm,
            disabled: processedLaborOrders.length ===0
        }
    ]

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            handleSearchClick();
        }
    }

    let validFiltersCount = Object.values(externalFiltersConfig).filter(
        (filterItem) => (filterItem && filterItem !== "" && filterItem.length>0)
    ).length-1;

    return (
        <div className="amz_content_view">
            <Container id="amz_laborOrderJsx" style={{maxWidth:"100%",margin:"0"}}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="mb-15">

                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                        {validFiltersCount > 0 && (
                            <ButtonPrimary
                                Title={`${constants.BUTTON.FILTER} (${
                                    validFiltersCount > 0 ? validFiltersCount : ""
                                })`}
                                onClick={toggleFilters}
                                ButtonIcon={<FilterListRoundedIcon />}
                            />
                        )}
                        {!validFiltersCount > 0 && (
                            <ButtonSecondary
                                Title={`${constants.BUTTON.FILTER} ${
                                    validFiltersCount > 0 ? validFiltersCount : ""
                                }`}
                                onClick={toggleFilters}
                                ButtonIcon={<FilterListRoundedIcon />}
                            />
                        )}
                    </Grid>

                    <Grid container item xs={12} sm={9} md={6} lg={6} xl={6}>
                        <Paper item xs={12} sm={12} md={12} lg={12} xl={12} component="form" className={classes.paper}>
                            <SearchIcon className={classes.iconButton} />
                            <InputBase
                                className={classes.input}
                                placeholder={constants.LABOR_ORDERS.SEARCH_PLACEHOLDER}
                                inputProps={constants.LABOR_ORDERS.SEARCH_PLACEHOLDER}
                                value={searchConfig.searchValue ? searchConfig.searchValue : ""}
                                onChange={handleSearchValueChange}
                                onKeyPress={handleKeyPress}
                            />
                            <Divider className={classes.divider} orientation="vertical" />
                            <ButtonSecondary Title="Search" onClick={handleSearchClick} />
                        </Paper>
                    </Grid>
                     <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                        <div className="form_page_actions">
                            <Demo isActionMenuOpen = {isActionMenuOpen} setIsActionMenuOpen={setIsActionMenuOpen} option={laborOrderActions}/>
                        </div>
                     </Grid>

                </Grid>
                <div className="d-flex">
                    <LaborOrdersGrid
                        View="page"
                        config={paginationConfig}
                        ref={gridRef}
                        downloadLaborOrderHandler={setLaborOrders}
                        updateFilter={updateFiltersFromResponse}
                        setSelectedLaborOrdersList = {setSelectedLaborOrdersList}
                    />
                </div>
            </Container>
            {showFormFilterDialog && (
                <LaborOrdersFilterDialog
                    filterData={externalFiltersConfig}
                    handleApply={applyFilters}
                    handleCancel={() => {
                        setShowFormFilterDialog(false);
                    }}
                />
            )}
            {
                showInactiveConfirmation &&
            <FormActionAlertDialog Message="Please confirm to mark the selected Labor Orders Inactive. This action may have implications to associated Requisitions" handleYes={()=>BulkUpdateActivity(constants.STATUS.INACTIVE)}  handleNo={() => { setShowInactiveConfirmation(false);setIsActionMenuOpen(false) }} />
            }
            {
                showDeleteConfirmation &&
                <FormActionAlertDialog Message="Please confirm to delete the selected Labor Orders. This action may have implications to associated Requisitions" handleYes={()=>BulkUpdateActivity(constants.STATUS.DELETED)}  handleNo={() => { setShowDeleteConfirmation(false);setIsActionMenuOpen(false) }} />
            }
            {
                showFulfillConfirmation &&
                <FormActionAlertDialog Message="Please confirm to mark the selected Labor Orders Fulfilled. This action may have implications to associated Requisitions" handleYes={()=>BulkUpdateActivity(constants.STATUS.FULFILLED)}  handleNo={() => { setShowFulfillConfirmation(false);setIsActionMenuOpen(false) }} />
            }
            {
                showSelectBulkUploadType && !isMenaUser() && (
                    <BulkUploadSelectionDialog
                        handleCancel = {() => {
                            setShowSelectBulkUploadType(false)
                        }}
                        handleAMZLSelection = {() => {
                            setShowSelectBulkUploadType(false)
                            setShowBulkUploadDialog(true)
                            setBulkUploadType(constants.LO_LR_BULK_UPLOAD.AMZL_SITE_TYPE)
                        }}
                        handleOtherSelection = {() => {
                            setShowSelectBulkUploadType(false)
                            setShowBulkUploadDialog(true)
                            setBulkUploadType(constants.LO_LR_BULK_UPLOAD.OTHER_SITE_TITLE)
                        }}
                        handleMenaSelection = {() => {
                            setShowSelectBulkUploadType(false)
                            setShowBulkUploadDialog(true)
                            setBulkUploadType(constants.LO_LR_BULK_UPLOAD.MENA_BUSINESS_LINES)
                        }}
                    />
                        )
            }
            {
                showSelectBulkUploadType && isMenaUser() && menaBulkUpload()
            }


            {showBulkUploadDialog && (
                <BulkUploadDialog
                    handleCancel={() => {
                        setShowBulkUploadDialog(false);
                    }}
                    bulkUploadType = {bulkUploadType}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
            {showCreateRLCFlyout &&
                <CreateRLCModal LaborOrderIds={processedLaborOrders} site={[...new Set(selectedLaborOrdersList.filter((laborOrder) => laborOrder.is_selected).map((obj)=>obj.Site))]} isActive={showCreateRLCFlyout} onSuccess={()=>window.location.reload ()} onClose={()=>setShowCreateRLCFlyout(false)}/>
            }
        </div>
    );
}

export default withAlertSnackBar(LaborOrders);