import React from 'react'
import { useLocation } from "react-router-dom";
import {Alert} from "@mui/material";

function PageNotFound() {
    let location = useLocation();
    return (
        <div>
            <Alert variant="filled" severity="error">Sorry about that, Requested resource {location.pathname} doesn't exist!</Alert>
        </div>
    )
}

export default PageNotFound
