import React from 'react';
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {KeyboardArrowUp} from "@mui/icons-material";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import {Add, Mail} from "@material-ui/icons";
import {List} from "@material-ui/core";
import {testPattern} from "../../Utils/PatternUtil";
import constants from "../../Utils/Constants";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";

const watcherStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        borderRadius: "5em",
        textTransform: "none",
    },
    watcherInput : {
        margin: "15px",
    },
    icon: {
        minWidth: '5px',
    }
}));

export function WatchersDropdownMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [watcherInput, setWatcherInput] = React.useState("");
    const [watcherInputError, setWatcherInputError] = React.useState(false);
    const [watcherLabel, setWatcherLabel] = React.useState(constants.WATCHER.DEFAULT_MESSAGES.WATCHER_LABEL);
    const [watcherHelperText, setWatcherHelperText] = React.useState(constants.WATCHER.DEFAULT_MESSAGES.WATCHER_HELPER_TEXT);

    const openWatcherPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeWatcherPopover = () => {
        setAnchorEl(null);
    };

    const handleWatcherChange = (event) => {
        setWatcherInput(event.target.value);
    }

    const validateErrorMessage = (watcherInput) => {
        if (!testPattern(watcherInput, new RegExp("^[a-z]([a-z0-9]){2,7}$"))) {
            return constants.WATCHER.ERROR_MESSAGES.PATTERN_MISMATCH;
        } else if(props.watchersList.includes(watcherInput + constants.WATCHER.EMAIL_SUFFIX)) {
            return constants.WATCHER.ERROR_MESSAGES.WATCHER_ALREADY_PRESENT;
        }
        return null;
    }

    const validateWatcherInput = (watcherInput) => {
        const errorMessage = validateErrorMessage(watcherInput);
        if (errorMessage) {
            setWatcherLabel(constants.WATCHER.ERROR_MESSAGES.WATCHER_LABEL);
            setWatcherInputError(true);
            setWatcherHelperText(errorMessage);
            return false;
        }
        //Success Case
        setWatcherLabel(constants.WATCHER.DEFAULT_MESSAGES.WATCHER_LABEL);
        setWatcherInputError(false);
        setWatcherHelperText(constants.WATCHER.DEFAULT_MESSAGES.WATCHER_HELPER_TEXT)
        return true;
    }

    const updateWatcherRow = async (updatedWatcherList) => {
        let isWatcherUpdateSuccess = true;
        if(props.isUpdateRequired){
            await props.updateWatcher(updatedWatcherList).catch(()=>{
                props.snackbarShowMessage(`${constants.WATCHER.ERROR_MESSAGES.WATCHER_UPDATE_FAILED}`,
                "error", "3000");
                isWatcherUpdateSuccess = false});
        }
        return isWatcherUpdateSuccess;
    }

    const addWatcher = async (watcherInput) => {
        if (validateWatcherInput(watcherInput)) {
            let newWatcher = watcherInput + constants.WATCHER.EMAIL_SUFFIX; //adding amazon.com to alias
            const updatedWatcherList = [newWatcher, ...props.watchersList];
            const isWatcherListUpdated = await updateWatcherRow(updatedWatcherList);
            if (isWatcherListUpdated) {  //check if network call is success
                props.setWatchersList(updatedWatcherList);
                setWatcherInput("");
            }
        }
    }

    const removeWatcher = async (selectedWatcher) => {
        const updatedWatcherList = props.watchersList.filter((watcher) => watcher !== selectedWatcher);
        const isWatcherListUpdated = await updateWatcherRow(updatedWatcherList);
        if (isWatcherListUpdated) { //check if network call succeeds
            props.setWatchersList(updatedWatcherList)
        }
    }

    return (
        <div>
            <Button
                className={watcherStyles().button}
                variant="contained"
                color="primary"
                size="large"
                onClick={openWatcherPopover}
                endIcon={Boolean(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                disabled={props.isWatcherDisabled ? props.isWatcherDisabled : false}
            >
                Watchers
            </Button>
            <Popover
                id="watchers-menu"
                className={watcherStyles().popover}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeWatcherPopover}
                elevation={8}
            anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{width: "300px"}}>
                    <div className={watcherStyles().watcherInput}>
                        <TextField helperText={watcherHelperText} id="watcherInput" label={watcherLabel}
                                   value={watcherInput} onChange={handleWatcherChange} error={watcherInputError}/>
                        <IconButton aria-label="add" onClick={()=>addWatcher(watcherInput)}>
                            <Add/>
                        </IconButton>
                    </div>
                        <List>
                        {props.watchersList.map((watcher) => {
                            return (
                                    <ListItem
                                      key={watcher}
                                      disablePadding={true}
                                      dense={true}
                                      secondaryAction={
                                          <IconButton edge="end" aria-label="delete" onClick={()=>removeWatcher(watcher)}>
                                              <CloseIcon/>
                                          </IconButton>
                                      }
                                    >
                                        <ListItemButton sx={{ maxWidth: "50px"}}>
                                            <ListItemIcon sx={{ minWidth: "30px" }}>
                                                <Mail/>
                                            </ListItemIcon>
                                            <ListItemText primary={watcher} sx={{ maxWidth: "50px" }}/>
                                        </ListItemButton>
                                    </ListItem>
                            )})}
                        </List>
                    </div>
            </Popover>
        </div>
    );
}

export default withAlertSnackBar(WatchersDropdownMenu);
