import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

function InfoHelpIcon(props) {
  return (
    <Tooltip title={props.FieldToolTip} placement="top" tabIndex="-1">
      <IconButton size="small" aria-label={props.Title}>
        <Icon>{props.IconClassName}</Icon>
      </IconButton>
    </Tooltip>
  );
}

export default InfoHelpIcon;
