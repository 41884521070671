import React, { Component } from 'react'
import { NavLink } from "react-router-dom";

class TabMain extends Component {
     onPaths(paths) {
        return (match, location) => {
            return paths.includes(location.pathname);
        };
    }

   setSelected = (tab) => {
      this.setState({ selected: tab});
  }

    render() {
    return (
        <div>
           <ul className='amz-tabs'>
               <NavLink className="amz-tab-item" activeClassName="active" to="/laborOrders" isActive={this.onPaths(["/", "/laborOrders"])}><li className='amz-tab-link'>Labor Orders</li></NavLink>
               <NavLink className="amz-tab-item" activeClassName="active" to="/requisitions"><li className='amz-tab-link'>Requisitions</li></NavLink>
               <NavLink className="amz-tab-item" activeClassName="active" to="/reports"><li className='amz-tab-link'>Reports</li></NavLink>
               <NavLink className="amz-tab-item" activeClassName="active" to="/candidate-management"><li className='amz-tab-link'>Candidates</li></NavLink>
           </ul>
        </div>
    );
}
}
export default TabMain
