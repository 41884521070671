import React, {useEffect, useState} from 'react'
import constants, {AuthorizationType} from '../../Utils/Constants';
import {customSortRequisition} from "../../Utils/customSort";
import Divider from "@material-ui/core/Divider";
import {Card, CardActionArea, CardContent} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useQueryClient} from "react-query";
import {useGetAllRequisitions} from "../../ReactQuery/hooks/useGetAllRequisitions";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import UnauthorizedAccessDialog from "../PageNotFound/UnauthorizedAccessDialog";
import {disablePastDate} from "../../Utils/DateUtil";
import {searchRequisitionByID} from "../../Utils/RequisitionGridOperations";

const useStyles = makeStyles((theme) => ({
    gridBackground:{
        overflowY: "auto"
    },
    gridCard: {
        backgroundColor: "#232f3e",
        height: "84.4vh",
        maxWidth: "20%"
    },
    rightArrowButton:{
        position: "absolute",
        right: "5%"
    },
    divider:{
        width: "100%",
        height:"0",
        border: "1px solid #D1D5DA"
    },
    verticalDivider:{
        height:"86px",
        margin: "auto 0",
        border: "1.2px solid #A2ABB5"
    },
    text:{
        margin: "auto 1em"
    },
    jobRoleCss: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width:"58%",
        "&:hover":{
            textOverflow:"none",
            whiteSpace:"normal"
        }
    }
}));

function RequisitionsVerticalGrid(props, ref) {

    const [rowData, setRowData] = React.useState([]);
    const [paginationConfig, setPaginationConfig] = React.useState(props.config);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    const [authType, setAuthType] = React.useState("");
    const [selectedVerticalReqCardId,setSelectedVerticalReqCardId] = React.useState("");
    const [requestBody, setRequestBody] = useState(null)
    const classes = useStyles();
    const queryClient = useQueryClient()
    const {data: queryRequisitions, error: errorGetAllRequisitions, isFetching:requisitionsLoading} = useGetAllRequisitions(requestBody)


    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const handleExternalFilters = (filters) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.externalFilters = filters;
        paginationConfigL.externalFiltersApplied = props.config.externalFiltersApplied;
        setPaginationConfig(paginationConfigL);
    }

    useEffect(() =>{
        if(props.config.laborOrderId && props.config.requisitionId){
            setSelectedVerticalReqCardId(props.config.requisitionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.config.laborOrderId,props.config.requisitionId]);

    useEffect(() => {
        if (paginationConfig && paginationConfig.pageSize) {
            operateOnRows()
        }
        // eslint-disable-next-line
    }, [paginationConfig, globalRows])

    useEffect(() => {
        if ((props.searchConfig.searchValue && props.searchConfig.searchValue!=="") || props.searchConfig.searchValue==='') {
            operateOnRows();
        }
        // eslint-disable-next-line
    }, [props.searchConfig.searchValue])

    useEffect(() => {
        if ((props.config.externalFilters)) {
            handleExternalFilters(props.config.externalFilters)
        }
        if(props.config.externalFiltersApplied || props.config.externalFilters.resetAppliedFilter) {
            getData ();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.externalFilters])

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData = searchRequisitionByID(rowsData, props.searchConfig.searchValue);
        setRowData(rowsData);
    }

    const getData = () => {
        let body = {
            laborOrderId: props.LOid,
            alias: JSON.parse(sessionStorage.getItem('session_user')).id,
            filterBy: "LR",
        };
        if(!props.config.externalFiltersApplied){
            body.resetAppliedFilter = props.config.externalFilters.resetAppliedFilter;
        }
        else {
            body = {...body,...props.config.externalFilters}
        }
        setRequestBody(body)
    };


    useEffect(() => {
        setRowData([])
    },[requestBody])

    useEffect(() => {
        if(errorGetAllRequisitions){
            if ( errorGetAllRequisitions.data && (errorGetAllRequisitions.data.message === constants.ERROR.NO_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.SITE_BASED);
            }
            else if ( errorGetAllRequisitions.data && (errorGetAllRequisitions.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
                setShowUnauthorizedDialog(true);
                setAuthType(AuthorizationType.BRASS)
            }
            else if (errorGetAllRequisitions.data && (errorGetAllRequisitions.data.statusCode === constants.ERROR.CODE.PAYLOAD_TOO_LARGE)){
                props.snackbarShowMessage(
                    `${constants.ERROR.FETCHING_ERROR} ${errorGetAllRequisitions.data.message}`,
                    "error",
                    "4000",
                    ""
                );
            }
            else if (errorGetAllRequisitions.data && (errorGetAllRequisitions.data.statusCode === constants.ERROR.CODE.GATEWAY_TIMEOUT)){
                props.snackbarShowMessage(
                    `${constants.ERROR.FETCHING_ERROR} ${constants.ERROR.BACKEND_TIMEOUT_ERROR}`,
                    "error",
                    "4000",
                    ""
                );
            }
            setRowData([]);
        }
        else{
            const queryData = queryClient.queryCache
                .findAll(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                .sort((a, b) => b.state.dataUpdatedAt - a.state.dataUpdatedAt)[0].state.data
            let rows = queryData && queryData.data && queryData.data.requisitionInfos;
            let filters = queryData && queryData.data && queryData.data.filterData;
            if(!requisitionsLoading)
                populateGrid(rows, filters)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[queryRequisitions, errorGetAllRequisitions])

    const populateGrid = (rows, filters) => {
        let rowsData=[];
        if (rows) {
            rows.forEach(row => {
                    rowsData.push ({
                        laborOrderId: row.laborOrderId,
                        requisitionId: row.requisitionId,
                        sites: [row.site],
                        site: row.site,
                        candidateCount: row.candidateCount,
                        jobRole: row.jobRole,
                        businessLine: row.businessLine,
                        id: row.requisitionId,
                        status: row.requisitionStatus ? (row.requisitionStatus === constants.STATUS.WIP_DB ? constants.STATUS.WIP_FRONTEND : row.requisitionStatus) : "",
                        startDate: row.onboardingPhaseDetails[0] && row.onboardingPhaseDetails[0].startDate ? row.onboardingPhaseDetails[0].startDate : "",
                        statusPreferenceCode: constants.STATUS_PREFERENCE_CODES[row.requisitionStatus]
                    });
            });
            setRowData(customSortRequisition(rowsData));
            setGlobalRows(rowsData);
            if(filters){
                props.updateFilter(filters);
            }
            else{
                let externalFilters = {}
                externalFilters.jobRoles=[];
                externalFilters.vendorId='';
                externalFilters.status=["OPEN","ACCEPTED","WORK_IN_PROGRESS","DECLINED"];
                externalFilters.sites = [];
                externalFilters.startDate = disablePastDate(new Date(),-7);
                externalFilters.endDate = disablePastDate(new Date(), 21);
                externalFilters.startHiringWeek = "";
                externalFilters.endHiringWeek = "";
                externalFilters.resetAppliedFilter = true;
                props.updateFilter(externalFilters)
            }
            setRowData(customSortRequisition(rowsData));
            setGlobalRows(rowsData);
        }
    }

    const requisitionSelectActivity =(e,requisition) => {
        setSelectedVerticalReqCardId(requisition.requisitionId);
        props.updateCandidateGridBySelectedRequistion(requisition);
    }

    return (
            <Grid
            container
            direction="column"
            justifyContent="left"
            alignItems="left"
            spacing={0}>
            {!requisitionsLoading && rowData.map((requisition)=>{ return (
                <CardActionArea onClick={ (e)=> requisitionSelectActivity(e,requisition)}>
                    <Grid key ={requisition.requisitionId} container direction="row" style={{backgroundColor: selectedVerticalReqCardId===requisition.requisitionId ? "#D1D5DA" : "#F1F4F7",borderLeft: selectedVerticalReqCardId===requisition.requisitionId ? "4px solid #006170":""}}>
                        <Grid id="amz_requisitionVerticalGridOptionItem" container direction="column" style={{padding:"8px",width:"24%",fontSize:"15px"}} alignItems="center" justifyContent="center">
                            <Typography sx={{ fontSize: 16 }} style={{fontWeight:"bold",fontSize:"22px"}}>
                                {requisition.candidateCount}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} style={{color:"#56616C",fontSize:"12px",marginTop:"-6px"}}>
                                Candidates
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} style={{fontWeight:"bold",fontSize:"17px"}}>
                                {requisition.businessLine}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} style={{color:"#56616C",fontSize:"13px",marginTop:"-2px"}}>
                                {requisition.site}
                            </Typography>
                        </Grid>
                        <Divider id="amz_requisitionVerticalGridDivider" className={classes.verticalDivider} orientation="vertical"/>
                        <Card id="amz_requisitionVerticalCard" style={{border: "none",backgroundColor:"transparent",width:"calc(75% - 1.2px)"}} variant="outlined">
                            <CardContent style={{padding:"8px"}}>
                                <Grid id="amz_requisitionVerticalGridOptionItem" container direction="row"  justifyContent="space-between"  >
                                    <Typography sx={{ fontSize: 16 }} style={{fontSize:"1.1em"}} >
                                        {requisition.requisitionId}
                                    </Typography>
                                    <Typography sx={{ fontSize: 16 }} style={{fontSize:"1em"}} >
                                        {requisition.status}
                                    </Typography>
                                </Grid>
                                <Grid id="amz_requisitionVerticalGridOptionItem" container direction="row" justifyContent="flex-start">
                                    <Typography sx={{ fontSize: 14 }} style={{fontSize:"1.1em"}}  >
                                        {requisition.laborOrderId}
                                    </Typography>
                                </Grid>
                                <Grid id="amz_requisitionVerticalGridOptionItem" container direction="row">
                                    <KeyboardArrowRightIcon className={classes.rightArrowButton}/>
                                </Grid>
                                <Grid  id="amz_requisitionVerticalGridOptionItem" container direction="row" justifyContent="flex-start">
                                    <Typography sx={{ fontSize: 14 }} style={{fontSize:"1.1em"}} >
                                        Need By Date :&nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} style={{fontSize:"1.1em"}}  >
                                        {requisition.startDate}
                                    </Typography>
                                </Grid>
                                <Grid id="amz_requisitionVerticalGridOptionItem" container direction="row" justifyContent="flex-start">
                                    <Typography sx={{ fontSize: 14 }} style={{fontSize:"1.1em"}}  >
                                        Job Role :&nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} style={{fontSize:"1.1em"}}  className={classes.jobRoleCss} >
                                        {requisition.jobRole}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Divider id="amz_requisitionVerticalGridDivider" className={classes.divider}/>
                    </Grid>
                </CardActionArea>
            );
            })}
            <Grid id="amz_requisitionVerticalGridNoOption" container style={{height:"45vh"}}>
                {requisitionsLoading ? <div style={{margin:"auto"}}><CircularProgress/></div> :
                    rowData.length===0 && <Typography className={classes.text} variant="caption" style={{margin:"auto"}}>No Related Requisitions...</Typography>}
            </Grid>
                {showUnauthorizedDialog && (
                    <UnauthorizedAccessDialog
                        handleCancel={() => {
                            setShowUnauthorizedDialog(false);
                        }}
                        authorizationType={authType}
                        snackbarShowMessage={props.snackbarShowMessage}
                    />
                )}
        </Grid>
    )
}

export default withAlertSnackBar(RequisitionsVerticalGrid)