import React from "react";
import TabMain from "../TabBar/TabMain";

function ContentView(){
    return (
        <div>
            <TabMain />
        </div>
    )
}

export default ContentView