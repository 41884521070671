import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Dialog, Grid} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import constants from "../../Utils/Constants";
import {isMenaUser} from "../../Utils/RegionUtil";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function BulkUploadSelectionDialog(props){
    const theme = useTheme();
    const classes = useStyles();
    useMediaQuery(theme.breakpoints.down('md'));

    const handleCancel = () => {
        props.handleCancel();
    }

    return (
        <div style={{minWidth: '150px'}}>
            <Dialog
    style={{zIndex: '100'}}
    open={true}>
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Container >
                        <Typography variant="h5" style = {{marginRight: "30px"}}>{"Select Business Line for Bulk Upload of LOs and LRs"}</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Container>
                </MuiDialogTitle>
                <DialogActions>
                    <Grid container justifyContent={"space-around"} alignItems="center" className="form_group_item">{
                        isMenaUser()?<ButtonPrimary variant="outlined" Title={constants.LO_LR_BULK_UPLOAD.MENA_BUSINESS_LINES} autoFocus onClick={props.handleMenaSelection} color="primary" />:
                            <Grid>
                                <ButtonPrimary variant="outlined" Title={constants.LO_LR_BULK_UPLOAD.AMZL_SITE_TITLE} autoFocus onClick={props.handleAMZLSelection} color="primary" />
                                <ButtonPrimary variant="outlined" Title={constants.LO_LR_BULK_UPLOAD.OTHER_SITE_TITLE} autoFocus onClick={props.handleOtherSelection} color="primary" />
                            </Grid>
                    }
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>)
}

export default withAlertSnackBar(BulkUploadSelectionDialog)