import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";
import {isUserMultiRegion} from "../../Utils/RegionUtil";

export const useGetAllLaborOrdersWithoutFilters= (isEnabled) => {

    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS], () => ActivityServices.getTotalActivitiesWithFilters(), {
        refetchOnWindowFocus: false,
        enabled: !isUserMultiRegion() && isEnabled
    })
}