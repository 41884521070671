import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import InputLabel from "../../components/FormPage/InputLabel";
import Formfieldstar from "../../components/FormPage/FormFieldStar";
import InputFile from "../../components/FormPage/Inputfile";
import constants  from "../../Utils/Constants";
import menaConstants from "../../Utils/MenaConstants";
import readXlsxFile from 'read-excel-file';
import { parse } from 'json2csv';
import {UploadDownloadS3File} from "../../Services/upload_download_S3file";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import xlsx from 'json-as-xlsx';
import excel from '../../assets/images/excel.svg'
import {useQueryClient} from "react-query";
import {isMenaUser} from "../../Utils/RegionUtil";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function BulkUploadDialog(props) {
    const theme = useTheme();
    useMediaQuery(theme.breakpoints.down('md'));
    const [bulkUploadFile, setBulkUploadFile] = React.useState(false);
    const classes = useStyles();
    const queryClient = useQueryClient()

    const uploadTemplate = async () => {

        const file = bulkUploadFile;

        if(file){
            if(props.bulkUploadType === constants.LO_LR_BULK_UPLOAD.OTHER_SITE_TITLE || props.bulkUploadType === constants.LO_LR_BULK_UPLOAD.MENA_BUSINESS_LINES){
                const isMena = props.bulkUploadType === constants.LO_LR_BULK_UPLOAD.MENA_BUSINESS_LINES;
                const LOSchema = isMena?  menaConstants.LO_DATA_SCHEMA_EXCEL_TO_JSON: constants.LO_DATA_SCHEMA_EXCEL_TO_JSON;
                const LRSchema = isMena?  menaConstants.LR_DATA_SCHEMA_EXCEL_TO_JSON: constants.LR_DATA_SCHEMA_EXCEL_TO_JSON;
                await readXlsxFile(file, {sheet : 1, schema: LOSchema})
                    .then( async (data) => {
                        if(data.rows.length === 0 || data.errors.length !== 0){
                            throw new Error(constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.EXCEL_ERROR + " in LO sheet")
                        }
                        const LOBulkUploadExcelFields = isMena? menaConstants.LO_BULK_UPLOAD_EXCEL_FIELDS: constants.LO_BULK_UPLOAD_EXCEL_FIELDS;
                        const LOCsv = await parse(data.rows, { LOBulkUploadExcelFields });
                        const LOFileName = constants.LO_LR_BULK_UPLOAD.LO_FILE;
                        const LOBlob = new Blob([LOCsv], { type: constants.LO_LR_BULK_UPLOAD.TEXT_CSV_FILE_TYPE });
                        await readXlsxFile(file, {sheet : 2, schema: LRSchema})
                            .then(async (data) => {
                                if(data.errors.length !== 0){
                                    throw new Error(constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.EXCEL_ERROR + " in LR sheet")
                                }
                                const LRBulkUploadExcelFields = isMena? menaConstants.LR_BULK_UPLOAD_EXCEL_FIELDS: constants.LR_BULK_UPLOAD_EXCEL_FIELDS;
                                const LRCsv = data.rows.length > 0 ? await parse(data.rows, { LRBulkUploadExcelFields }) : null;
                                const LRFileName = constants.LO_LR_BULK_UPLOAD.LR_FILE;
                                const LRBlob = new Blob([LRCsv], { type: constants.LO_LR_BULK_UPLOAD.TEXT_CSV_FILE_TYPE });
                                const isAmzlBlob = new Blob(["false"], {type: constants.LO_LR_BULK_UPLOAD.TEXT_CSV_FILE_TYPE});
                                const formDataRequest = new FormData();
                                formDataRequest.append("files", LOBlob , LOFileName);
                                formDataRequest.append("files", LRBlob , LRFileName);
                                formDataRequest.append("files",isAmzlBlob,constants.LO_LR_BULK_UPLOAD.IS_AMZL_FILE_NAME);
                                await UploadDownloadS3File.uploadConstruct(formDataRequest)
                                    .then(async(data)=>{
                                        if(data.data.error){
                                            props.snackbarShowMessage(`${data.data.error}`,"error");
                                        }else{
                                            if(data.data.success) {
                                                queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                                                queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                                                const loSchema = isMena? menaConstants.LO_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS: constants.LO_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS;
                                                const lrSchema = isMena? menaConstants.LR_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS: constants.LR_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS;
                                                let xlsxData = [
                                                    {
                                                        sheet: "LO",
                                                        columns: loSchema,
                                                        content: data.data.loResponse
                                                    },
                                                    {
                                                        sheet: "LR",
                                                        columns: lrSchema,
                                                        content: data.data.lrResponse
                                                    }
                                                ]

                                                let settings = {
                                                    fileName: "LO_LR_BulkUploadResponse",
                                                    extraLength: 3,
                                                    writeOptions: {}
                                                }
                                                await xlsx(xlsxData, settings)

                                                data.data.duplicates ? props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.FILE_UPLOAD_WITH_DUPLICATES}`, "success","3000") : props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.FILE_UPLOAD_SUCCESS}`, "success","3000");
                                            }
                                            else {
                                                const loSchema = isMena?  menaConstants.LO_DATA_SCHEMA_JSON_TO_EXCEL_ERROR:constants.LO_DATA_SCHEMA_JSON_TO_EXCEL_ERROR;
                                                const lrSchema = isMena? menaConstants.LR_DATA_SCHEMA_JSON_TO_EXCEL_ERROR: constants.LR_DATA_SCHEMA_JSON_TO_EXCEL_ERROR;
                                                let xlsxData = [
                                                    {
                                                        sheet: "LO",
                                                        columns: loSchema,
                                                        content: data.data.loResponse
                                                    },
                                                    {
                                                        sheet: "LR",
                                                        columns: lrSchema,
                                                        content: data.data.lrResponse
                                                    }
                                                ]

                                                let settings = {
                                                    fileName: "LO_LR_BulkUploadErrorResponse",
                                                    extraLength: 3,
                                                    writeOptions: {}
                                                }

                                                await xlsx(xlsxData, settings)
                                                props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_ERRORS}`,"error","3000");
                                            }
                                        }
                                    })
                                    .catch(async (error) => {
                                        props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_UPLOAD_ERROR}`,"error","3000");
                                    })
                            })
                            .catch((error)=>{
                                props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_UPLOAD_ERROR}`,"error","3000");
                            });
                    })
                    .catch((error)=>{
                        props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_UPLOAD_ERROR}`,"error","3000");
                    });
            }
            else{
                const LOLRschema  = constants.LO_LR_AMZL_DATA_SCHEMA_EXCEL_TO_JSON
                await readXlsxFile(file, {sheet : 1, schema: LOLRschema})
                    .then( async (data) => {
                        if (data.rows.length === 0 || data.errors.length !== 0) {
                            throw new Error(constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.EXCEL_ERROR + " in LO sheet")
                        }
                        const LOLRBulkUploadExcelFields = constants.LO_LR_AMZL_BULK_UPLOAD_EXCEL_FIELDS;
                        const LOCsv = await parse(data.rows, { LOLRBulkUploadExcelFields });
                        const LOFileName = constants.LO_LR_BULK_UPLOAD.LO_LR_AMZL_FILE;
                        const LOBlob = new Blob([LOCsv], { type: constants.LO_LR_BULK_UPLOAD.TEXT_CSV_FILE_TYPE });
                        const isAmzlBlob = new Blob(["true"], {type: constants.LO_LR_BULK_UPLOAD.TEXT_CSV_FILE_TYPE});
                        const formDataRequest = new FormData();
                        formDataRequest.append("files", LOBlob , LOFileName);
                        formDataRequest.append("files", LOBlob , LOFileName);
                        formDataRequest.append("files",isAmzlBlob, constants.LO_LR_BULK_UPLOAD.IS_AMZL_FILE_NAME);
                        await UploadDownloadS3File.uploadConstruct(formDataRequest)
                            .then(async(data)=>{
                                if(data.data.error){
                                    props.snackbarShowMessage(`${data.data.error}`,"error");
                                }else{
                                    if(data.data.success) {
                                        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_LABORORDERS)
                                        queryClient.invalidateQueries(constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS)
                                        const loSchema = constants.LO_LR_DATA_SCHEMA_JSON_TO_EXCEL_SUCCESS;
                                        let xlsxData = [
                                            {
                                                sheet: "LO_LR",
                                                columns: loSchema,
                                                content: data.data.loResponse
                                            }
                                        ]

                                        let settings = {
                                            fileName: "LO_LR_BulkUploadResponse",
                                            extraLength: 3,
                                            writeOptions: {}
                                        }
                                        await xlsx(xlsxData, settings)

                                        data.data.duplicates ? props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.FILE_UPLOAD_WITH_DUPLICATES}`, "success","3000") : props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.FILE_UPLOAD_SUCCESS}`, "success","3000");
                                    }
                                    else {
                                        const loSchema = constants.LO_LR_DATA_SCHEMA_JSON_TO_EXCEL_ERROR;
                                        let xlsxData = [
                                            {
                                                sheet: "LO_LR",
                                                columns: loSchema,
                                                content: data.data.loResponse
                                            }
                                        ]

                                        let settings = {
                                            fileName: "LO_LR_BulkUploadErrorResponse",
                                            extraLength: 3,
                                            writeOptions: {}
                                        }

                                        await xlsx(xlsxData, settings)
                                        props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_ERRORS}`,"error","3000");
                                    }
                                }
                            })
                            .catch(async (error) => {
                                props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_UPLOAD_ERROR}`,"error","3000");
                            })
                            .catch(async (error) => {
                                props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_UPLOAD_ERROR}`,"error","3000");
                            })
                    })

            }

            window.setTimeout(() => {
                handleCancel()
            }, "3000")
            setBulkUploadFile(null);
            document.getElementById("uploadLOLRFileInput").value = "";

        }else{
            props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_UPLOAD_ERROR}`,"error","3000");
        }
    }

    const handleCancel = () => {
        props.handleCancel();
    }

    async function chooseFile (file) {
        let uploadFile = file.target.files[0];
        const allowedFileType = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
        if(allowedFileType.indexOf(uploadFile.type)>=0){
            setBulkUploadFile(uploadFile) ;
        }else{
            props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_FORMAT_ERROR}`,"error");
            document.getElementById("uploadLOLRFileInput").value = "";
        }
    }
    const downloadTemplate = async () => {
        await UploadDownloadS3File.downloadConstruct(isMenaUser()?constants.LO_LR_BULK_UPLOAD.LO_LR_MENA_TEMPLATE:props.bulkUploadType=== constants.LO_LR_BULK_UPLOAD.AMZL_SITE_TYPE ?constants.LO_LR_BULK_UPLOAD.LO_LR_AMZL_TEMPLATE:constants.LO_LR_BULK_UPLOAD.LO_LR_TEMPLATE)
            .then((data) => {
                props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.FILE_DOWNLOAD_SUCCESS}`);
                let link = document.createElement('a');
                link.download = data.config.data.replace(/[{}"]+/g, "").split(":")[1];
                link.href = data.data.fileSignedLink;
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    document.body.removeChild(link);
                }, 200);
            })
            .catch((error) => {
                props.snackbarShowMessage(`${constants.LO_LR_BULK_UPLOAD.ERROR_MESSAGES.FILE_DOWNLOAD_ERROR}`, "error");
            })
    }

    return (
        <div style={{minWidth: '50px'}}>
            <Dialog
                style={{zIndex:'100'}}
                open={true}>
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h5" style = {{marginLeft: "40px"}}>{`Bulk Upload of ${props.bulkUploadType } LOs and LRs`}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container alignItems="top" className="form_group_list">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="1) Download Template:" />
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <img src={excel} alt={"Download Template"} onClick={downloadTemplate} style={{cursor: 'pointer'}}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="2) Choose a file:" />
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <InputFile id={'uploadLOLRFileInput'} type={'file'} onChange={chooseFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonPrimary variant="outlined" Title={"Upload"} autoFocus onClick={uploadTemplate} color="primary" />
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withAlertSnackBar(BulkUploadDialog);