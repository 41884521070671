import { eventTaskAxios, siteAxios} from '../api/axios'

export const MasterServices = {
    getOrgStatuses,
    getOrgNodes,
    getOrgTypes,
    getOrgVenueCapacity,
    getOrgContractstatus,
    getAllUsers,
    getTaskPriorities,
    getAllOrganizationsPicklist,
    getActivityFormat,
    getActivityType,
    getActivityStatus,
    postActivityForOrgs,
    postNodesForOrgs,
    postContactsForOrgs,
    getReportTypes,
    getActivityTextCodes,
    getVendorByActivity,
    getAllSites,
}

function getOrgStatuses() {
    return eventTaskAxios.get('/picklist?type=ORG_STATUS')

}

function getOrgNodes() {
    return eventTaskAxios.get('/picklist?type=ORG_NODE')
}

function getOrgTypes() {
    return eventTaskAxios.get('/picklist?type=ORG_TYPE')
}

function getOrgVenueCapacity() {
    return eventTaskAxios.get('/picklist?type=VENUE_CAPACITY')
}
function getOrgContractstatus() {
    return eventTaskAxios.get('/picklist?type=ORG_RELATIONSHIP_HEALTH')
}

function getAllUsers() {
    return eventTaskAxios.get('/picklist?type=USER')
}
function getTaskPriorities() {
    return eventTaskAxios.get('/picklist?type=TASK_PRIORITY')
}
function getAllOrganizationsPicklist() {
    return eventTaskAxios.post('/orgs/filter', {
        "sortBy": "id",
        "sortOrder": "DESC",
        "pageSize": 10,
        "pageNo": 1,
        "search": "",
        "type": "",
        "sites":""
    })
}
function getActivityFormat() {
    return eventTaskAxios.get('/picklist?type=EVENT_FORMAT')
}
function getActivityType() {
    return eventTaskAxios.get('/picklist?type=EVENT_TYPE')
}

function getActivityStatus() {
    return eventTaskAxios.get('/picklist?type=EVENT_STATUS')
}

function postActivityForOrgs(body={}) {
    return eventTaskAxios.post('/picklist?type=EVENTS_FOR_ORGS',body)
}

function postNodesForOrgs(body={}) {
    return eventTaskAxios.post('/picklist?type=NODES_FOR_ORGS', body)
}

function postContactsForOrgs(body={}) {
    return eventTaskAxios.post('/picklist?type=CONTACTS_FOR_ORGS',body)
}

function getReportTypes() {
    return eventTaskAxios.get('/picklist?type=REPORT_TYPE')
}

function getActivityTextCodes() {
    return eventTaskAxios.get('/picklist?type=ACTIVITY_TEXT_CODE')
}
function getVendorByActivity(id) {
    return eventTaskAxios.get('/events/orgsbyevent/' + id + '?pageNo=1&pageSize=10&sortBy=id&sortOrder=DESC');
}

function getAllSites() {
    return siteAxios.get('/dashboard/sites')
}