export function getRequisitionTabIndex(requisitions, index, offset) {
    let tabIndex = 1 + offset;
    for(let iterator = 0; iterator<index; iterator++) {
        tabIndex += (4 + (requisitions[iterator].OnboardingPhases.length * 5));
    }
    return tabIndex.toString();
}

export function getPhaseTabIndex(requisitions, requisitionIndex, phaseIndex, offset) {
    let tabIndex = 1 + offset;
    for(let iterator = 0; iterator<requisitionIndex; iterator++) {
        tabIndex += (4 + (requisitions[iterator].OnboardingPhases.length * 5));
    }
    tabIndex+=(4+5*(phaseIndex));
    return tabIndex.toString();
}

export function getSelected(array) {
    const selectedItem =  array.filter(item => item.is_selected);
    return selectedItem ? selectedItem[0] : "";
}

export function getApprovalURL(approvalId){
    const hostUrl = window.location.host;
    let approvalUrl = "";
    if(hostUrl!=='lorm.hiring.ap-south-1.hvh.a2z.com'){
        approvalUrl = "https://approvalstage.amazon.com/Approval/Details/" + approvalId;
    }else{
        approvalUrl = "https://approvals.amazon.com/Approval/Details/" + approvalId;
    }
    return approvalUrl;
}

export function getVendorsList(parentVendorList){
    let parentVendors = [];
    parentVendorList.forEach ((parentVendor) => {
        if(parentVendor.status === "ACTIVE"){
            parentVendors.push({
                id: parentVendor.vendorId,
                is_selected: false,
                name: parentVendor.vendorName
            });
        }
    });
    return parentVendors;
}

export function getUserCountry(){
    return sessionStorage.getItem("authorizedCountries").split(",")[0];
}

export function getOnboardingPhase(row,onboardingPhaseNumber){
    if(row.onboardingPhaseDetails.length > onboardingPhaseNumber)
        return row.onboardingPhaseDetails[onboardingPhaseNumber];
    return {
        general : "",
        startDate : "",
        onboardingShift : ""
    }
}