import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";
import {isUserMultiRegion} from "../../Utils/RegionUtil";

export const useGetRequisition= (loId,reqId) =>{

    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_REQUISITION,loId,reqId],()=> ActivityServices.getRequisitionById(loId,reqId),{
        refetchOnWindowFocus:false,
        enabled: !isUserMultiRegion()
    })
}