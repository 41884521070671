import {compare} from "./Comparator";

export function searchRequisition(requisitions,search){
    if(search) {
        return requisitions.filter(arr => arr.VendorName.toLowerCase().includes(search.toLowerCase()))
    }
    return requisitions
}

export function searchRequisitionByID(requisitions,search){
    if(search) {
        return requisitions.filter(arr => arr.requisitionId.toLowerCase().includes(search.toLowerCase()))
    }
    return requisitions
}

export function filterRequisitions(requisitions,jobType,vendor, status){
    if(jobType) {
        requisitions = requisitions.filter(arr => arr.jobType === jobType)
    }
    if(vendor) {
        requisitions = requisitions.filter(arr => arr.vendorName === vendor)
    }
    if(status){
        requisitions = requisitions.filter(arr => arr.status === status)
    }
    return requisitions
}

export function sortRequisitions(rows,sortColumn,order){
    if(sortColumn==="headCount")
        return rows.sort(compare("requisitionHeadCount",order))
    return rows.sort(compare(sortColumn,order))
}