import React from 'react'

  export function Formvalidationerror(props) {

      return (
          <p className='amz_field_error'><small>{props.fieldName} field is missing</small></p>
      )

}
    export function FormNonZerovalidationerror(props) {
        return (
            <p className='amz_field_error'><small>{props.fieldName} should be greater than zero</small></p>
        )
    }
    export function FormPastDatevalidationerror(props){
    return (
        <p className='amz_field_error'><small>{props.fieldName} cannot be past date</small></p>
    )
    }

    export function FormComparevalidationerror(props) {
        return (
            <p className='amz_field_error'><small>{props.fieldName} should be greater than head count</small></p>
        )
    }

