import React from 'react'

function Admin() {
    return (
        <div className='amz_content_view home_cecrm mb-100'>
            <div className="home_cecrm_upcoming">       
                <h3 class="form_page_title">Admin</h3>
            </div>
        </div>
    )
}

export default Admin
