import {eventTaskAxios} from '../api/axios'

export const UploadDownloadS3File = {
    downloadConstruct,
    uploadConstruct,
}

async function downloadConstruct(downloadTemplateName) {
    const body = {
        templateName : downloadTemplateName,
    };
    return await eventTaskAxios.post(`/S3/downloadConstruct`, body);
}

async function uploadConstruct(body) {
    return await eventTaskAxios.post(`/S3/uploadConstruct`, body);
}