import { useCallback, useEffect } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

/**
 * Syncs state with localStoage, providing real-time updates across tabs
 * @param {string} key - Key used to access local storage value
 * @param {any} initialValue - Initial value to use if no value in local storage with key
 * @returns [state, setState] - Returns the current state and a function to update the state
 */
export function useLocalStorage(key, initialValue) {
  const getSnapshot = () => window.localStorage.getItem(key);

  // Subscribe to changes in localStorage
  const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot, getLocalStorageServerSnapshot);

  // Function to update the state in localStorage
  const setState = useCallback(
    (v) => {
      try {
        // If v is a fn, call it with current state to get the next state
        const nextState = typeof v === 'function' ? v(JSON.parse(store)) : v;

        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, nextState);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, store]
  );

  // Sets initial value in localStorage, if it doesn't exist
  useEffect(() => {
    if (getLocalStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [store ? JSON.parse(store) : initialValue, setState];
}

/**
 * Dispatches custom event to notify other tabs/windows of changes
 * @param {string} key 
 * @param {string | null} newValue 
 */
function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

/**
 * Subscribes to storage events for real-time updates across tabs/windows
 * @param {function} callback - The callback function to be executed when local storage changes.
 * @param {Window} callback.this - The Window object as the 'this' context for the callback.
 * @param {StorageEvent} callback.ev - The StorageEvent object containing information about the change.
 */
const useLocalStorageSubscribe = (callback) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

/**
 * Throws an error if attempting to use localStorage on the server
 */
const getLocalStorageServerSnapshot = () => {
  throw Error('useLocalStorage is a client-only hook');
};

/**
 * Retrieves an item from localStorage
 * @param {string} key - Key in local storage to retrieve from
 */
const getLocalStorageItem = (key) => {
  return window.localStorage.getItem(key);
};

/**
 * Sets an item in localStorage and dispatches a storage event
 * @param {string} key - Key in local storage to set
 * @param {any} value - Value to store in local storage
 */
const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

/**
 * Removes an item from localStorage and dispatches a storage event
 * @param {string} key - Key in local storage to remove
 */
const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};
