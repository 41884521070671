import React from 'react'

function UserName(props) {
    return (
        <div>
            <h6>{props.username}</h6>
        </div>
    )
}

UserName.defaultProps = {
    username: 'username@',
}

export default UserName
