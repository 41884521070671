import constants from "./Constants";

export function isMenaUser(){
    let authorizedCountries = sessionStorage.getItem("authorizedCountries")
    let countries = authorizedCountries? authorizedCountries.split(","): []
    return constants.MENA_COUNTRIES.some(menaCountry => countries.includes(menaCountry));
}

export function isUserMultiRegion() {
    let authorizedCountries = sessionStorage.getItem("authorizedCountries")
    let countries = authorizedCountries? authorizedCountries.split(","): []
    return isMenaUser() && countries.includes(constants.INDIA_REGION)
}

export function filterListByCountry(rows) {
    let authorizedCountries = sessionStorage.getItem("authorizedCountries").split(',')
    return rows.filter(row => authorizedCountries.includes(row.country))
}


// We are getting all MENA and India LO's from backend.
// this function filters LO/LR for bulkDownload
// it check if LO/LR contains country attribute and if this country is in the authorizedCountries list.
// if LO/LR doesn't contains country it assumes country to be IN
export function downloadLOLRCountryFilter(rows) {
    let authorizedCountries = sessionStorage.getItem("authorizedCountries").split(',')
    return rows.filter(row => {
        const country = row.country ?? 'IN';
        return authorizedCountries.includes(country);
    })
}